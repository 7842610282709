import React, { Component } from 'react';
import { Tabs } from 'antd';
import Categories from '../components/categories';
import ClientInfo from '../components/clientInfo';
import FeedbackConf from '../components/feedbackConf';
import ReportsConf from '../components/reportsConf';
import TagsConf from '../components/tagsConf';

const { TabPane } = Tabs;

class ConfSettings extends Component {
	render() {
		return (
			<div>
				<Tabs>
					<TabPane tab="Categories" key="1">
						<Categories />
					</TabPane>
					<TabPane tab="Tags" key="2">
						<TagsConf />
					</TabPane>
					<TabPane tab="Reports" key="3">
						<ReportsConf />
					</TabPane>
					<TabPane tab="Client Information" key="4">
						<ClientInfo />
					</TabPane>
					<TabPane tab="Feedback Information" key="5">
						<FeedbackConf />
					</TabPane>
				</Tabs>
			</div >
		)
	}
}

export default ConfSettings