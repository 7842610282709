import { TYPES } from './actionCreators';

export default (state = {}, action) => {
  switch (action.type) {
    case TYPES.LOGIN_START:
      return { ...state, loading: true, error: '' }
    case TYPES.LOGIN_SUCCESS:
      const { user, token } = action.payload;
      const returnState = { ...state, loading: false, user, token }
      //console.log('login success',returnState)
      return returnState
    case TYPES.LOGIN_FAILED:
      return { ...state, loading: false, error: action.payload }
    default:
      return state;
  }
};
