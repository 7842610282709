export const role = [
  'Admin',
  'Researcher',
  'Lawyer',
]


export const permissions = {
  'Admin': [
    'view_user_list',
    'add_user',
    'edit_user',
    'activate_deactivate_user'
  ],
  'Researcher': [
    'view_conversation_list',
    'view_client_inquiry',
    'view_feedback',
    'view_clients_list',
    'view_client_profile',
    'view_info',
    'view_sessions_and_feedback',
    'edit_client_info',
    'view_reports',
    'download_reports'
  ],
  'Lawyer': [
    'view_conversation_list',
    'view_client_inquiry',
    'add_tag',
    'mark_unmark_as_spam',
    'mark_unmark_as_follow_up',
    'add_notes',
    'edit_client_info',
    'start_conversation',
    'mark_as_done',
    'view_clients_list',
    'block_unblock_client',
    'view_info',
    'view_sessions_and_feedback',
    'view_reports',
    'download_reports',
  ]
}

//use if role is one per user
export const admin = [
  'view_sessions_list',
  'view_client_inquiry',
  'mark_unmark_as_spam',
  'mark_unmark_as_follow_up',
  'start_end_conversation',
  'add_tag',
  'add_notes',
  'edit_client_info',
  'view_feedback',
  'add_feedback',
  'move_session',
  'archive_unarchive_session',
  'add_edit_inquiry_description',
  'assign_update_category',
  'manage_tag',
  'view_clients_list',
  'view_client_profile',
  'view_sessions_and_feedback',
  'view_client_feedback',
  'switch_chatbot_on_off',
  'block_unblock_client',
  'view_reports_module',
  'download_reports',
  'view_configuration_module',
  'view_category_configuration',
  'create_category',
  'edit_category',
  'delete_category',
  'view_tags_configuration',
  'create_tag',
  'edit_tag',
  'delete_tag',
  'view_reports_configuration',
  'update_reports_configuration',
  'view_client_info_config',
  'create_client_info_label',
  'delete_client_info_label',
  'view_feedback_question_config',
  'create_feedback_question_label',
  'delete_feedback_question_label',
  'view_user_list',
  'edit_user',
  'activate_deactivate_user',
  'create_user',
  'view_user_logs',
  'delete_users',
  'update_own_account',
]

export const lawyer = [
  'view_conversation_list',
  'view_client_inquiry',
  'view_feedback',
  'view_client_profile',
  'add_tag',
  'mark_unmark_as_spam',
  'mark_unmark_as_follow_up',
  'add_notes',
  'edit_client_info',
  'start_conversation',
  'mark_as_done',
  'view_clients_list',
  'block_unblock_client',
  'view_info',
  'view_sessions_and_feedback',
  'view_reports',
  'download_reports',
  'view_client_profile'
]

export const researcher = [
  'view_conversation_list',
  'view_client_inquiry',
  'edit_client_info',
  'start_conversation',
  'mark_as_done',
  'view_clients_list',
  'view_info',
  'view_sessions_and_feedback',
  'view_reports',
  'download_reports',
]



