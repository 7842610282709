import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { DoubleRightOutlined } from '@ant-design/icons';
import Reports from './screens/reports'


class Sessions extends Component {
  render() {
    return (
      <div>
        <h3 className='ideals-page-header'>
          <DoubleRightOutlined />&nbsp; Reports
        </h3>
        <hr />
        <br />
        <Switch>
          <Route exact path="/reports/reports" component={Reports} />
        </Switch>
      </div>
    );
  }
}

export default Sessions;