import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getChatter, enableBot, disableBot, blockChatter, updateChatterData } from '../actionCreators';
import { userLog } from '../../users/actionCreators'
import { getClientConf } from '../../configuration/actionCreators'
import { Redirect } from 'react-router-dom';
import { Descriptions, Switch, Table, Button, Tabs, Row, Col, Tag, Tooltip } from 'antd';
import _ from 'lodash';
import FormInformation from '../components/FormInformation';
import { DoubleRightOutlined, WechatOutlined } from '@ant-design/icons';
import SummaryInformation from '../../../shared/components/SummaryInformation';
import { session } from '../../sessions/data'
import moment from 'moment';
import DrawerFeedback from '../../sessions/components/DrawerFeedback';

const type_filters = session.status.map(type => ({ text: type.label, value: type.key }));

class SessionTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      session_to_view: {}
    };
    // this.ref_DrawerFeedback = React.createRef();
  }

  expandedRowRender = (record, index, indent, expanded) => {
    var data = [];
    _.each(record.data, (value, key) => {
      data.push({
        key: key,
        value: value
      })
    })
    if (expanded) {
      return <Descriptions bordered size="small">
        {
          data.map((item) => {
            return <Descriptions.Item label={item.key} key={item.key} span={3}>{item.value}</Descriptions.Item>
          })
        }
      </Descriptions>
    }
  }

  viewFeedBack = (id) => {
    // this.setState({ session_to_view: this.props.sessions[id] });
    // this.ref_DrawerFeedback.current.show();
    this.props.props.history.push({
      pathname: `/sessions/${this.props.sessions[id].id}/chatter/${this.props.sessions[id].chatter_id}`,
      state: { showFeedback: true }
    })
  }
  onRowClick = (record) => {
    this.props.props.history.push({
      pathname: `/sessions/${record.id}/chatter/${record.chatter_id}`,
    })
  }
  render = () => {
    let user_filters = [];
    _.each(this.props.user.data, (value, key) => {
      user_filters.push({
        value: value.id,
        text: value.name
      })
    });

    const columns = [
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        render: (value) => <WechatOutlined />,
        width: '15px'

      },
      {
        title: 'Date',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (date) => moment(date, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY - HH:mm'),
        // sorter: true
      },
      {
        title: 'Session ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Tags',
        dataIndex: 'tags',
        key: 'tags',
        render: (tags, row) => {
          let tagArr = [];
          if (tags && tags.length !== 0) {
            tagArr = tags.split(',')
          }
          if (tagArr.includes('Spam')) {
            const index = tagArr.indexOf('Spam');
            if (index > -1) {
              tagArr.splice(index, 1);
            }
            tagArr.unshift('Spam')
          }
          if (tagArr.includes('Follow-up')) {
            const index = tagArr.indexOf('Follow-up');
            if (index > -1) {
              tagArr.splice(index, 1);
            }
            tagArr.unshift('Follow-up')
          }
          const taglist = tagArr || [];
          let tagExtra = [...taglist];
          if (tagExtra.length === 0) {
            return <div>-</div>
          }
          let visibleTags = []
          // remove 2 items
          if (tagExtra.length > 0) visibleTags.push(tagExtra.shift());
          if (tagExtra.length > 0) visibleTags.push(tagExtra.shift());

          return (
            <div>
              {
                visibleTags.map((item) => {
                  let color = '#00e';
                  if (item === 'Spam') {
                    color = '#c00';
                  }
                  else if (item === 'Follow-up') {
                    color = '#ffdb58';
                  }
                  return < Tag color={color} key={item}>
                    {item}
                  </Tag >
                })
              }


              {
                tagExtra.length > 0 &&
                <Tooltip placement="bottom" title={tagExtra.join(',')}>
                  <Tag color={'#bbb'}>
                    +{tagExtra.length}
                  </Tag>
                </Tooltip>
              }
            </div>
          )
        },
      },
      {
        title: 'Assigned To',
        dataIndex: 'assigned_to',
        key: 'assigned_to',
        // filters: user_filters,
        render: value => {
          if (value === null) {
            return '-';
          }
          else {
            return this.props.user[value] ? this.props.user[value].name : value;
          }
        }
      },

      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        // filters: type_filters,
        render: value => {
          const type = type_filters.find(type => type.value === value);
          if (type) {
            return type.text;
          }

          return value;
        }
      },
      {
        title: '',
        dataIndex: 'id',
        render: (value, row, index) => {
          return <Button
            style={{ color: '#1890ff' }}
            type="text"
            onClick={() => this.viewFeedBack(index)}
          >View Feedback</Button>
        }
      },

    ];

    return (
      <div>
        <Table
          size="small"
          dataSource={this.props.sessions}
          columns={columns}
          onRow={(record) => ({ onDoubleClick: () => this.onRowClick(record) })}
          rowKey='id'
        //          expandable={{ expandedRowRender: this.expandedRowRender }}
        />
        {/* <DrawerFeedback session={this.state.session_to_view} feedback={this.state.session_to_view.feedback} ref={this.ref_DrawerFeedback} /> */}
      </div>
    );
  }

}

class ChatterDetail extends Component {
  constructor(props) {
    super(props);
    this.ref_FormInformation = React.createRef();

  }
  componentDidMount() {
    this.props.getChatter(this.props.match.params.id, this.props.auth.token)
    this.props.getClientConf(this.props.auth.token)
  }

  onChange = (checked) => {
    const id = this.props.match.params.id
    const chatter = this.props.chatter[id]

    if (checked) {
      this.props.enableBot(chatter.id, this.props.auth.token);
    }
    else {
      this.props.disableBot(chatter.id, this.props.auth.token);
    }
  }
  setIsBlocked = (status) => {
    const id = this.props.match.params.id
    this.props.blockChatter(id, status, this.props.auth.token);
    this.props.userLog({
      'user_id': this.props.auth.user.id,
      'activity': status === 1 ? 'Blocked Client' : 'Unblocked Client',
      'condition': {
        'chatter_id': id,
        'chatter_name': this.props.chatter[id].full_name,
      }
    }, this.props.auth.token)
  }
  showFormInformation = () => {
    this.ref_FormInformation.current.show();
  }
  submit_FormInformation = (data) => {
    this.props.updateChatterData(this.props.match.params.id, data, this.props.auth.token);
    this.props.userLog({
      'user_id': this.props.auth.user.id,
      'activity': `Edited Information`,
      'condition': {
        'chatter_id': this.props.match.params.id,
        'chatter_name': this.props.chatter[this.props.match.params.id].full_name,
      }
    }, this.props.auth.token)
    this.ref_FormInformation.current.hide();
  }

  render() {
    const id = this.props.match.params.id
    if (!this.props.auth.token) {
      return <Redirect to={'/login'} />
    }
    if (!this.props.chatter[id]) {
      return <div></div>
    }
    const chatter = this.props.chatter[id]
    var data = [];
    _.each(chatter.data, (value, key) => {
      data.push({
        key: key,
        value: value
      })
    })
    return (
      <div className="full-width">
        <h3 className='ideals-page-header'>
          <DoubleRightOutlined />&nbsp; Client Profile
          <span style={{ float: 'right' }}>
            <Button size="small" onClick={() => { this.props.history.push({ pathname: '/chatters/list' }) }}>Back to Client List</Button>
          </span>
        </h3>
        <hr />
        <br />

        <h1>
          {chatter.full_name}
          <span style={{ marginLeft: '20px' }}><Switch style={{ verticalAlign: 'middle' }} size="small" checked={chatter.status === 'ON'} onChange={this.onChange} /></span>
          {this.props.auth.user.permissions.includes('block_unblock_client') && <span style={{ float: 'right' }}>
            {
              chatter.is_blocked === 1 &&
              <Button size="small" onClick={() => this.setIsBlocked(0)} >Unblock Client</Button>
            }
            {
              (!chatter.is_blocked) &&
              <Button size="small" type="danger" onClick={() => this.setIsBlocked(1)} >Block Client</Button>
            }
          </span>}
        </h1>
        <SummaryInformation
          data={[
            { key: 'Client ID', value: chatter.id },
            { key: 'First Seen', value: chatter.created_at },
            { key: 'Source Feedback', value: chatter.source || '-' }
          ]}
        />
        <br />
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Information" key="1">
            <Row>
              <Col span={18}>
                <SummaryInformation
                  style={{ lineHeight: '2em' }}
                  data={[
                    ...data
                  ]}
                />

              </Col>
              <Col span={6}>
                <span style={{ float: 'right' }}>
                  <a className='ideals-clickable-text' onClick={this.showFormInformation}>Edit Information</a>
                </span>

              </Col>

            </Row>

          </Tabs.TabPane>
          <Tabs.TabPane tab="Sessions & Feedback" key="2">
            <br />
            <SessionTable sessions={chatter.sessions} user={this.props.user} props={this.props} />

          </Tabs.TabPane>
        </Tabs>

        <FormInformation
          data={chatter.data}
          id={id}
          token={this.props.auth.token}
          ref={this.ref_FormInformation}
          onSubmit={this.submit_FormInformation}
          extraFields={this.props.clientConf}
        />

      </div>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
    chatter: state.chatter,
    auth: state.auth,
    user: state.user,
    clientConf: state.configuration.clientConf
  }
}

export default connect(mapStateToProps, {
  getChatter, enableBot, disableBot, blockChatter, updateChatterData, userLog, getClientConf
})(ChatterDetail)
