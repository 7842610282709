import { combineReducers } from 'redux';
import UserReducer from './modules/users/reducer';
import LoginReducer from './modules/login/reducer';
import SessionReducer from './modules/sessions/reducer';
import ChatterReducer from './modules/chatters/reducer';
import ReportReducer from './modules/reports/reducer';
import ConfigReducer from './modules/configuration/reducer';
import { notification } from './shared/reducers/notification';


const appReducer = combineReducers({
  user: UserReducer,
  auth: LoginReducer,
  session: SessionReducer,
  chatter: ChatterReducer,
  report: ReportReducer,
  configuration: ConfigReducer,
  notification: notification,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action)
}

export default rootReducer;
