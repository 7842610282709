import axios from '../../shared/utils/axios';
import { logout } from '../../shared/utils/api_utils'
import {
  API_GET_SESSIONS,
  API_GET_SESSION,
  API_START_CONVERSATION,
  API_END_CONVERSATION,
  API_SET_IS_FOLLOW_UP,
  API_SET_IS_SPAM,
  API_SAVE_NOTE,
  API_GET_NOTE,
  API_GET_TAG_LIST,
  API_SAVE_TAG_CLIENT_SESSION,
  API_GET_TAG_CLIENT_SESSION,
  API_ADD_TAG,
  API_DELETE_TAG,
  API_DELETE_CLIENT_TAG,
  API_GET_STATUS_COUNT,
  API_MASS_UPDATE_SESSION,
  API_ADD_INQUIRY_DES,
  API_DELETE_NOTE,
  API_SAVE_CLIENT_CAT,
} from '../../shared/config/constants';

export const TYPES = {
  GET_SESSIONS_LIST: 'GET_SESSIONS_LIST',
  GET_SESSION: 'GET_SESSION',
  START_CONVERSATION: 'START_CONVERSATION',
  END_CONVERSATION: 'END_CONVERSATION',
  SAVE_NOTE: 'SAVE_NOTE',
  GET_NOTE: 'GET_NOTE',
  SET_IS_FOLLOW_UP: 'SET_IS_FOLLOW_UP',
  SET_IS_SPAM: 'SET_IS_SPAM',
  GET_TAG_LIST: 'GET_TAG_LIST',
  SAVE_TAG_CLIENT_SESSION: 'SAVE_TAG_CLIENT_SESSION',
  GET_TAG_CLIENT_SESSION: 'GET_TAG_CLIENT_SESSION',
  PERSIST_FILTER_SESSION: 'PERSIST_FILTER_SESSION',
  PERSIST_SORTER_SESSION: 'PERSIST_SORTER_SESSION',
  DELETE_CLIENT_TAG: 'DELETE_CLIENT_TAG',
  GET_STATUS_COUNT: 'GET_STATUS_COUNT',
  PERSIST_TABLE_PAGE_SESSION: 'PERSIST_TABLE_PAGE_SESSION',
  GET_INQUIRY_DES: 'GET_INQUIRY_DES',
  GET_ClIENT_CAT: 'GET_ClIENT_CAT',
}

export const massUpdate = (params, token) => async dispatch => {
  try {
    const response = await axios.put(API_MASS_UPDATE_SESSION, params,
      {
        // params: params,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    dispatch({ type: TYPES.GET_SESSIONS_LIST, payload: response.data })
  }
  catch (err) {
    logout(err, dispatch)
  }
}
export const addInquiryDes = (params, token) => async dispatch => {
  try {
    const response = await axios.put(API_ADD_INQUIRY_DES, params,
      {
        // params: params,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    dispatch({ type: TYPES.GET_INQUIRY_DES, payload: response.data })
  }
  catch (err) {
    logout(err, dispatch)
  }
}
export const getSessions = (params, token) => async dispatch => {
  try {
    const response = await axios.get(API_GET_SESSIONS,
      {
        params: params,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    dispatch({ type: TYPES.GET_SESSIONS_LIST, payload: response.data })
  }
  catch (err) {
    logout(err, dispatch)
  }
}

export const getSession = (params, token) => async dispatch => {
  try {
    const response = await axios.get(API_GET_SESSION,
      {
        params: params,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    dispatch({ type: `${TYPES.GET_SESSION}_SUCCESS`, payload: response.data })

  }
  catch (err) {
    logout(err, dispatch)
  }
}

export const getStatusCount = (token) => async dispatch => {
  try {
    const response = await axios.get(API_GET_STATUS_COUNT,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    dispatch({ type: TYPES.GET_STATUS_COUNT, payload: response.data })
  }
  catch (err) {
    logout(err, logout)
  }
}
export const startConversation = (params, token) => async dispatch => {
  try {
    const response = await axios.post(API_START_CONVERSATION, params,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    dispatch({ type: `${TYPES.START_CONVERSATION}_SUCCESS`, payload: response.data })

  }
  catch (err) {
    logout(err, dispatch)
  }

}

export const endConversation = (params, token) => async dispatch => {
  try {
    const response = await axios.post(API_END_CONVERSATION, params,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    dispatch({ type: `${TYPES.END_CONVERSATION}_SUCCESS`, payload: response.data })

  }
  catch (err) {
    logout(err, dispatch)
  }

}

export const saveNote = (values, token) => async dispatch => {
  try {
    const response = await axios.put(API_SAVE_NOTE, values,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
    dispatch({ type: TYPES.GET_NOTE, payload: response.data })
  }
  catch (err) {
    logout(err, dispatch)
  }

}

export const getNote = (values, token) => async dispatch => {
  try {

    const response = await axios.put(API_GET_NOTE, values,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    dispatch({ type: TYPES.GET_NOTE, payload: response.data })

  }
  catch (err) {
    logout(err, dispatch)
  }
}
export const deleteNote = (values, token) => async dispatch => {
  try {

    const response = await axios.put(API_DELETE_NOTE, values,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    dispatch({ type: TYPES.GET_NOTE, payload: response.data })

  }
  catch (err) {
    logout(err, dispatch)
  }
}
export const setIsFollowUp = (params, token) => async dispatch => {
  try {
    const response = await axios.post(API_SET_IS_FOLLOW_UP, params,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    dispatch({ type: `${TYPES.SET_IS_FOLLOW_UP}_SUCCESS`, payload: response.data })

  }
  catch (err) {
    // TODO
    logout(err, dispatch)
  }
}

export const setIsSpam = (params, token) => async dispatch => {
  try {
    const response = await axios.post(API_SET_IS_SPAM, params,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    dispatch({ type: `${TYPES.SET_IS_SPAM}_SUCCESS`, payload: response.data })

  }
  catch (err) {
    // TODO
    logout(err, dispatch)
  }
}

export const getTags = (token) => async dispatch => {
  await axios.get(API_GET_TAG_LIST, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
    .then((response) => {
      dispatch({ type: TYPES.GET_TAG_LIST, payload: response.data })
    })
}

export const saveTag = (values, token) => async dispatch => {
  var response = await axios.put(API_SAVE_TAG_CLIENT_SESSION, values,
    {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
  )
  dispatch({ type: TYPES.GET_TAG_CLIENT_SESSION, payload: response.data })
}

export const getSessionTag = (values, token) => async dispatch => {
  var response = await axios.put(API_GET_TAG_CLIENT_SESSION, values,
    {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
  )
  dispatch({ type: TYPES.GET_TAG_CLIENT_SESSION, payload: response.data })
}

export const deleteClientTag = (values, token) => async dispatch => {
  let response = await axios.delete(`${API_DELETE_CLIENT_TAG}/${values.chatter_id}/${values.session_id}/${values.tags}`,
    {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
  )
  dispatch({ type: TYPES.GET_TAG_CLIENT_SESSION, payload: response.data });
}

export const addTag = (values, token) => async dispatch => {
  let response = await axios.put(API_ADD_TAG, values,
    {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
  )
  dispatch({ type: TYPES.GET_TAG_LIST, payload: response.data });
}

export const deleteTag = (id, token) => async dispatch => {
  let response = await axios.delete(`${API_DELETE_TAG}/${id}`,
    {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
  )
  dispatch({ type: TYPES.GET_TAG_LIST, payload: response.data });
}

export const persistFilter = (filter) => async dispatch => {
  dispatch({ type: TYPES.PERSIST_FILTER_SESSION, payload: filter })
}

export const persistSorter = (sorter) => async dispatch => {
  dispatch({ type: TYPES.PERSIST_SORTER_SESSION, payload: sorter })
}

export const persistPage = (page) => async dispatch => {
  dispatch({ type: TYPES.PERSIST_TABLE_PAGE_SESSION, payload: page })
}

export const saveCat = (value, token) => async dispatch => {
  axios.put(API_SAVE_CLIENT_CAT, value, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(response => {
    dispatch({ type: TYPES.GET_ClIENT_CAT, payload: response.data })
  }).catch(err => {
    logout(err, dispatch)
  })
}