import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  getSessions,
  getTags,
  persistFilter,
  persistSorter,
  getStatusCount,
  persistPage,
  massUpdate
} from '../actionCreators';
import { getUsers, userLog } from '../../users/actionCreators';
import { getCatConfig } from '../../configuration/actionCreators'
import { session } from '../data'
import {
  Table, Input, DatePicker,
  Tag, Tooltip, Button,
  Select,
} from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { DoubleRightOutlined, WechatOutlined } from '@ant-design/icons';
// import '../index.css'

const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const type_filters = session.status.map(type => ({ text: type.label, value: type.key }));
const default_filter = ['BOT', 'QUEUE', 'ONGOING_CHAT', 'DONE']
class SessionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //filteredInfo:null,
      filteredInfo: {
        status: default_filter
      },
      sortedInfo: null,
      selectedKeys: null,
      selectedRowKeys: [],
      queueCount: null,
      ongoingCount: null,
      followupCount: null,
      selectTagFilterVals: [],
    }
  }
  componentDidMount() {
    this.props.getSessions({
      take: 10,
      page: this.props.page ? this.props.page : 1,
      ...this.props.filters,
      status: this.props.filters && this.props.filters.status ? this.props.filters.status :
        default_filter
      ,
      sort_dir: this.props.sorter ? this.props.sorter.order : 'descend',
      sort_key: this.props.sorter ? this.props.sorter.columnKey : 'created_at'
    }, this.props.auth.token);
    this.props.getTags(this.props.auth.token)
    this.props.getUsers({ take: 10000 }, this.props.auth.token);
    this.props.getStatusCount(this.props.auth.token);
    this.props.persistFilter(this.props.filters ? this.props.filters : {
      status: default_filter
    })
    this.props.getCatConfig(this.props.auth.token)
    this.setState({
      filteredInfo: this.props.filters ? this.props.filters : {
        status: default_filter
      },
      sortedInfo: this.props.sorter ? this.props.sorter : {
        columnKey: 'created_at',
        order: 'descend'
      }
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.status !== this.props.status) {
      this.setState({
        followupCount: _.get(this, 'props.status.followup.count', 0) !== 0 ? this.props.status.followup.count : 0,
        queueCount: _.get(this, 'props.status.status.QUEUE.count', 0) !== 0 ? this.props.status.status.QUEUE.count : 0,
        ongoingCount: _.get(this, 'props.status.status.ONGOING_CHAT.count', 0) !== 0 ? this.props.status.status.ONGOING_CHAT.count : 0
      })
    }
    // if (prevProps.catConfig !== this.props.catConfig) {
    //   let catArr = []
    //   _.each(this.props.catConfig, (item) => {
    //     catArr.push({
    //       text: item.category,
    //       value: item.category
    //     })
    //   })
    //   this.setState({
    //     catFilterArr: catArr
    //   })
    // }
  }
  setColor = (value) => {
    switch (value) {
      case 'BOT':
        return ['#FAFF75', '#172B4D']
      case 'QUEUE':
        return ['#ED7514', '#FFFFFF']
      case 'ONGOING_CHAT':
        return ['#0058e9', '#FFFFFF']
      case 'DONE':
        return ['#2EDF21', '#FFFFFF']
      default:
        return ['#172B4D', '#DEEBFF']
    }

  }
  listcolumns = (filteredInfo, sortedInfo) => {
    let tagFilter = [];
    _.each(this.props.tagList, (value, key) => {
      tagFilter.push({
        text: value.tag,
        value: value.tag

      })
    })
    tagFilter.unshift({ text: 'Spam', value: 'Spam' });
    tagFilter.unshift({ text: 'Follow-up', value: 'Follow-up' });
    let user_filters = [];
    _.each(this.props.user.data, (value, key) => {
      user_filters.push({
        value: value.id,
        text: value.name
      })
    });
    const columns = [
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        render: (value) => <WechatOutlined />,
        width: '15px'

      },
      {
        title: 'Name',
        dataIndex: 'full_name',
        key: 'full_name',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'full_name' && sortedInfo.order,
      },
      {
        title: 'Date',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (date) => moment(date, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY - HH:mm'),
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'created_at' && sortedInfo.order,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: '10px', /*background: '#F0F0F0'*/ }} className="custom-filter-dropdown">
            <RangePicker
              value={selectedKeys[0]}
              onChange={date => {
                setSelectedKeys([date]);
                confirm();
              }}
            />
            <div style={{ margin: '10px 0px 0px 0px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button onClick={() => clearFilters()}>Reset</Button>
            </div>
          </div>
        ),
        filteredValue: filteredInfo.created_at || null
      },
      {
        title: 'Session ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        filteredValue: filteredInfo.category || null,
        filters: this.props.catConfig?.map(item => {
          return { text: item.category, value: item.category }
        })
      },
      {
        title: 'Tags',
        dataIndex: 'tags',
        key: 'tags',
        // width: 40,
        render: (tags, row) => {
          let tagArr = [];
          if (tags) {
            tagArr = tags.split(',')
          }
          if (tagArr.includes('Spam')) {
            const index = tagArr.indexOf('Spam');
            if (index > -1) {
              tagArr.splice(index, 1);
            }
            tagArr.unshift('Spam')
          }
          if (tagArr.includes('Follow-up')) {
            const index = tagArr.indexOf('Follow-up');
            if (index > -1) {
              tagArr.splice(index, 1);
            }
            tagArr.unshift('Follow-up')
          }
          const taglist = tagArr || [];
          let tagExtra = [...taglist];
          if (tagExtra.length === 0) {
            return <div>-</div>
          }
          let visibleTags = []
          // remove 2 items
          if (tagExtra.length > 0) visibleTags.push(tagExtra.shift());
          if (tagExtra.length > 0) visibleTags.push(tagExtra.shift());

          return (
            <div>
              {
                visibleTags.map((item) => {
                  let color = '#00e';
                  if (item === 'Spam') {
                    color = '#c00';
                  }
                  else if (item === 'Follow-up') {
                    color = '#ffdb58';
                  }
                  return < Tag color={color} key={item}>
                    {item}
                  </Tag >
                })
              }
              {
                tagExtra.length > 0 &&
                <Tooltip placement="bottom" title={tagExtra.join(',')}>
                  <Tag color={'#bbb'}>
                    +{tagExtra.length}
                  </Tag>
                </Tooltip>
              }
            </div>
          )
        },
        filteredValue: filteredInfo.tags || null,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: '10px', background: '#F0F0F0' }} className="custom-filter-dropdown">
            <Button type="primary" onClick={() => confirm()}>Search</Button>
            <Button onClick={() => this.resetTagSelect(clearFilters)}>Reset</Button>
            <div style={{ margin: '10px 0px 0px 0px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Select
                style={{ width: '300px' }}
                mode='multiple'
                defaultOpen={true}
                placeholder={'Tags to Filter'}
                onChange={e => {
                  setSelectedKeys(e)
                  this.setState({
                    selectTagFilterVals: e
                  })
                }}
                value={this.state.selectTagFilterVals}
                showSearch
              >
                <Option value={'Spam'}>Spam</Option>
                <Option value={'Follow-up'}>Follow-up</Option>
                {this.props?.tagList?.map(value => {
                  return <Option value={value.tag}>{value.tag}</Option>
                })}
              </Select>
            </div>
          </div>
        ),

      },
      {
        title: 'Assigned To',
        dataIndex: 'assigned_to',
        key: 'assigned_to',
        filteredValue: filteredInfo.assigned_to || null,
        filters: user_filters,
        render: value => {
          if (value === null) {
            return '-';
          }
          else {
            return this.props.user[value] ? this.props.user[value].name : value;
          }
        }
      },

      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        filteredValue: filteredInfo.status || null,
        filters: type_filters,
        render: value => {
          const type = type_filters.find(type => type.value === value);
          let color = this.setColor(value)
          if (type) {
            return <Tag color={color[0]}>
              <div style={{ color: color[1] }}>{type.text}</div>
            </Tag>;
          }
          return <Tag color={this.setColor()}>
            <div style={{ color: color[1] }}>{value}</div>
          </Tag>;
        }
      },
    ];
    return columns
  }

  onRowClick = (record) => {
    this.props.history.push({
      pathname: `/sessions/${record.id}/chatter/${record.chatter_id}`
    })
  }

  handleListChange = (pagination, filters, sorter) => {
    console.log('list chnage', filters)
    this.props.getSessions({
      q: this.props.session.q,
      take: pagination.pageSize,
      page: pagination.current,
      sort_key: sorter.columnKey,
      sort_dir: sorter.order,
      ...filters
    }, this.props.auth.token);
    this.props.persistFilter(filters)
    this.props.persistSorter(sorter)
    this.props.persistPage(pagination.current)
    this.props.getStatusCount(this.props.auth.token);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    })
  }

  search = (value) => {
    this.props.getSessions({
      q: value,
      take: this.props.session.take,
      page: 1,
      sort_key: this.props.session.sort_key,
      sort_dir: this.props.session.sort_dir,
      ...this.props.session.filters
    },
      this.props.auth.token)
  }
  resetTagSelect = (clearFilters) => {
    clearFilters()
    this.setState({
      selectTagFilterVals: []
    })
  }
  resetTable = () => {
    this.props.getSessions({
      take: 10, page: 1, status: default_filter
    }, this.props.auth.token);
    this.props.getUsers({ take: 10000 }, this.props.auth.token);
    this.props.persistFilter({ status: default_filter });
    this.props.persistSorter({});
    this.props.persistPage(1);
    this.props.getStatusCount(this.props.auth.token);
    this.setState({
      filteredInfo: {
        status: default_filter
      },
      sortedInfo: {
        columnKey: 'created_at',
        order: 'descend'
      },
      selectTagFilterVals: []
    })
  }
  quickFilter = (filter) => {
    const jsonFilter = filter === 'Follow-up' ? {
      tags: [filter]
    } : { status: [filter] }
    let item = {
      take: 10,
      page: 1,
      status: [filter]
    }
    if (filter === 'Follow-up') {
      item = {
        take: 10,
        page: 1,
        tags: [filter]
      }
    }
    this.props.getSessions(item,
      this.props.auth.token);
    this.props.persistFilter(jsonFilter)
    this.setState({
      filteredInfo: jsonFilter
    })
  }
  rowSelection = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
      selectedRows
    })
  }

  massUpdate = (value) => {
    const item = {
      keys: this.state.selectedRowKeys,
      statusUpdate: value,
      take: 10,
      page: this.props.page ? this.props.page : 1,
      ...this.props.filters,
      sort_dir: this.props.sorter ? this.props.sorter.order : 'descend',
      sort_key: this.props.sorter ? this.props.sorter.columnKey : 'created_at',
      updater: this.props.auth.user.id
    }
    const status = type_filters.filter((item) => item.value === value)
    this.props.massUpdate(item, this.props.auth.token)
    this.props.getStatusCount(this.props.auth.token);
    this.state.selectedRows.map((entry) => {
      const prevStatus = type_filters.filter((item) => item.value === entry.status)
      this.props.userLog({
        'user_id': this.props.auth.user.id,
        'activity': `Moved to ${status[0].text}`,
        'condition': {
          'session_id': entry.id,
          'chatter_id': entry.chatter_id,
          'chatter_name': entry.full_name,
          'prevStatus': prevStatus[0].text,
          'currentStatus': status[0].text,
        }
      }, this.props.auth.token)

    })
    this.setState({
      selectedRowKeys: []
    })
  }
  render() {
    const session = this.props.session;
    let { filteredInfo, sortedInfo } = this.state;
    const { selectedRowKeys } = this.state;
    filteredInfo = filteredInfo || {};
    sortedInfo = sortedInfo || {};
    const rowSelection = {
      selectedRowKeys,
      onChange: this.rowSelection
    }
    if (!this.props.auth.token) {
      return <Redirect to={'/login'} />
    }
    return (
      <div>
        <h3 className='ideals-page-header'>
          <DoubleRightOutlined />&nbsp; Conversation List
        </h3>
        <hr />
        <br />
        <Search placeholder="Search Client Name" onSearch={this.search} enterButton style={{ width: 400 }} />
        <Button type='default' onClick={this.resetTable} style={{ float: 'right' }}>Reset All Filters</Button>
        <br />
        {_.get(this, 'state.followupCount', 0) !== 0 &&
          <Button type='link' onClick={() => this.quickFilter('Follow-up')}
            style={{ color: '#1890ff', float: 'right' }}>
            {this.state.followupCount} Follow Up
        </Button>}
        {_.get(this, 'state.queueCount', 0) !== 0 &&
          <Button type='link' onClick={() => this.quickFilter('QUEUE')}
            style={{ color: '#1890ff', float: 'right' }}>
            {this.state.queueCount} Queued
        </Button>}
        {_.get(this, 'state.ongoingCount', 0) !== 0 &&
          <Button type='link' onClick={() => this.quickFilter('ONGOING_CHAT')}
            style={{ color: '#1890ff', float: 'right' }}>
            {this.state.ongoingCount} Ongoing
        </Button>}
        <br />

        {this.state.selectedRowKeys.length !== 0 && <Select
          onChange={this.massUpdate}
          placeholder="Select a action"
        >
          {/* {type_filters.filter((item) => item.value !== 'BOT').map((item) => { */}
          {type_filters.map((item) => {
            return <Option value={item.value}>{item.text}</Option>
          })}
        </Select>}
        <Table
          size="small"
          dataSource={session.data}
          columns={this.listcolumns(filteredInfo, sortedInfo)}
          onRow={(record) => ({ onClick: () => this.onRowClick(record) })}
          onChange={this.handleListChange}
          pagination={{
            total: session.total,
            current: session.page,
            pageSize: session.take
          }}
          rowSelection={rowSelection}
          rowKey={record => {
            const key = record.id + '_' + record.chatter_id
            return key
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
    auth: state.auth,
    tagList: state.session.tagList,
    user: state.user,
    filters: state.session.filter,
    sorter: state.session.sorter,
    status: state.session.statusCount,
    page: state.session.page,
    catConfig: state.configuration.CatConfig,
  }
}

export default connect(mapStateToProps, {
  getSessions,
  getTags,
  getUsers,
  persistFilter,
  persistSorter,
  getStatusCount,
  persistPage,
  massUpdate,
  userLog,
  getCatConfig
})(SessionList);