import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import UserList from './screens/UserList';

class Users extends Component {
	render() {
		return (
			<Switch>
				<Route exact path="/users/list" component={UserList} />
			</Switch>
		);
	}
}

export default Users;