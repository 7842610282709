import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { DoubleRightOutlined } from '@ant-design/icons';
import ConfSettings from './screen/configuration'
class Configuration extends Component {
	render() {
		return (
			<div>
				<h3 className='ideals-page-header'>
					<DoubleRightOutlined />&nbsp; Configuration
        </h3>
				<br />
				<Switch>
					<Route exact path='/configuration/settings' component={ConfSettings} />
				</Switch>
			</div>
		)
	}
}

export default Configuration;