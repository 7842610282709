import { createStore, applyMiddleware } from 'redux';
import { createMigrate, persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import rootReducer from '../reducer';
import storage from 'redux-persist/lib/storage';

export default function configureStore(preloadedState) {
  const migrations = {
    1: (state) => {
      return {
        ...state
      }
    }
  }

  const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    migrate: createMigrate(migrations, { debug: false }),
    whitelist: ['auth']
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const middlewares = [thunk];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const storeEnhancers = [middlewareEnhancer];
  const composedEnhancer = composeWithDevTools(...storeEnhancers);

  const store = createStore(persistedReducer, preloadedState, composedEnhancer);

  if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
      module
        .hot
        .accept('../reducer', () => {
          const newRootReducer = require("../reducer").default;
          store.replaceReducer(persistReducer(persistConfig, newRootReducer))
        });
    }
  }

  const persistor = persistStore(store);

  return { store, persistor };
}
