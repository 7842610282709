import axios from '../../shared/utils/axios';
import { getError } from '../../shared/utils/api_utils';
import {
  API_LOGIN_START
} from '../../shared/config/constants';
import { NOTIFICATION_ACTION } from '../../shared/reducers/notification';

export const TYPES = {
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGOUT: 'LOGOUT'
}

export const login = (fromValues) => {
  return (dispatch) => {
    dispatch({
      type: TYPES.LOGIN_START
    })

    axios.post(API_LOGIN_START,
      fromValues
    )
      .then((response) => {
        dispatch({
          type: TYPES.LOGIN_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.LOGIN_FAILED,
          payload: getError(error)
        });
        dispatch({
          type: NOTIFICATION_ACTION,
          payload: {
            'type': 'error',
            'message': getError(error).message,
            id: Math.random().toString(36).substring(7)
          }
        })
      })

  }
}

export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: TYPES.LOGOUT
    })
  }
}