import React, { Component } from 'react';
import {
	Drawer, Select, Collapse,
	Switch, Input, Button,
	Checkbox
} from 'antd';

const { Panel } = Collapse;
const { Option } = Select;

const genExtra = (value, max) => (
	<div>{value} of {max} permissions granted </div>
);

class UserManagement extends Component {
	render() {
		return (
			<Drawer
				title="User Management"
				placement="right"
				closable={false}
				onClose={this.props.onClose}
				visible={this.props._this.state.visible}
				width={500}
			>
				{this.props._this.state.id ?
					<Switch checked={this.props._this.state.active} onChange={this.props.onSwitchChange} />
					: <div></div>}
				<form onSubmit={this.props.handleCreateNewItem}>
					<div>
						<label htmlFor='name'>Name</label>
						<Input
							id='name'
							name='name'
							value={this.props._this.state.name}
							onChange={this.props._this.handleFieldChange}
						/>
						{this.props.errors.name.length > 0 &&
							<span style={{ color: 'red' }} className='error'>{this.props.errors.name}</span>}
					</div>
					<div>
						<label htmlFor='email'>Email</label>
						<Input
							id='email'
							name='email'
							value={this.props._this.state.email}
							onChange={this.props.handleFieldChange}
						/>
						{this.props.errors.email.length > 0 &&
							<span style={{ color: 'red' }} className='error'>{this.props.errors.email}</span>}
					</div>
					<div>
						<label htmlFor='password'>Password</label>
						<Input.Password
							type='password'
							id='password'
							name='password'
							value={this.props._this.state.password}
							onChange={this.props.handleFieldChange}
						/>
						{this.props.errors.password.length > 0 &&
							<span style={{ color: 'red' }} className='error'>{this.props.errors.password}</span>}
					</div>
					<div>
						<label htmlFor='password2'>Confirm Password</label>
						<Input.Password
							type='password2'
							id='password2'
							name='password2'
							value={this.props._this.state.password2}
							onChange={this.props.handleFieldChange}
						/>
						{this.props.errors.password2.length > 0 &&
							<span style={{ color: 'red' }} className='error'>{this.props.errors.password2}</span>}
					</div>

					<div>
						<label htmlFor='role'>Role</label><br />
						<Select onChange={this.props.onSelectRole} value={this.props._this.state.role} style={{ width: '100%' }}>
							{
								this.props.role.map((item => {
									return <Option value={item}>{item}</Option>
								}))
							}
						</Select>
					</div>
					<hr />
					<Collapse>
						<Panel
							header='Permissions'
							extra={genExtra(this.props._this.state.permissions.length, this.props.admin.length)}
						>
							<Checkbox onChange={this.props.checkAll} style={{ fontWeight: "bold" }} checked={this.props._this.state.checked}>
								Check all permissions
						</Checkbox>

							<br />
							<Checkbox.Group
								options={this.props.admin}
								value={this.props._this.state.permissions}
								onChange={this.props.onCheckPermissions}
							/>
						</Panel>
					</Collapse>
					<br />
					<Button
						disabled={
							!this.props.validateForm(this.props.errors)
							|| this.props._this.state.name.length === 0
							|| this.props._this.state.email.length === 0
							// || this.props._this.state.password.length === 0
							// || this.props._this.state.password2.length === 0
						}
						type="primary"
						onClick={this.props.handleCreateNewItem}>Save</Button>
					<Button onClick={this.props.onClose}>Cancel</Button>
				</form>
			</Drawer>
		)
	}
}

export default UserManagement