/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import axios from '../../../shared/utils/axios';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'antd'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { addDays, getYear } from 'date-fns';
import {
  getSessions,
  getChatters,
  getSessionsCount,
  getChattersCount,
  getFeedbackData,
  getLegalReport,
  getSessionsData,
  getChatterData,
  getAllHitData,
  getAllTagData,
  getAllRegionData,
} from '../actionCreators';
import {
  getReportConfig,
  getClientConf,
} from '../../configuration/actionCreators'
import { getUsers, userLog } from '../../users/actionCreators';
import Moment from 'moment';
import _ from 'lodash';
import {
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryBar,
  VictoryPie,
  VictoryLegend,
  VictoryTooltip,
} from 'victory';
import {
  Tabs, Card,
  Row, Col, Modal
} from 'antd';
import {
  CalendarOutlined, FileDoneOutlined
} from '@ant-design/icons';
import Area from '../components/Area';
import HorizontalBar from '../components/HorizontalBar';
import StackBar from '../components/StackBar';
import BarIndividualAxis from '../components/BarIndividualAxis';

const { TabPane } = Tabs;
const warning = Modal.warning;

const blue = '#0a26f7';
const pink = '#e62c92';
const blueArr = [
  "DEEBFF", "DEEBFF", "B3C9E6", "B3C9E6", "6290CC",
  "6290CC", "1061CC", "1061CC", "003780", "003780"
];

const goldArr = [
  "#FAF3D0", "#FFEBB3", "#FFDD80", "#FFC933", "#E6A800"
];
class Reports extends Component {
  constructor(props) {
    super(props)
    const weekArr = this.getWeek()
    this.state = {
      DateRangeVisible: false,
      selection: {
        startDate: weekArr[0],
        endDate: weekArr[1],
        key: 'selection'
      },
      sessionCols: '',
      legalCols: '',
      clientCols: '',
      extraClientCols: '',
      sessionLoading: false,
      chatterLoading: false,
      legalLoading: false
    }
    this.initializeData();
    this.props.getReportConfig(this.props.auth.token);
    this.props.getClientConf(this.props.auth.token);
  }
  getFormattedDate = (date) => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1;// getMonth starts from 0 so plus 1 is needed
    const day = date.getDate();
    const formattedDate = Moment(`${year}-${month}-${day}`).format('YYYY-MM-DD')
    return formattedDate;
  }

  displayFormattedData = (date) => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1;// getMonth starts from 0 so plus 1 is needed
    const day = date.getDate();
    const formattedDate = Moment(`${month}-${day}-${year}`).format('MMM-DD-YYYY')
    return formattedDate;
  }
  initializeData = () => {
    const startDate = this.getFormattedDate(this.state.selection.startDate) + ' 00:00:00'
    const endDate = this.getFormattedDate(this.state.selection.endDate) + ' 23:59:59'
    this.props.getSessionsCount({
      startDate: startDate,
      endDate: endDate
    }, this.props.auth.token)
    this.props.getChattersCount({
      startDate: startDate,
      endDate: endDate
    }, this.props.auth.token);
    this.props.getFeedbackData({
      startDate: startDate,
      endDate: endDate
    }, this.props.auth.token);
    this.props.getUsers({}, this.props.auth.token);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selection !== this.state.selection) {
      this.initializeData()
      this.showRangePicker();
      this.setState({
        sessionLoading: false,
        chatterLoading: false,
        legalLoading: false
      })
    }
    if (prevProps.legalLoading !== this.props.legalLoading) {
      this.setState({
        legalLoading: false
      })
    }
    if (prevProps.sessionLoad !== this.props.sessionLoad) {
      this.setState({
        sessionLoading: false
      })
    }
    if (prevProps.chatterLoad !== this.props.chatterLoad) {
      this.setState({
        chatterLoading: false
      })
    }
    if (prevProps.reportConfig !== this.props.reportConfig) {
      this.setState({
        sessionCols: this.props.reportConfig.reportConfig.sessionsDetails.columns,
        legalCols: this.props.reportConfig.reportConfig.legalDetails.columns,
        clientCols: this.props.reportConfig.reportConfig.clientDetails.columns,
      })
    }
    if (prevProps.clientConf !== this.props.clientConf) {
      let extraCols = []
      _.each(this.props.clientConf, (item) => {
        extraCols.push(item.label)
      })
      this.setState({
        extraClientCols: extraCols
      })
    }
  }
  getWeek = () => {
    const startOfWeek = Moment().startOf('week').toDate();
    const endOfWeek = Moment().endOf('week').toDate();
    return [startOfWeek, endOfWeek]
  }
  showRangePicker = () => {
    this.setState({
      DateRangeVisible: !this.state.DateRangeVisible,
    })
  }

  listCountData = (data) => {
    let dataArr = []
    if (data && data.data) {
      _.each(data.data, (value, key) => {
        dataArr.push({
          x: new Date(value.day),
          y: value.count,
          day: value.day
        })
      })
    }
    else {
      _.each(data, (value, key) => {
        dataArr.push({
          x: new Date(value.day),
          y: value.count,
          day: value.day
        })
      })
    }
    return dataArr
  }

  listSectorCat = (data) => {
    let dataArr = []
    if (data) {
      _.each(data.sectorCat, (value, key) => {
        dataArr.push(value.sector)
      })
    }
    return dataArr
  }
  render() {
    if (!this.props.auth.token) {
      return <Redirect to={'/login'} />
    }
    let dateRange = '';
    if (this.state.selection.endDate) {
      const startDate = this.displayFormattedData(this.state.selection.startDate);
      const endDate = this.displayFormattedData(this.state.selection.endDate);
      dateRange = startDate + ' to ' + endDate;
    }
    return (
      <div>
        <Button onClick={this.showRangePicker}>
          {
            this.state.DateRangeVisible ? 'Hide Date' :
              this.state.selection.endDate ? dateRange : 'Pick Date'
          }
          < CalendarOutlined />
        </Button>
        <br />
        {
          this.state.DateRangeVisible ? <DateRangePicker
            onChange={item => this.setState({ ...this.state, ...item })}
            direction="vertical"
            scroll={{ enabled: true }}
            ranges={[this.state.selection]}
          /> : <div></div>
        }
        <br />
        <Row gutter={[16, 24]}>
          <Col span={24}>
            <Card title="Reports and Analytics"
              extra={
                this.props.auth.user.permissions.includes('download_reports') &&
                <Button onClick={() => {
                  this.setState({ legalLoading: true })
                  this.props.getLegalReport(this.state.legalCols, dateRange, this.state.selection.startDate, this.state.selection.endDate,
                    this.props.auth.token, this)
                  this.props.userLog({
                    'user_id': this.props.auth.user.id,
                    'activity': `Exported CSV`,
                    'condition': {
                      'report': 'Legal Consultation Report',
                    }
                  }, this.props.auth.token)
                }}
                  loading={this.state.legalLoading}
                >Export ALL</Button>
              }
            >
              {_.get(this, 'props.sessionCount.data.length', 0) !== 0 ?
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Sessions" key="1">
                    <div style={{ float: 'right', fontWeight: 'bold', }}>
                      {this.props.sessionCount && <div>{this.props.sessionCount.total.total} sessions {dateRange}</div>}
                      <Button type='link'
                        onClick={() => {
                          this.setState({ sessionLoading: true })
                          this.props.getSessionsData(this.state.sessionCols, dateRange, this.state.selection.startDate, this.state.selection.endDate,
                            this.props.auth.token, this)
                          this.props.userLog({
                            'user_id': this.props.auth.user.id,
                            'activity': `Exported CSV`,
                            'condition': {
                              'report': 'Session Report',
                            }
                          }, this.props.auth.token)
                        }}
                        loading={this.state.sessionLoading}>
                        export sessions</Button>
                    </div>
                    <Area
                      data={
                        this.listCountData(this.props.sessionCount)
                      }
                      blue={blue}
                      ylabel={'sessions'}
                    />
                  </TabPane>
                  <TabPane tab="Clients" key="2">
                    <div style={{ float: 'right', fontWeight: 'bold', }}>
                      {this.props.chatterCount && <div>{this.props.chatterCount.total.total} clients {dateRange}</div>}
                      <Button type='link' onClick={() => {
                        this.setState({ chatterLoading: true })
                        this.props.getChatterData(this.state.clientCols, this.state.extraClientCols, dateRange,
                          this.state.selection.startDate, this.state.selection.endDate, this.props.auth.token, this)
                        this.props.userLog({
                          'user_id': this.props.auth.user.id,
                          'activity': `Exported CSV`,
                          'condition': {
                            'report': 'Client Report',
                          }
                        }, this.props.auth.token)
                      }}
                        loading={this.state.chatterLoading}>
                        export clients</Button>
                    </div>
                    <Area
                      data={
                        this.listCountData(this.props.chatterCount)
                      }
                      blue={blue}
                      ylabel={'clients'}
                    />
                  </TabPane>
                  <TabPane tab="CTS" key="3">
                    {_.get(this, 'props.sessionCount.averageTime.averageCTS', false) &&
                      <div style={{ float: 'right', fontWeight: 'bold', }}>
                        {this.props.sessionCount.averageTime.averageCTS} average chatbot time spent of client's sessions on  {dateRange}
                      </div>}
                    <Area
                      data={
                        this.listCountData(this.props.sessionCount.CTS)
                      }
                      blue={blue}
                      ylabel={'duration'}
                      specialAxis={true}
                    />
                  </TabPane>
                  {/* <TabPane tab="QT" key="4">
                    {_.get(this, 'props.sessionCount.averageTime.averageQT', false) &&
                      <div style={{ float: 'right', fontWeight: 'bold', }}>
                        {this.props.sessionCount.averageTime.averageQT} average queue response time {dateRange}
                      </div>}
                    <Area
                      data={
                        this.listCountData(this.props.sessionCount.QT)
                      }
                      blue={blue}
                      ylabel={'minutes'}
                    />
                  </TabPane> */}
                  <TabPane tab="FRT" key="5">
                    {_.get(this, 'props.sessionCount.averageTime.averageFRT', false) &&
                      <div style={{ float: 'right', fontWeight: 'bold', }}>
                        {this.props.sessionCount.averageTime.averageFRT} average first response time of queued sessions on {dateRange}
                      </div>}
                    <Area
                      data={
                        this.listCountData(this.props.sessionCount.FRT)
                      }
                      blue={blue}
                      ylabel={'duration'}
                      specialAxis={true}
                    />
                  </TabPane>
                  <TabPane tab="TCR" key="6">
                    {_.get(this, 'props.sessionCount.averageTime.averageTCR', false) &&
                      <div style={{ float: 'right', fontWeight: 'bold', }}>
                        {this.props.sessionCount.averageTime.averageTCR} average time to complete rate of ongoing sessions on {dateRange}
                      </div>}
                    <Area
                      data={
                        this.listCountData(this.props.sessionCount.TCR)
                      }
                      blue={blue}
                      ylabel={'duration'}
                      specialAxis={true}
                    />
                  </TabPane>
                </Tabs> : <div>no data</div>}
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 24]}>
          <Col span={12}>
            <Card title='FAQ vs Legal Consultation'
              extra={
                <div style={{ float: 'right' }}>100% of total sessions</div>
              }
            >
              {_.get(this, 'props.sessionCount.faqVsLegal.total', 0) !== 0 ?
                <VictoryChart
                  height={200}
                  containerComponent={
                    <VictoryVoronoiContainer
                      mouseFollowTooltips
                      voronoiDimension="x"
                      labels={({ datum }) =>
                        `${dateRange}\n count:${datum.count} ${((datum.count / this.props.sessionCount.faqVsLegal.total) * 100).toFixed(2)}%`}
                      labelComponent={
                        <VictoryTooltip
                          style={{ fontSize: 12 }}
                        />
                      }
                    />
                  }
                >
                  <VictoryBar horizontal
                    style={{
                      data: {
                        fill: ({ datum }) => datum.cat === 'faq' ? blue : pink,
                        fillOpacity: 0.3
                      }
                    }}
                    data={this.props.sessionCount.faqVsLegal.values}
                    x='cat'
                    y='count'
                  />
                </VictoryChart> : <div>no data</div>}
            </Card>
          </Col>
          <Col span={12}>
            <Card title='Age of Clients'
              extra={
                <div>
                  <div style={{ float: 'right', color: pink }}>Legal</div>
                  <div style={{ float: 'right', color: blue }}>FAQ<span>&nbsp;&nbsp;&nbsp;&nbsp;</span></div>
                  <div style={{ float: 'right' }}>{dateRange}<span>&nbsp;&nbsp;&nbsp;&nbsp;</span></div>
                </div>
              }
            >

              {(_.get(this, 'props.chatterCount.ageCount.talked_to.length', 0) !== 0 ||
                _.get(this, 'props.chatterCount.ageCount.not_talked_to.length', 0)) ?
                <StackBar
                  pink={pink}
                  blue={blue}
                  data1={this.props.chatterCount.ageCount.talked_to}
                  data2={this.props.chatterCount.ageCount.not_talked_to}
                  x='age'
                  y='count'
                /> : <div>no data</div>}
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 24]}>
          <Col span={12}>
            <Card title='Gender'
              style={{
                height: _.get(this, 'props.chatterCount.genderCount.total.total', 0) !== 0 ? 700 : 150
              }}
              extra={
                <div>100% of total clients</div>
              }
            >
              {_.get(this, 'props.chatterCount.genderCount.total.total', 0) !== 0 ?
                <div>
                  <Card style={{ height: 75 }}>
                    <VictoryLegend
                      orientation="horizontal"
                      gutter={15}
                      data={[
                        { name: "Babae", symbol: { fill: "#EC828B", type: "star" } },
                        { name: "Lalaki", symbol: { fill: "#80ADF2" } },
                        { name: "LGBTQ+", symbol: { fill: "#D4A8FC" } },
                        { name: "Iba Pa", symbol: { fill: "#64F38F" } },
                        { name: "Ayaw Ko Sabihin", symbol: { fill: "#F3A264" } },
                      ]}
                    />
                  </Card>
                  <div>{dateRange}</div>
                  <VictoryPie
                    padding={{ top: -120 }}
                    radius={100}
                    colorScale={["#EC828B", "#80ADF2", "#D4A8FC", "#64F38F", "#F3A264"]}
                    data={this.props.chatterCount.genderCount.genderCount}
                    x='gender'
                    y='count'
                    labels={({ datum }) => datum.gender}
                    labelComponent={
                      <VictoryTooltip
                        constrainToVisibleArea
                        flyoutWidth={100}
                        flyoutHeight={20}
                      />
                    }
                  />
                </div> : <div>no data</div>}
            </Card>
          </Col>
          <Col span={12}>
            <Card title='Sector' style={{
              height: _.get(this, 'props.chatterCount.sectorCount.sectorCount.length', 0) !== 0 ? 700 : 150
            }}>
              {_.get(this, 'props.chatterCount.sectorCount.sectorCount.length', 0) !== 0 ?
                <BarIndividualAxis
                  data={this.props.chatterCount.sectorCount.sectorCount}
                  blue={blue}
                  category={this.listSectorCat(this.props.chatterCount.sectorCount)}
                  dateRange={dateRange}
                /> : <div>no data</div>}
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 24]}>
          <Col span={8}>
            <Card title='Clients per Region'>
              <div style={{ float: 'left' }}>Top 10 of 100% total clients</div>
              {_.get(this, 'props.chatterCount.regionCount.regionCount.length', 0) !== 0 ?
                <div>
                  <Button
                    style={{ color: '#1890ff', float: 'right' }}
                    type='link'
                    onClick={() => {
                      this.props.getAllRegionData(dateRange, this.state.selection.startDate, this.state.selection.endDate,
                        this.props.auth.token, this, warning)
                    }}>Export CSV</Button>
                  <HorizontalBar
                    total={this.props.chatterCount.regionCount.regionTotal.total}
                    data={this.props.chatterCount.regionCount.regionCount}
                    colorArr={blueArr}
                    x='region'
                    y='count'
                    dateRange={dateRange}
                    left={100}
                  /></div> : <div>no data</div>}
            </Card>
          </Col>
          <Col span={8}>
            <Card title='FAQ and Hotline'>
              <div style={{ float: 'left' }}>Top 10 of 100% FAQ sessions</div>
              {_.get(this, 'props.sessionCount.hitReport.hitCount.length', 0) !== 0 ?
                <div>
                  <Button
                    style={{ color: '#1890ff', float: 'right' }}
                    type='link'
                    onClick={() => {
                      this.props.getAllHitData(dateRange, this.state.selection.startDate, this.state.selection.endDate,
                        this.props.auth.token, this, warning)
                    }}>Export CSV</Button>
                  <HorizontalBar
                    total={this.props.sessionCount.hitReport.total.count}
                    data={this.props.sessionCount.hitReport.hitCount}
                    colorArr={blueArr}
                    x='node'
                    y='count'
                    dateRange={dateRange}
                  />
                </div> : <div ><br />no data</div>}
            </Card>
          </Col>
          <Col span={8}>
            <Card title='Issues Per Legal Consultation'>
              <div style={{ float: 'left' }}>Top 10 of 100% total clients</div>
              {_.get(this, 'props.sessionCount.tagsReport.tagCount.length', 0) !== 0 ?
                <div>
                  <Button
                    style={{ color: '#1890ff', float: 'right' }}
                    type='link'
                    onClick={() => {
                      this.props.getAllTagData(dateRange, this.state.selection.startDate, this.state.selection.endDate,
                        this.props.auth.token, this, warning)
                    }}>Export CSV</Button>
                  <HorizontalBar
                    total={this.props.sessionCount.tagsReport.total.count}
                    data={this.props.sessionCount.tagsReport.tagCount}
                    colorArr={blueArr}
                    x='tag'
                    y='count'
                    dateRange={dateRange}
                  />
                </div> : <div><br /> no data</div>}
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 24]}>
          <Col span={12}>
            <Card title='Source Feedback'
              extra={
                <div style={{ float: 'right' }}>100% total of unique clients with feedback</div>
              }
            >
              {_.get(this, 'props.feedbackData.source.length', 0) !== 0 ?
                <HorizontalBar
                  total={this.props.feedbackData.sourceTotal.total}
                  data={this.props.feedbackData.source}
                  colorArr={blueArr}
                  x='feedback_value'
                  y='count'
                  left={150}
                  dateRange={dateRange}
                  yAxis={['ibang social media', 'reports sa media', 'nasabi lang sa akin', 'iba pa', 'facebook', 'ibp']}
                /> : <div>no data</div>}
            </Card>
          </Col>
          <Col span={12}>
            <Card title='Star Rating Feedback'
              extra={
                <div style={{ float: 'right' }}>100% total of sessions with feedback</div>
              }
            >
              {_.get(this, 'props.feedbackData.rating.length', 0) !== 0 ?
                <HorizontalBar
                  total={this.props.feedbackData.ratingTotal.total}
                  data={this.props.feedbackData.rating}
                  colorArr={goldArr}
                  x='feedback_value'
                  y='count'
                  dateRange={dateRange}
                  yAxis={['⭐', '⭐⭐', '⭐⭐⭐', '⭐⭐⭐⭐', '⭐⭐⭐⭐⭐']}
                /> : <div>no data</div>}
            </Card>
          </Col>
        </Row>
      </div >
    )
  }

}

const mapStateToProps = (state) => {
  return {
    sessions: state.report.sessions,
    chatters: state.report.chatters,
    sessionCount: state.report.sessionCount,
    chatterCount: state.report.chatterCount,
    feedbackData: state.report.feedbackData,
    legalLoading: state.report.legalLoading,
    sessionLoad: state.report.sessionLoading,
    chatterLoad: state.report.chatterLoading,
    reportConfig: state.configuration.reportConfig,
    clientConf: state.configuration.clientConf,
    auth: state.auth,
    user: state.user,
  }
}

export default connect(mapStateToProps, {
  getSessions,
  getChatters,
  getSessionsCount,
  getChattersCount,
  getFeedbackData,
  getUsers,
  getLegalReport,
  getSessionsData,
  getChatterData,
  getAllHitData,
  getAllTagData,
  getAllRegionData,
  getReportConfig,
  userLog,
  getClientConf
})(Reports);
