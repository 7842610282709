import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	Button, Table, Input,
	Popconfirm
} from 'antd'
import {
	getCatConfig,
	saveCatConfig,
	deleteCat
} from '../actionCreators'
import { userLog } from '../../users/actionCreators'
import Modal from 'antd/lib/modal/Modal';
import {
	DeleteOutlined,
	EditOutlined,
} from '@ant-design/icons';


class Categories extends Component {
	constructor(props) {
		super(props)
		this.state = {
			id: '',
			visible: false,
			editVisible: false,
			prevCat: '',
			category: '',
			update: false, // for re rendering
			columns: [{
				title: 'Category',
				dataIndex: 'category',
				key: 'category'
			}, {
				title: 'Action',
				key: 'action',
				render: (text, record) => (
					<span>
						<Popconfirm
							title="Are you sure you want to delete this Category?"
							onConfirm={() => {
								this.props.deleteCat(record.id, this.props.auth.token)
								this.props.userLog({
									'user_id': this.props.auth.user.id,
									'activity': `Deleted Category`,
									'condition': {
										'category': record.category
									}
								}, this.props.auth.token);
							}}
							okText="Yes"
							cancelText="No"
						>
							<Button
								size="small"
								icon={<DeleteOutlined />}
								danger
							/>
						</Popconfirm>
						&nbsp;
						<Button size="small" icon={<EditOutlined />}
							onClick={() => this.openEditModal(record.id, record.category)}
						/>
					</span>
				)
			}
			]
		}
	}
	componentDidMount() {
		this.props.getCatConfig(this.props.auth.token)
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevState.update !== this.state.update) {
			this.props.getCatConfig(this.props.auth.token)
		}
	}
	textAreaTagChange = (event) => {
		this.setState({
			category: event.target.value.substring(0, 35)
		})
	}
	openModal = () => {
		this.setState({
			visible: true
		})
	}
	openEditModal = (id, category) => {
		this.setState({
			id: id,
			editVisible: true,
			prevCat: category
		})
	}
	onCancel = () => {
		this.setState({
			visible: false,
			editVisible: false,
			category: ''
		})
	}
	onOk = () => {
		this.props.saveCatConfig({
			category: this.state.category
		}, this.props.auth.token)
		this.props.userLog({
			'user_id': this.props.auth.user.id,
			'activity': `Created Category`,
			'condition': {
				'category': this.state.category
			}
		}, this.props.auth.token);
		this.setState({
			visible: false,
			category: '',
			update: !this.state.update,
			id: ''
		})
	}
	onOkEdit = () => {
		this.props.saveCatConfig({
			id: this.state.id,
			category: this.state.category
		}, this.props.auth.token)
		this.props.userLog({
			'user_id': this.props.auth.user.id,
			'activity': `Edited Category`,
			'condition': {
				'prevCat': this.state.prevCat,
				'category': this.state.category
			}
		}, this.props.auth.token);
		this.setState({
			editVisible: false,
			category: '',
			update: !this.state.update,
			id: ''
		})
	}
	render() {
		if (!this.props.auth.token) {
			return <Redirect to={'/login'} />
		}
		return (
			<div>
				<Button
					onClick={this.openModal}
				>
					Add Category
				</Button>
				<Modal
					title='Add Category'
					visible={this.state.visible}
					onCancel={this.onCancel}
					onOk={this.onOk}
				>
					<Input
						value={this.state.category}
						onChange={this.textAreaTagChange}
					/>
				</Modal>
				<Modal
					title='Edit Category'
					visible={this.state.editVisible}
					onCancel={this.onCancel}
					onOk={this.onOkEdit}
				>
					<Input
						value={this.state.category}
						onChange={this.textAreaTagChange}
					/>
				</Modal>
				<Table
					columns={this.state.columns}
					dataSource={this.props.catConfig}
				/>
			</div>
		)
	}
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		catConfig: state.configuration.CatConfig
	}
}
export default connect(mapStateToProps, {
	getCatConfig,
	saveCatConfig,
	deleteCat,
	userLog
})(Categories)