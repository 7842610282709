import { TYPES } from './actionCreators';
import _ from 'lodash';

export default (state = {}, action) => {
  var session_key = null;
  switch (action.type) {
    case `${TYPES.GET_SESSION}_SUCCESS`:
    case `${TYPES.START_CONVERSATION}_SUCCESS`:
    case `${TYPES.END_CONVERSATION}_SUCCESS`:
    case `${TYPES.SET_IS_FOLLOW_UP}_SUCCESS`:
    case `${TYPES.SET_IS_SPAM}_SUCCESS`:
      session_key = action.payload.id + '_' + action.payload.chatter_id;
      return { ...state, [session_key]: action.payload }
    case TYPES.GET_SESSIONS_LIST:
      // also store mapKeys
      let values = _.mapKeys(action.payload.data, function (value, key) {
        return value.id + '_' + value.chatter_id;
      })
      return { ...state, ...action.payload, ...values }
    case TYPES.GET_NOTE:
      return { ...state, note: action.payload }
    case TYPES.GET_TAG_LIST:
      return { ...state, tagList: action.payload }
    case TYPES.GET_TAG_CLIENT_SESSION:
      return { ...state, sessionTagList: action.payload }
    case TYPES.PERSIST_FILTER_SESSION:
      return { ...state, filter: action.payload }
    case TYPES.PERSIST_SORTER_SESSION:
      return { ...state, sorter: action.payload }
    case TYPES.PERSIST_TABLE_PAGE_SESSION:
      return { ...state, page: action.payload }
    case TYPES.GET_STATUS_COUNT:
      return { ...state, statusCount: action.payload }
    case TYPES.GET_INQUIRY_DES:
      return { ...state, inquiry_des: action.payload }
    case TYPES.GET_ClIENT_CAT:
      return { ...state, clientCat: action.payload }
    default:
      return state
  }
}
