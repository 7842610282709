import _ from 'lodash';
import { TYPES } from './actionCreators';

export default (state = {}, action) => {
  switch (action.type) {
    case TYPES.GET_CHATTER:
    case `${TYPES.DISABLE_BOT}_SUCCESS`:
    case `${TYPES.ENABLE_BOT}_SUCCESS`:
    case `${TYPES.UPDATE_CHATTER_DATA}_SUCCESS`:
    case `${TYPES.BLOCK_CHATTER}_SUCCESS`:
      return { ...state, [action.payload.id]: action.payload };
    case `${TYPES.GET_CHATTERS_LIST}_SUCCESS`:
      // also store the values for easy update
      var values = _.mapKeys(action.payload.data, function (value, key) {
        return value.id;
      })
      return { ...state, ...action.payload, ...values }
    // return {
    //   ...state, ..._.mapKeys(action.payload, function (value, key) {
    //     return value.id;
    //   })
    // }
    case TYPES.PERSIST_FILTER_CHATTER:
      return { ...state, filter: action.payload }
    case TYPES.PERSIST_SORTER_CHATTER:
      return { ...state, sorter: action.payload }
    case TYPES.PERSIST_TABLE_PAGE_CHATTER:
      return { ...state, page: action.payload }
    default:
      return state
  }
}
