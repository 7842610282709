import axios from '../../shared/utils/axios';
import { logout } from '../../shared/utils/api_utils';
import {
	API_GET_USERS,
	API_CREATE_USER,
	API_USER,
	API_USER_LOG,
	API_GET_USER_LOG
} from '../../shared/config/constants';
import ActionButton from 'antd/lib/modal/ActionButton';

export const TYPES = {
	GET_USERS_LIST: 'GET_USERS_LIST',
	CREATE_USER: 'CREATE_USER',
	DELETE_USER: 'DELETE_USER',
	UPDATE_USER: 'UPDATE_USER',
	GET_USER_LOG: 'GET_USER_LOG'
}

export const getUsers = (params, token) => async dispatch => {
	const response = await axios.get(API_GET_USERS,
		{
			params: params,
			headers: {
				'Authorization': `Bearer ${token}`
			}
		});
	dispatch({ type: TYPES.GET_USERS_LIST, payload: response.data })
}

export const createUser = (id, formValues, token) => async (dispatch, getState) => {
	if (!id) {
		const response = await axios.post(API_CREATE_USER, formValues,
			{
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
		dispatch({ type: TYPES.CREATE_USER, payload: response.data })
	}
	else {
		const response = await axios.put(`${API_USER}/${id}`, formValues,
			{
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
		dispatch({ type: TYPES.UPDATE_USER, payload: response.data })
	}
}

export const deleteUser = (params, id, token) => async dispatch => {
	const response = await axios.delete(`${API_USER}/${id}`, {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	})
		.then(
			await axios.get(API_GET_USERS,
				{
					params: params,
					headers: {
						'Authorization': `Bearer ${token}`
					}
				})
				.then((response) => {
					dispatch({ type: TYPES.GET_USERS_LIST, payload: response.data })
				})
		);

}

export const userLog = (value, token) => async dispatch => {
	try {
		axios.put(API_USER_LOG, value, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		});
	} catch (err) {
		logout(err, dispatch)
	}
}

export const getUserLog = (params, token) => async dispatch => {
	try {
		const response = await axios.get(API_GET_USER_LOG, {
			params: params,
			headers: {
				'Authorization': `Bearer ${token}`
			}
		});
		dispatch({ type: TYPES.GET_USER_LOG, payload: response.data })
	} catch (err) {
		logout(err, dispatch)
	}
}