export const NOTIFICATION_ACTION = 'global.NOTIFICATION_ACTION';

export const notification = (state = {}, action) => {
  console.log(state, action);
  const { type } = action;
  switch (type) {
    case `${NOTIFICATION_ACTION}`:
      return action.payload;
    default:
      return state;
  }
};
