import React, { Component } from 'react';
import {
	VictoryChart,
	VictoryArea,
	VictoryTooltip,
	VictoryVoronoiContainer,
	VictoryAxis
} from 'victory';
import Moment from 'moment'
// eslint-disable-next-line no-unused-vars
import { compose } from 'redux';

class Area extends Component {
	labelFormat = (datum) => {
		const y = this.props.specialAxis ? Moment.duration({ 'minutes': datum.y }).humanize() : datum.y
		return `${Moment(datum.day).format('MMM-D-YY')} ${this.props.ylabel}: ${y}`
	}

	render = () => {
		const length = this.props.data.length
		let daysArr = []
		let valArr = []
		this.props.data.forEach((value) => {
			daysArr.push(value.x)
			valArr.push(value.y)
		})
		return (
			<div>
				{length > 1 ? <VictoryChart
					height={200}
					containerComponent={
						<VictoryVoronoiContainer
							mouseFollowTooltips
							voronoiDimension="x"
							labels={({ datum }) =>
								this.labelFormat(datum)
							}
						/>
					}
				>
					<VictoryArea
						style={{ data: { fill: this.props.blue, stroke: this.props.blue, fillOpacity: 0.1 } }}
						data={this.props.data}
						labelComponent={<VictoryTooltip />}
					/>
					<VictoryAxis crossAxis
						fixLabelOverlap={true}
						tickValues={daysArr}
						tickFormat={(t) => Moment(t).format('MMM-D-YY')}
						style={{ tickLabels: { fontSize: 10, padding: 0 } }}
					/>
					{this.props.specialAxis ?
						<VictoryAxis
							tickValues={[Math.min(...valArr), (Math.min(...valArr) + Math.max(...valArr)) / 2, Math.max(...valArr)]}
							dependentAxis
							style={{ tickLabels: { fontSize: 10, padding: 0 } }}
							tickFormat={(t) => `${Moment.duration({ "minutes": t }).humanize()}`}
						/> : <VictoryAxis
							dependentAxis
							style={{ tickLabels: { fontSize: 10, padding: 0 } }}
							tickFormat={(t) => t}
						/>}
				</VictoryChart> :
					length === 1 ? <div style={{
						fontWeight: 'bold',
						color: '#1890ff'
					}}>
						<div>Date: {this.props.data[0].day}</div>
						<div># of {this.props.ylabel}: {this.props.data[0].y}</div>
					</div> : <div style={{
						fontWeight: 'bold',
						color: '#1890ff'
					}}>
						no data
					</div>}
			</div>
		);
	}
}
export default Area;