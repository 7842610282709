import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  getChatters, persistFilter, persistSorter,
  persistPage
} from '../actionCreators';
import {
  Table, Input, Button,
  DatePicker
} from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { DoubleRightOutlined, UserOutlined } from '@ant-design/icons';
const { Search } = Input;
const { RangePicker } = DatePicker;
class ChatterList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filteredInfo: null,
      sortedInfo: null,
    }
  }
  componentDidMount() {
    this.props.getChatters({
      take: 10,
      page: this.props.page ? this.props.page : 1,
      ...this.props.filters,
      sort_dir: this.props.sorter ? this.props.sorter.order : 'descend',
      sort_key: this.props.sorter ? this.props.sorter.columnKey : 'full_name'
    }, this.props.auth.token);
    this.setState({
      filteredInfo: this.props.filters,
      sortedInfo: this.props.sorter ? this.props.sorter : {
        columnKey: 'full_name',
        order: 'descend'
      }
    })
  }
  listcolumns = (filteredInfo, sortedInfo) => {
    const columns = [
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        render: (value) => <UserOutlined />,
        width: '15px'

      },
      {
        title: 'Name',
        dataIndex: 'full_name',
        key: 'full_name',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'full_name' && sortedInfo.order,
      },
      {
        title: 'First Seen',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (date) => moment(date, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY - HH:mm'),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: '10px', /*background: '#F0F0F0'*/ }} className="custom-filter-dropdown">
            <RangePicker
              value={selectedKeys[0]}
              onChange={date => {
                setSelectedKeys([date]);
                confirm();
              }}
            />
            <div style={{ margin: '10px 0px 0px 0px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button onClick={() => clearFilters()}>Reset</Button>
            </div>
          </div>
        ),
        filteredValue: filteredInfo.created_at || null,
        // sorter: true,
      },
      {
        title: 'Last Seen',
        dataIndex: 'updated_at',
        key: 'updated_at',
        render: (date) => moment(date, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY - HH:mm'),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: '10px', /*background: '#F0F0F0'*/ }} className="custom-filter-dropdown">
            <RangePicker
              value={selectedKeys[0]}
              onChange={date => {
                setSelectedKeys([date]);
                confirm();
              }}
            />
            <div style={{ margin: '10px 0px 0px 0px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button onClick={() => clearFilters()}>Reset</Button>
            </div>
          </div>
        ),
        filteredValue: filteredInfo.updated_at || null,
        // sorter: true
      },
      {
        title: 'Sessions',
        dataIndex: 'session_count',
        key: 'session_count',
        render: (value) => value ? value : '0',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'session_count' && sortedInfo.order,
      },
      {
        title: 'Inquired',
        dataIndex: 'is_talked_to_lawyer',
        key: 'is_talked_to_lawyer',
        render: (value) => value ? 'Yes' : 'No',
        filteredValue: filteredInfo.is_talked_to_lawyer || null,
        filters: [
          { value: 1, text: 'Yes' },
          { value: 0, text: 'No' }
        ]
      },
      {
        title: 'Status',
        dataIndex: 'is_blocked',
        key: 'is_blocked',
        render: (value) => value ? 'Blocked' : 'Active',
        filteredValue: filteredInfo.is_blocked || null,
        filters: [
          { value: 1, text: 'Blocked' },
          { value: 0, text: 'Active' }
        ]
      },
      {
        title: 'ChatBot',
        dataIndex: 'status',
        key: 'status',
        filteredValue: filteredInfo.status || null,
        filters: [
          { value: 'ON', text: 'ON' },
          { value: 'OFF', text: 'OFF' }
        ]
      },

    ];

    return columns;
  }
  onRowClick = (record) => {
    this.props.history.push({
      pathname: `/chatters/${record.id}`,
    })
  }
  handleListChange = (pagination, filters, sorter) => {
    this.props.getChatters({
      q: this.props.chatter.q,
      take: pagination.pageSize,
      page: pagination.current,
      sort_key: sorter.columnKey,
      sort_dir: sorter.order,
      ...filters
    },
      this.props.auth.token)
    this.props.persistFilter(filters)
    this.props.persistSorter(sorter)
    this.props.persistPage(pagination.current)
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    })
  }

  search = (value) => {
    this.props.getChatters({
      q: value,
      take: this.props.chatter.take,
      page: 1,
      sort_key: this.props.chatter.sort_key,
      sort_dir: this.props.chatter.sort_dir,
      ...this.props.chatter.filters
    },
      this.props.auth.token)
  }
  resetTable = () => {
    this.props.getChatters({ take: 10, page: 1 }, this.props.auth.token);
    this.props.persistFilter({});
    this.props.persistSorter({});
    this.props.persistPage(1)
    this.setState({
      filteredInfo: null,
      sortedInfo: {
        columnKey: 'full_name',
        order: 'descend'
      }
    })
  }
  render() {
    const chatter = this.props.chatter;
    let { filteredInfo, sortedInfo } = this.state;
    filteredInfo = filteredInfo || {};
    sortedInfo = sortedInfo || {};
    if (!this.props.auth.token) {
      return <Redirect to={'/login'} />
    }
    return (
      <div>
        <h3 className='ideals-page-header'>
          <DoubleRightOutlined />&nbsp; Client List
        </h3>
        <hr />
        <br />
        <Search placeholder="Search Client Name" onSearch={this.search} enterButton style={{ width: 400 }} />
        <Button type='default' onClick={this.resetTable} style={{ float: 'right' }}>Reset All Filters</Button>
        <br />
        <br />
        <Table
          size="small"
          dataSource={chatter.data}
          columns={this.listcolumns(filteredInfo, sortedInfo)}
          onRow={(record) => ({ onClick: () => this.onRowClick(record) })}
          rowKey={record => {
            const key = record.id + '_' + record.chatter_id
            return key
          }}
          // rowKey='id'
          onChange={this.handleListChange}
          pagination={{
            total: chatter.total,
            current: chatter.page,
            pageSize: chatter.take
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    chatter: state.chatter,
    auth: state.auth,
    filters: state.chatter.filter,
    sorter: state.chatter.sorter,
    page: state.chatter.page
  }
}

export default connect(mapStateToProps, {
  getChatters, persistFilter, persistSorter,
  persistPage
})(ChatterList);