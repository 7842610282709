import { TYPES } from './actionCreators';
import _ from 'lodash';

export default (state = {}, action) => {
  let data;
  switch (action.type) {
    case TYPES.GET_USERS_LIST:
      let values = _.mapKeys(action.payload.data, function (value, key) {
        return value.id;
      })
      return { ...state, ...action.payload, ...values }
    case TYPES.CREATE_USER:
      data = [...state.data];
      data.unshift(action.payload.user);
      return { ...state, total: state.total + 1, data: data, [action.payload.user.id]: action.payload.user };
    case TYPES.DELETE_USER:
      data = [...state.data];
      let index_to_delete;
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === action.payload.id) {
          index_to_delete = i;
        }
      }
      data.splice(index_to_delete, 1);
      let newState = _.omit(state, action.payload.user.id);
      return { ...newState, data: data }
    case TYPES.UPDATE_USER:
      data = [...state.data];
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === action.payload.user.id) {
          data[i] = action.payload.user;
        }
      }
      return { ...state, data: data, [action.payload.user.id]: action.payload.user };
    case TYPES.GET_USER_LOG:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
