import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	getClientConf,
	saveClientConf,
	deleteClientConf
} from '../actionCreators'
import { userLog } from '../../users/actionCreators'
import {
	Table, Drawer, Button, Input,
	Select, Popconfirm
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons'
import { antdInputType } from '../data'

const { Option } = Select;

class ClientInfo extends Component {
	constructor(props) {
		super(props)
		this.state = {
			drawerVisible: false,
			name: '',
			inputType: '',
			options: '',
			update: true,//for re rendering
			columns: [
				{
					title: 'Label',
					dataIndex: 'label',
					key: 'lablel'
				}, {
					title: 'Input Type',
					dataIndex: 'inputType',
					key: 'inputType'
				}, {
					title: 'Options',
					dataIndex: 'options',
					key: 'options'
				}, {
					title: 'Action',
					key: 'action',
					render: (text, record) => (
						<span>
							<Popconfirm
								title="Are you sure you want to delete this configuration?"
								onConfirm={() => {
									this.props.deleteClientConf(record.id, this.props.auth.token)
									this.props.userLog({
										'user_id': this.props.auth.user.id,
										'activity': `Deleted Client Conf`,
									}, this.props.auth.token);
								}}
								okText="Yes"
								cancelText="No"
							>
								<Button
									size="small"
									icon={<DeleteOutlined />}
									danger
								/>
							</Popconfirm>
						</span>
					)
				}
			]
		}
	}
	componentDidMount() {
		this.props.getClientConf(this.props.auth.token)
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevState.update !== this.state.update) {
			this.props.getClientConf(this.props.auth.token)
		}
	}
	addOption = () => {
		this.setState({
			drawerVisible: true
		})
	}
	cancelOption = () => {
		this.setState({
			drawerVisible: false
		})
	}
	inputChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value.replace(/[^a-zA-Zñ, ]/gi, '')
		})
	}
	onSelectInput = (value) => {
		this.setState({
			inputType: value
		})
	}
	onSave = () => {
		const item = {
			label: this.state.label,
			inputType: this.state.inputType,
			options: this.state.inputType === 'Input' || this.state.inputType === 'TextArea' ? 'No options' : this.state.options
		}
		this.props.saveClientConf(item, this.props.auth.token)
		this.props.userLog({
			'user_id': this.props.auth.user.id,
			'activity': `Added New Client Info`,
			'condition': {
				'clinetInfo': this.state.label
			}
		}, this.props.auth.token)
		this.setState({
			drawerVisible: false,
			label: '',
			inputType: '',
			options: '',
			update: !this.state.update
		})
	}
	render() {
		if (!this.props.auth.token) {
			return <Redirect to={'/login'} />
		}
		return (
			<div>
				<Button onClick={this.addOption}>
					Add option
				</Button>
				<div>
					<Drawer
						visible={this.state.drawerVisible}
						onClose={this.cancelOption}
					>
						<label htmlFor='name'>Label</label>
						<Input
							placeholder='Type label'
							name='label'
							value={this.state.label}
							onChange={this.inputChange}
						/>
						<label htmlFor='inputType'>Input Type</label><br />
						<Select
							style={{ width: '100%' }}
							placeholder='Select Input Type'
							value={this.state.inputType}
							onChange={this.onSelectInput}
						>
							{antdInputType.map((item) => {
								return <Option value={item}>{item}</Option>
							})}
						</Select>
						<label htmlFor='options'>Options(seperate with comma)</label>
						<Input
							placeholder='Seperate with comma'
							disabled={this.state.inputType === 'Input' || this.state.inputType === 'TextArea'}
							name='options'
							value={this.state.options}
							onChange={this.inputChange}
						/>
						<Button onClick={this.onSave}>
							Save
						</Button>
					</Drawer>
				</div>
				<Table
					columns={this.state.columns}
					dataSource={this.props.clientConf}
				/>
			</div >
		)
	}
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		clientConf: state.configuration.clientConf
	}
}
export default connect(mapStateToProps, {
	getClientConf,
	saveClientConf,
	deleteClientConf,
	userLog,
})(ClientInfo)