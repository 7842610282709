import React, { Component } from 'react';

class SummaryInformation extends Component {
  render = () => {
    return <div style={this.props.style}>
      <table>
        {
          this.props.data.map((item, index) => {
            return <tr key={index}>
              <td style={{ color: '#888' }}>{item.key}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
              <td>{item.value}</td>
            </tr>
          })
        }
      </table>
    </div>
  }
}

export default SummaryInformation;