import React, { Component } from 'react';
import { Drawer, Table } from 'antd';
import { connect } from 'react-redux';
import { getUserLog } from '../actionCreators'

class UserLog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			columns: [{
				title: 'Created',
				dataIndex: 'updated_at',
				key: 'updated_at'
			}, {
				title: 'Activity',
				dataIndex: 'activity',
				key: 'activity'
			}, {
				title: 'Condition',
				dataIndex: 'condition',
				key: 'condition',
				render: (value) => {
					if (value) {
						return <div>
							{value.clientTag && <span><b>{value.clientTag}</b> to </span>}
							{value.prevStatus && <span> from <b>{value.prevStatus}</b> status</span>}
							{value.session_id && <div>Session ID {value.session_id} of </div>}
							{value.chatter_id && <div>Client ID:{value.chatter_id}</div>}
							{value.chatter_name && <b>{value.chatter_name}</b>}
							{value.role && <span>status: {value.active ? 'Active' : 'Disabled'} <b>{value.role} - {value.userName}</b></span>}
							{value.report && <span>{value.report}</span>}
							{value.oldTag && <span> from <b>{value.oldTag}</b> to </span>}
							{value.newTag && <b>{value.newTag}</b>}
							{value.prevCat && <span> from <b>{value.prevCat}</b> to </span>}
							{value.category && <b>{value.category}</b>}
						</div>
					}
				}
			}
			]
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.userId !== this.props.userId) {
			this.props.getUserLog({
				user_id: this.props.userId,
				take: 10,
				page: 1,
			}, this.props.auth.token)
		}

	}
	handleListChange = (pagination, filters, sorter) => {
		this.props.getUserLog({
			user_id: this.props.userId,
			take: pagination.pageSize,
			page: pagination.current,
			sort_key: sorter.columnKey,
			sort_dir: sorter.order,
			...filters
		}, this.props.auth.token)
	}

	render() {
		return (
			<Drawer
				visible={this.props.visible}
				width={1000}
				onClose={this.props.onClose}
			>
				<Table
					columns={this.state.columns}
					dataSource={this.props.userLog?.data}
					onChange={this.handleListChange}
					pagination={{
						total: this.props.userLog?.total,
						current: this.props.userLog?.page,
						pageSize: this.props.userLog?.take
					}}

				/>
			</Drawer>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		userLog: state.user.UserLog
	}
}

export default connect(mapStateToProps, {
	getUserLog
})(UserLog)