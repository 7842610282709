export const FIELDS = ['Real Name', 'Age', 'Gender', 'Civil Status', 'Employment Status', 'Sector', 'Address', 'Region', 'Province', 'City', 'Contact Number']
export const DROPDOWN_FIELDS = {
	'Age': [
		'',
		'Below 18 years old',
		'18-24 years old',
		'25-34 years old',
		'35-44 years old',
		'45-54 years old',
		'55-64 years old',
		'65-74 years old',
		'75 years or older'
	],
	'Gender': [
		'',
		'Babae',
		'Lalaki',
		'LGBTQ+',
		'Iba pa',
		'Ayaw ko Sabihin'
	],
	'Civil Status': [
		'',
		'Single',
		'Kasal',
		'Live in',
		'Biyuda o biyudo',
		'Wala sa nabanggit'
	],
	'Employment Status': [
		'',
		'May Trabaho',
		'Walang Trabaho'
	],
	'Sector': [
		'',
		'Kababaihan',
		'Kabataan',
		'Manggagawa',
		'Magsasaka',
		'Mangingisda',
		'Maralita',
		'Katutubo',
		'Wala sa nabanggit'
	],
	'Region': [
		'',
		'CAR',
		'BARMM',
		'NCR',
		'Region I',
		'Region II',
		'Region III',
		'Region IV-A',
		'Region IV-B',
		'Region V',
		'Region VI',
		'Region VII',
		'Region VIII',
		'Region IX',
		'Region X',
		'Region XI',
		'Region XII',
		'Region XIII',
	],
	'Source': [
		'',
		'IBP',
		'Facebook',
		'Ibang social media',
		'Media',
		'Nasabi lang sa akin',
		'Iba pa'
	]
}