import axios from '../../shared/utils/axios';
import {
  API_GET_CHATTER, API_GET_CHATTERS, BASE_API_URL
} from '../../shared/config/constants';
import { logout } from '../../shared/utils/api_utils';

export const TYPES = {
  GET_CHATTER: 'GET_CHATTER',
  UPDATE_CHATTER_DATA: 'UPDATE_CHATTER_DATA',
  GET_CHATTERS_LIST: 'GET_CHATTERS_LIST',
  DISABLE_BOT: 'DISABLE_BOT',
  ENABLE_BOT: 'ENABLE_BOT',
  BLOCK_CHATTER: 'BLOCK_CHATTER',
  PERSIST_FILTER_CHATTER: 'PERSIST_FILTER_CHATTER',
  PERSIST_SORTER_CHATTER: 'PERSIST_SORTER_CHATTER',
  PERSIST_TABLE_PAGE_CHATTER: 'PERSIST_TABLE_PAGE_CHATTER'
}

export const getChatter = (id, token) => async dispatch => {
  try {
    const response = await axios.get(`${API_GET_CHATTER}/${id}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    dispatch({ type: TYPES.GET_CHATTER, payload: response.data })
  }
  catch (err) {
    logout(err, dispatch)
  }

}

export const getChatters = (params, token) => async dispatch => {
  try {
    const response = await axios.get(`${API_GET_CHATTERS}`,
      {
        params: params,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    dispatch({ type: `${TYPES.GET_CHATTERS_LIST}_SUCCESS`, payload: response.data })
  }
  catch (err) {
    // TODO: dispatch error notif
    logout(err, dispatch)
  }
}

export const disableBot = (id, token) => async dispatch => {
  try {
    dispatch({
      type: `${TYPES.DISABLE_BOT}_START`
    })
    const response = await axios.post(`${BASE_API_URL}/chatter/${id}/disable_bot`, {},
      { headers: { 'Authorization': `Bearer ${token}` } });
    dispatch({ type: `${TYPES.DISABLE_BOT}_SUCCESS`, payload: response.data })
  }
  catch (err) {
    // TODO: dispatch error notif
  }
}

export const enableBot = (id, token) => async dispatch => {
  try {
    dispatch({
      type: `${TYPES.ENABLE_BOT}_START`
    })
    const response = await axios.post(`${BASE_API_URL}/chatter/${id}/reenable_bot`, {},
      { headers: { 'Authorization': `Bearer ${token}` } });
    dispatch({ type: `${TYPES.ENABLE_BOT}_SUCCESS`, payload: response.data })
  }
  catch (err) {
    // TODO: dispatch error notif
  }
}

export const updateChatterData = (id, data, token) => async dispatch => {
  try {
    dispatch({
      type: `${TYPES.UPDATE_CHATTER_DATA}_START`
    })
    const response = await axios.put(`${BASE_API_URL}/chatter/${id}`, {
      data: data
    },
      { headers: { 'Authorization': `Bearer ${token}` } });
    dispatch({ type: `${TYPES.UPDATE_CHATTER_DATA}_SUCCESS`, payload: response.data })
  }
  catch (err) {
    // TODO: dispatch error notif
  }
}

export const blockChatter = (id, status, token) => async dispatch => {
  try {
    dispatch({
      type: `${TYPES.BLOCK_CHATTER}_START`
    })
    const response = await axios.post(`${BASE_API_URL}/chatter/${id}/block`,
      { status: status },
      { headers: { 'Authorization': `Bearer ${token}` } });
    dispatch({ type: `${TYPES.BLOCK_CHATTER}_SUCCESS`, payload: response.data })
  }
  catch (err) {
    // TODO: dispatch error notif
  }
}

export const persistFilter = (filters) => async dispatch => {
  dispatch({ type: TYPES.PERSIST_FILTER_CHATTER, payload: filters })
}

export const persistSorter = (sorter) => async dispatch => {
  dispatch({ type: TYPES.PERSIST_SORTER_CHATTER, payload: sorter })
}

export const persistPage = (page) => async dispatch => {
  dispatch({ type: TYPES.PERSIST_TABLE_PAGE_CHATTER, payload: page })
}
