import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getChatter } from '../../chatters/actionCreators';
import {
  getSession,
  startConversation,
  endConversation,
  setIsFollowUp,
  setIsSpam,
  saveNote,
  getNote,
  getTags,
  saveTag,
  getSessionTag,
  addTag,
  deleteTag,
  deleteClientTag,
  addInquiryDes,
  deleteNote,
  saveCat,
} from '../actionCreators';
import { updateChatterData } from '../../chatters/actionCreators'
import { userLog } from '../../users/actionCreators'
import { getCatConfig } from '../../configuration/actionCreators'
import {
  Button, Input, Popconfirm, AutoComplete,
  List, Drawer, Row, Col, Tag, Select
} from 'antd';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import {
  StarFilled,
  StarOutlined,
  WarningFilled,
  WarningOutlined,
  DoubleRightOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import FormInformation from '../../chatters/components/FormInformation';
import SummaryInformation from '../../../shared/components/SummaryInformation'
import DrawerFeedback from '../components/DrawerFeedback';
import { TweenOneGroup } from 'rc-tween-one';
import { PlusOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import { set } from 'date-fns';

const { Option } = Select;
class SessionDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      note: '',
      inquiry_des: '',
      display_inquiry_des: '',
      display_client_category: '',
      tags: [],
      currentTag: '',
      inputVisible: false,
      inputValue: '',
      visible: false,
      note_form_visible: false,
      inquiry_form_visible: false,
      category_form_visible: false,
      tag: '',
      setTags: true,
      availableTags: [],
      optionVal: [],
      textAreaTag: '',
      tagModalVisible: false,
      category: '',
      clienyCategory: '',
    }
    this.ref_FormInformation = React.createRef();
    this.ref_DrawerFeedback = React.createRef();
  }
  componentDidMount() {
    this.props.getTags(this.props.auth.token);
    this.props.getCatConfig(this.props.auth.token);
    this.initializeData();
  }

  initializeData = () => {
    const id = this.props.match.params.id
    const chatter_id = this.props.match.params.chatter_id
    const session_key = id + '_' + chatter_id
    this.props.getChatter(this.props.match.params.chatter_id, this.props.auth.token)
    this.props.getSession({
      id: this.props.match.params.id,
      chatter_id: this.props.match.params.chatter_id
    }, this.props.auth.token)
    this.props.getNote({
      'session_id': this.props.match.params.id,
      'chatter_id': this.props.match.params.chatter_id
    }, this.props.auth.token)
    this.props.getSessionTag({
      'session_id': this.props.match.params.id,
      'chatter_id': this.props.match.params.chatter_id
    }, this.props.auth.token)
    this.setState({
      setTags: true,
      display_inquiry_des: _.get(this, `props.session[${session_key}].inquiry_des`, 0) !== 0 ?
        this.props.session[session_key].inquiry_des : '',
      display_client_category: _.get(this, `props.session[${session_key}].category`, 0) !== 0 ?
        this.props.session[session_key].category : '',
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.initializeData();
    }
    if ((prevProps.sessionTagList !== this.props.sessionTagList)
      && (this.props.sessionTagList && this.props.sessionTagList[0])) {
      const tagStr = this.props.sessionTagList?.[0]?.['tags'] ?? ''
      const tags = tagStr.replace(/['"]+/g, '').split(',')
      this.setState({
        tags: tags
      })
    }
    if (prevProps.inquiry_des !== this.props.inquiry_des) {
      this.setState({
        display_inquiry_des: this.props.inquiry_des.result.inquiry_des
      })
    }
    if (prevProps.clientCat !== this.props.clientCat) {
      console.log(this.props.clientCat)
      this.setState({
        display_client_category: this.props.clientCat.result.category
      })
    }
    if (prevProps.tagList !== this.props.tagList || prevState.tags !== this.state.tags ||
      ((prevProps.sessionTagList !== this.props.sessionTagList) && (this.props.sessionTagList && this.props.sessionTagList[0]))) {
      const tags = this.props.sessionTagList?.[0]?.tags ?? this.state.tags
      let optionArr = []
      let valueArr = []
      _.each(this.props.tagList, (item) => {
        optionArr.push(item.tag)
      })
      const filteredArr = optionArr.filter(n => !tags.includes(n))
      _.each(filteredArr, (item) => {
        valueArr.push({ value: item })
      })
      this.setState({
        availableTags: filteredArr,
        optionVal: valueArr
      })
    }
  }

  handleStart = () => {
    this.props.startConversation({
      id: this.props.match.params.id,
      chatter_id: this.props.match.params.chatter_id
    }, this.props.auth.token)
    this.props.userLog({
      'user_id': this.props.auth.user.id,
      'activity': 'Started Conversation',
      'condition': {
        'session_id': this.props.match.params.id,
        'chatter_id': this.props.match.params.chatter_id,
        'chatter_name': this.props.chatter[this.props.match.params.chatter_id].full_name
      }
    }, this.props.auth.token)
  }

  handleEnd = () => {
    this.props.endConversation({
      id: this.props.match.params.id,
      chatter_id: this.props.match.params.chatter_id
    }, this.props.auth.token)
    this.props.userLog({
      'user_id': this.props.auth.user.id,
      'activity': 'Ended Conversation',
      'condition': {
        'session_id': this.props.match.params.id,
        'chatter_id': this.props.match.params.chatter_id,
        'chatter_name': this.props.chatter[this.props.match.params.chatter_id].full_name
      }
    }, this.props.auth.token)
  }
  saveCat = () => {
    const item = {
      'session_id': this.props.match.params.id,
      'chatter_id': this.props.match.params.chatter_id,
      'category': this.state.clienyCategory
    }
    console.log(item)
    this.props.saveCat(item, this.props.auth.token)
    this.setState({
      clienyCategory: '',
      category_form_visible: !this.state.category_form_visible
    })
  }
  saveInquiry = () => {
    const item = {
      'session_id': this.props.match.params.id,
      'chatter_id': this.props.match.params.chatter_id,
      'inquiry_des': this.state.inquiry_des
    }
    this.props.addInquiryDes(item, this.props.auth.token)
    this.props.userLog({
      'user_id': this.props.auth.user.id,
      'activity': 'Added Inquiry Description',
      'condition': {
        'session_id': this.props.match.params.id,
        'chatter_id': this.props.match.params.chatter_id,
        'chatter_name': this.props.chatter[this.props.match.params.chatter_id].full_name
      }
    }, this.props.auth.token)
    this.setState({
      inquiry_des: '',
      inquiry_form_visible: !this.state.inquiry_form_visible,
    })
  }

  saveNote = () => {
    const id = this.props.match.params.id;
    const chatter_id = this.props.match.params.chatter_id;
    const note = {
      'session_id': id,
      'chatter_id': chatter_id,
      'notes': this.state.note,
      'user': this.props.auth.user.name
    }
    this.props.saveNote(note, this.props.auth.token);
    this.props.getNote({
      'session_id': this.props.match.params.id,
      'chatter_id': this.props.match.params.chatter_id
    }, this.props.auth.token)
    this.setState({
      note: ''
    })
    this.props.userLog({
      'user_id': this.props.auth.user.id,
      'activity': 'Added Note',
      'condition': {
        'session_id': this.props.match.params.id,
        'chatter_id': this.props.match.params.chatter_id,
        'chatter_name': this.props.chatter[this.props.match.params.chatter_id].full_name
      }
    }, this.props.auth.token)
  }
  deleteNote = (noteid) => {
    const id = this.props.match.params.id;
    const chatter_id = this.props.match.params.chatter_id;
    const item = {
      'session_id': id,
      'chatter_id': chatter_id,
      'id': noteid,
      'user': this.props.auth.user.name
    }
    this.props.deleteNote(item, this.props.auth.token)
    this.props.userLog({
      'user_id': this.props.auth.user.id,
      'activity': 'Deleted Note',
      'condition': {
        'session_id': this.props.match.params.id,
        'chatter_id': this.props.match.params.chatter_id,
        'chatter_name': this.props.chatter[this.props.match.params.chatter_id].full_name
      }
    }, this.props.auth.token)
  }
  textAreaChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  textAreaTagChange = (event) => {
    this.setState({
      textAreaTag: event.target.value.substring(0, 35)
    })
  }

  addSpamOrFF = (condition, tagSpecial) => {
    if (condition) {
      const tags = this.state.tags.filter(tag => tag !== tagSpecial);
      this.setState({ tags: tags });
      const item = {
        chatter_id: this.props.match.params.chatter_id,
        session_id: this.props.match.params.id,
        tags: `"${tagSpecial}"`
      }
      this.props.deleteClientTag(item, this.props.auth.token)
      this.props.userLog({
        'user_id': this.props.auth.user.id,
        'activity': `Unmarked as ${tagSpecial}`,
        'condition': {
          'session_id': this.props.match.params.id,
          'chatter_id': this.props.match.params.chatter_id,
          'chatter_name': this.props.chatter[this.props.match.params.chatter_id].full_name
        }
      }, this.props.auth.token)
    }
    else {
      let { tags } = this.state;
      tags = [...tags, tagSpecial];
      this.setState({
        currentTag: tagSpecial,
        tags,
      });
      const tagItem = {
        'session_id': this.props.match.params.id,
        'chatter_id': this.props.match.params.chatter_id,
        'tag': tagSpecial
      }
      this.props.saveTag(tagItem, this.props.auth.token)
      this.props.userLog({
        'user_id': this.props.auth.user.id,
        'activity': `Marked as ${tagSpecial}`,
        'condition': {
          'session_id': this.props.match.params.id,
          'chatter_id': this.props.match.params.chatter_id,
          'chatter_name': this.props.chatter[this.props.match.params.chatter_id].full_name
        }
      }, this.props.auth.token)
    }
  }
  toggleIsFollowUp = () => {
    const permission = this.props.auth.user.permissions;
    if (permission.includes('add_tag')) {
      const session_key = this.props.match.params.id + '_' + this.props.match.params.chatter_id
      const sessionData = this.props.session[session_key]

      this.props.setIsFollowUp({
        id: this.props.match.params.id,
        chatter_id: this.props.match.params.chatter_id,
        value: !sessionData['is_follow_up']
      }, this.props.auth.token)
      this.addSpamOrFF(sessionData['is_follow_up'], 'Follow-up')
    }
  }
  toggleIsSpam = () => {
    const permission = this.props.auth.user.permissions;
    if (permission.includes('add_tag')) {
      const session_key = this.props.match.params.id + '_' + this.props.match.params.chatter_id
      const sessionData = this.props.session[session_key]

      this.props.setIsSpam({
        id: this.props.match.params.id,
        chatter_id: this.props.match.params.chatter_id,
        value: !sessionData['is_spam']
      }, this.props.auth.token)
      this.addSpamOrFF(sessionData['is_spam'], 'Spam')
    }
  }

  saveTag = () => {
    const id = this.props.match.params.id;
    const chatter_id = this.props.match.params.chatter_id;
    const tags = {
      'session_id': id,
      'chatter_id': chatter_id,
      'tag': this.state.currentTag
    }
    this.props.saveTag(tags, this.props.auth.token)
    this.props.getTags(this.props.auth.token)
    this.props.userLog({
      'user_id': this.props.auth.user.id,
      'activity': `Added Tag`,
      'condition': {
        'clientTag': this.state.currentTag,
        'session_id': id,
        'chatter_id': chatter_id,
        'chatter_name': this.props.chatter[chatter_id].full_name
      }
    }, this.props.auth.token)
  }
  onChange = () => {
    this.setState({
      visible: false
    })
  }
  showDrawerTags = () => {
    this.setState({
      visible: true,
    });
  };
  onCloseDrawerTags = () => {
    this.setState({
      visible: false,
    });
  }
  updateTag = () => {
    this.props.addTag({
      tag: this.state.tag.replace(/[^a-zA-Zñ ]/gi, ''),
      category: this.state.category
    }, this.props.auth.token)
    this.props.userLog({
      'user_id': this.props.auth.user.id,
      'activity': `Created Tag`,
      'condition': {
        'newTag': this.state.tag.replace(/[^a-zA-Zñ ]/gi, '')
      }
    }, this.props.auth.token)
    this.setState({
      visible: false,
      tag: ''
    })
  }
  handleFieldChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  showFormInformation = () => {
    this.ref_FormInformation.current.show();
  }
  showDrawerFeedback = () => {
    _.get(this, 'ref_DrawerFeedback.current', false) && this.ref_DrawerFeedback.current.show();
  }
  showInquiryForm = () => {
    this.setState({ inquiry_form_visible: !this.state.inquiry_form_visible })
  }
  showCategoryForm = () => {
    this.setState({ category_form_visible: !this.state.category_form_visible })
  }
  showNoteForm = () => {
    this.setState({ note_form_visible: true });
  }
  hideNoteForm = () => {
    this.setState({ note_form_visible: false });
  }
  submit_FormInformation = (data) => {
    this.props.updateChatterData(this.props.match.params.chatter_id, data, this.props.auth.token);
    this.ref_FormInformation.current.hide();
  }
  deleteTag = (id) => {
    this.props.deleteTag(id, this.props.auth.token);
    this.props.userLog({
      'user_id': this.props.auth.user.id,
      'activity': `Deleted Tag`,
      'condition': {
        'newTag': id
      }
    }, this.props.auth.token);
    this.props.getTags(this.props.auth.token);
  }

  handleClose = removedTag => {
    const tags = this.state.tags.filter(tag => tag !== removedTag);
    this.setState({ tags });
    const item = {
      chatter_id: this.props.match.params.chatter_id,
      session_id: this.props.match.params.id,
      tags: `"${removedTag}"`
    }
    this.props.userLog({
      'user_id': this.props.auth.user.id,
      'activity': `Deleted Tag`,
      'condition': {
        'clientTag': removedTag,
        'session_id': this.props.match.params.id,
        'chatter_id': this.props.match.params.chatter_id,
        'chatter_name': this.props.chatter[this.props.match.params.chatter_id].full_name
      }
    }, this.props.auth.token)
    window.setTimeout(this.props.deleteClientTag(item, this.props.auth.token), 100);
  };

  showInput = () => {
    //using Input
    //this.setState({ inputVisible: true }, () => this.input.focus());
    this.setState({
      inputVisible: true
    })
  };

  handleInputChange = e => {
    //using Input
    // this.setState({ inputValue: e.target.value });

    let { tags } = this.state;
    tags = [...tags, e];
    // console.log('Input Confirm', e);
    this.setState({
      currentTag: e,
      tags,
      inputVisible: false,
      inputValue: '',
    });
    window.setTimeout(this.saveTag, 100);
  };

  saveInputRef = input => (this.input = input);


  forMap = tag => {
    const permissions = this.props.auth.user.permissions;
    const tagElem = (
      <Tag
        closable={
          (tag === 'Spam' || tag === 'Follow-up' ||
            !permissions.includes('add_tag')
          ) ? false : true
        }
        color={
          (tag === 'Spam' || tag === 'Follow-up') ?
            (tag === 'Follow-up') ? 'gold' : 'red' : 'blue'
        }
        onClose={e => {
          e.preventDefault();
          this.handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };

  onSelect = (data) => {
    let { tags } = this.state;
    tags = [...tags, data];
    this.setState({
      currentTag: data,
      tags,
      // inputVisible: false,
      inputValue: '',
    }, this.setState({ textAreaTag: '' }));
    window.setTimeout(this.saveTag, 100);
  }

  onSearch = (searchText) => {
    const availalbleTags = this.state.availableTags.filter(n => n.toLowerCase().includes(searchText.toLowerCase()))
    let optionsArr = []
    _.each(availalbleTags, (item) => {
      optionsArr.push({
        value: item
      })
    })
    this.setState({
      optionVal: optionsArr
    })
  }
  onTagModalCancel = () => {
    this.setState({
      tagModalVisible: false,
      // inputVisible: false,
      textAreaTag: ''
    }, this.setState({ textAreaTag: '' }))
  }
  onTagModalOk = () => {
    let { tags } = this.state
    tags = [...tags, this.state.textAreaTag.replace(/[^a-zA-Zñ ]/gi, '')]
    this.setState({
      currentTag: this.state.textAreaTag.replace(/[^a-zA-Zñ ]/gi, ''),
      tags,
      // inputVisible: false,
      inputValue: '',
      tagModalVisible: false
    }, this.setState({ textAreaTag: '' }))
    window.setTimeout(this.saveTag, 100);
    this.props.addTag({
      tag: this.state.textAreaTag.replace(/[^a-zA-Zñ ]/gi, ''),
      category: this.state.category
    }, this.props.auth.token)
  }
  onPressEnter = () => {
    this.setState({
      tagModalVisible: true
    })
  }
  hideAutoComp = () => {
    this.setState({
      inputVisible: false,
      textAreaTag: ''
    })
  }
  selectCategory = (value) => {
    this.setState({
      category: value
    })
  }
  selectClientCategory = (value) => {
    this.setState({
      clienyCategory: value
    })
  }
  render() {
    const id = this.props.match.params.id
    const chatter_id = this.props.match.params.chatter_id
    const session_key = id + '_' + chatter_id

    const chatter = this.props.chatter[chatter_id]
    const sessionData = this.props.session[session_key]

    const { tags, inputVisible, inputValue } = this.state;
    const tagSet = [...new Set(tags)]
    const tagChild = tagSet.map(this.forMap)

    if (!this.props.auth.token) {
      return <Redirect to={'/login'} />
    }
    if (!this.props.chatter[chatter_id]) {
      return <div></div>
    }
    if (!this.props.session[session_key]) {
      return <div></div>
    }
    /** to show feedback when clicked from ChatterDetail */
    _.get(this, 'props.location.state.showFeedback', false) && this.showDrawerFeedback()
    const permissions = this.props.auth.user.permissions;
    const sessionObj = sessionData.data
    var data = [];
    _.each(sessionObj, (value, key) => {
      data.push({
        key: key,
        value: value
      })
    })

    var additional_data = [];
    if (sessionData.assigned_to) {
      additional_data.push({
        key: 'Assigned To',
        value: this.props.user[sessionData.assigned_to] ? this.props.user[sessionData.assigned_to].name : '-'
      })
    }

    var chatterData = [];
    _.each(chatter.data, (value, key) => {
      chatterData.push({
        key: key,
        value: value
      })
    })

    var inquiries = sessionData.inquiry;
    var feedback = sessionData.feedback;

    let notes = []
    _.each(this.props.session.note, (value, key) => {
      notes.push(
        value
      )
    })
    let tagsList = []
    _.each(this.props.tagList, (value) => {
      tagsList.push({
        key: value.tag,
        value: value.tag
      })
    })

    var session_status_tag;
    if (sessionData.status === 'BOT') {
      session_status_tag = <Tag size="small" color={'#ffc72e'} style={{ marginLeft: '10px' }}>ChatBot</Tag>
    }
    else if (sessionData.status === 'QUEUE') {
      session_status_tag = <Tag size="small" color={'#f07022'} style={{ marginLeft: '10px' }}>Queued</Tag>
    }
    else if (sessionData.status === 'ONGOING_CHAT') {
      session_status_tag = <Tag size="small" color={'#0058e9'} style={{ marginLeft: '10px' }}>Ongoing</Tag>
    }
    else if (sessionData.status === 'DONE') {
      session_status_tag = <Tag size="small" color={'#1f943e'} style={{ marginLeft: '10px' }}>Completed</Tag>
    }

    return (
      <div>
        <h3 className='ideals-page-header'>
          <DoubleRightOutlined />&nbsp; Client Inquiry
          <span style={{ float: 'right' }}>
            <Button size="small" onClick={() => { this.props.history.push({ pathname: '/sessions/list' }) }}>Back to Conversation List</Button>
          </span>
        </h3>
        <hr />
        <Row>
          <Col span={16}>
            <div style={{ marginRight: '20px' }}>
              <br />
              <h3>{chatter.full_name} {session_status_tag}


                <span style={{ float: 'right' }}>

                  {
                    sessionData.is_spam === 1 || sessionData.is_spam === true ?
                      <WarningFilled onClick={this.toggleIsSpam} style={{ color: '#cc0000', fontSize: '1.3em', verticalAlign: 'bottom' }} /> :
                      <WarningOutlined onClick={this.toggleIsSpam} style={{ color: '#cc0000', fontSize: '1.3em', verticalAlign: 'bottom' }} />
                  }
                  &nbsp;
                  {
                    sessionData.is_follow_up === 1 || sessionData.is_follow_up === true ?
                      <StarFilled onClick={this.toggleIsFollowUp} style={{ color: '#e1ad01', fontSize: '1.3em', verticalAlign: 'bottom' }} /> :
                      <StarOutlined onClick={this.toggleIsFollowUp} style={{ color: '#e1ad01', fontSize: '1.3em', verticalAlign: 'bottom' }} />
                  }
                  &nbsp;&nbsp;&nbsp;
                  {
                    (!(sessionData.status === 'ONGOING_CHAT' || sessionData.status === 'BOT')) && <Button size="small" type="primary" onClick={this.handleStart}>Start Conversation</Button>
                  }
                  {
                    sessionData.status === 'ONGOING_CHAT' && <Button size="small" type="primary" onClick={this.handleEnd}>End Conversation</Button>
                  }
                </span>

              </h3>
              <SummaryInformation
                data={[
                  { key: 'Session ID', value: sessionData.id },
                  { key: 'Date & Time', value: sessionData.created_at },
                  ...data,
                  ...additional_data
                ]}
              />
              <a className='ideals-clickable-text' onClick={this.showDrawerFeedback}>View Feedback</a>

            </div>
            <hr></hr>
            <div>

              <br />
              {
                inquiries !== null && inquiries.length > 0 &&
                <h3>Inquiry</h3>
              }
              {
                inquiries !== null &&
                inquiries.map((item) => {
                  return <p key={item}>{item}</p>
                })
              }

            </div>

          </Col>
          <Col span={8} style={{ borderLeft: '1px solid #ccc' }}>
            <div style={{ marginLeft: '20px' }}>
              <div className='ideals-right-heading'>
                <h3>{chatter.full_name}</h3>
                <span style={{ float: 'right' }}>
                  <a className='ideals-clickable-text' onClick={this.showFormInformation}>Edit Information</a>
                </span>
              </div>
              <div style={{ clear: 'both' }}></div>
              <SummaryInformation
                data={[
                  ...chatterData
                ]}
              />

            </div>
            <hr />
            <div style={{ marginLeft: '20px' }}>
              <div className='ideals-right-heading'>
                <h3>Inquiry Description</h3>
              </div>
              <span style={{ float: 'right' }}>
                <a className='ideals-clickable-text' onClick={this.showInquiryForm}>Edit Description</a>
              </span>
              <div style={{ clear: 'both' }}></div>
              <div style={{ 'display': this.state.inquiry_form_visible ? 'block' : 'none' }}>
                <TextArea
                  id='inquiry_des'
                  name='inquiry_des'
                  rows={3}
                  value={this.state.inquiry_des}
                  onChange={this.textAreaChange}
                />
                <span style={{ float: 'right', margin: '10px' }}>
                  <Button size="small" onClick={this.showInquiryForm}>Cancel</Button>
                  &nbsp;&nbsp;
                  <Button
                    disabled={this.state.inquiry_des.length === 0}
                    type="primary"
                    size="small"
                    onClick={this.saveInquiry}
                  >
                    Save Description
                  </Button>
                </span>
              </div>
              <div style={{ clear: 'both' }}></div>
              <div>{this.state.display_inquiry_des}</div>
            </div>
            <hr />
            <div style={{ marginLeft: '20px' }}>
              <div className='ideals-right-heading'>
                <h3>Category</h3>
              </div>
              <span style={{ float: 'right' }}>
                <a className='ideals-clickable-text' onClick={this.showCategoryForm}>Edit Category</a>
              </span>
              <div style={{ clear: 'both' }}></div>
              <div style={{ 'display': this.state.category_form_visible ? 'block' : 'none' }}>
                <Select
                  style={{ width: '100%' }}
                  onChange={this.selectClientCategory}
                  value={this.state.clienyCategory}
                >
                  {
                    this.props.catConfig?.map((item) => {
                      return <Option value={item.category}>{item.category}</Option>
                    })
                  }
                </Select>
                <span style={{ float: 'right', margin: '10px' }}>
                  <Button size="small" onClick={this.showCategoryForm}>Cancel</Button>
                  &nbsp;&nbsp;
                  <Button
                    disabled={this.state.clienyCategory.length === 0}
                    type="primary"
                    size="small"
                    onClick={this.saveCat}
                  >
                    Save Category
                  </Button>
                </span>
              </div>
              <div style={{ clear: 'both' }}></div>
              <div>{this.state.display_client_category}</div>
            </div>
            <hr />
            <div style={{ marginLeft: '20px' }}>
              <div className='ideals-right-heading'>
                <h3>Tags</h3>
                {permissions.includes('add_tag') && <span style={{ float: 'right' }}>
                  <a className='ideals-clickable-text' onClick={this.showDrawerTags}>Manage Tags</a>
                </span>}
              </div>
              <div style={{ clear: 'both' }}></div>

              <div>
                <div style={{ marginBottom: 16, lineHeight: '2.2em' }}>
                  <TweenOneGroup
                    enter={{
                      scale: 0.8,
                      opacity: 0,
                      type: 'from',
                      duration: 100,
                      onComplete: e => {
                        e.target.style = '';
                      },
                    }}
                    leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                    appear={false}
                  >
                    {tagChild}
                  </TweenOneGroup>
                </div>
                {inputVisible && permissions.includes('add_tag') && (
                  <div>
                    <AutoComplete
                      options={this.state.optionVal}
                      style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
                      onSelect={this.onSelect}
                      onSearch={this.onSearch}
                      value={this.state.textAreaTag}
                    >
                      <TextArea
                        id='textAreaTag'
                        name='textAreaTag'
                        placeholder="add tag"
                        style={{ height: 32 }}
                        value={this.state.textAreaTag}
                        onChange={this.textAreaTagChange}
                        onPressEnter={this.onPressEnter}
                      />
                    </AutoComplete>
                    <span style={{ float: 'right', margin: '10px' }}>
                      <Button size='small' onClick={this.hideAutoComp}>Cancel</Button>
                    </span>
                    <Modal
                      title="Basic Modal"
                      visible={this.state.tagModalVisible}
                      onOk={this.onTagModalOk}
                      onCancel={this.onTagModalCancel}
                    >
                      <p>Please make sure that this <b>{this.state.textAreaTag}</b> does NOT EXIST yet
                      before pressing OK. Otheriwse select from the list </p>
                      <Select
                        placeholder='Select Category'
                        onChange={this.selectCategory}
                      >
                        {
                          this.props.catConfig?.map((item) => {
                            return <Option value={item.category}>{item.category}</Option>
                          })
                        }
                      </Select>
                    </Modal>
                  </div>
                )}
                {!inputVisible && permissions.includes('add_tag') && (
                  <Tag
                    color="blue"
                    onClick={this.showInput}
                    className="site-tag-plus">
                    <PlusOutlined /> Add Tag
                  </Tag>
                )}
              </div>
              <br />
            </div>
            <hr />
            <div style={{ marginLeft: '20px' }}>
              <div className='ideals-right-heading'>
                <h3>Notes</h3>
                {permissions.includes('add_notes') && <span style={{ float: 'right' }}>
                  <a className='ideals-clickable-text' onClick={this.showNoteForm}>Add Note</a>
                </span>}
              </div>
              <div style={{ clear: 'both' }}></div>

              <div style={{ 'display': this.state.note_form_visible ? 'block' : 'none' }}>
                <TextArea
                  id='note'
                  name='note'
                  rows={3}
                  value={this.state.note}
                  onChange={this.textAreaChange}
                />
                <span style={{ float: 'right', margin: '10px' }}>
                  <Button size="small" onClick={this.hideNoteForm}>Cancel</Button>
                  &nbsp;&nbsp;
                  <Button
                    disabled={this.state.note.length === 0}
                    type="primary"
                    size="small"
                    onClick={this.saveNote}>
                    Save Note
                  </Button>
                </span>
              </div>
              <div style={{ clear: 'both' }}></div>
              {
                notes.map((item) => {
                  return <div key={item.id}>
                    <div style={{ fontSize: '0.8em', color: '#888', height: '1.7em' }}>{item.user}-{item.created_at}<br /></div>
                    <div style={{ fontSize: '1em', lineHeight: '1em' }}>{item.notes}
                      <Button
                        style={{ float: 'right' }}
                        size='small'
                        icon={<DeleteOutlined />}
                        onClick={() => this.deleteNote(item.id)}
                      />
                    </div>
                    <br />
                  </div>
                })
              }

              <div style={{ height: '30px' }} />
            </div>

          </Col>
        </Row>

        <Drawer
          title="Manage Tags"
          placement="right"
          closable={true}
          onClose={this.onCloseDrawerTags}
          visible={this.state.visible}
          width={500}
        >
          <Input
            id='tag'
            name='tag'
            value={this.state.tag}
            onChange={this.handleFieldChange}
          />
          <Select
            placeholder='Select Category'
            onChange={this.selectCategory}
          >
            {
              this.props.catConfig?.map((item) => {
                return <Option value={item.category}>{item.category}</Option>
              })
            }
          </Select>
          <div style={{ float: 'right', marginTop: '10px' }}>
            <Button
              disabled={this.state.tag.length === 0}
              onClick={this.updateTag}
              size="small"
            >Add Tag
          </Button>
          </div>
          <div style={{ clear: 'both' }}></div>
          <br />
          <List
            size="small"
            header=''
            bordered
            dataSource={tagsList}
            renderItem={item => (
              <div>
                <List.Item key={item.key}>
                  {item.value}
                  <Popconfirm
                    title="Are you sure delete this tag?"
                    onConfirm={() => { this.deleteTag(item.key) }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      style={{ float: 'right' }}
                      // type='primary'
                      size='small'
                      // danger
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>

                </List.Item>
              </div>
            )}
          />
        </Drawer>

        <FormInformation
          data={chatter.data}
          id={chatter_id}
          ref={this.ref_FormInformation}
          onSubmit={this.submit_FormInformation}
        />
        <DrawerFeedback session={sessionData} feedback={feedback} ref={this.ref_DrawerFeedback}
          chatter_name={chatter.full_name} userId={this.props.auth.user.id} token={this.props.auth.token}
        />

      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
    chatter: state.chatter,
    auth: state.auth,
    tagList: state.session.tagList,
    sessionTagList: state.session.sessionTagList,
    user: state.user,
    inquiry_des: state.session.inquiry_des,
    clientCat: state.session.clientCat,
    catConfig: state.configuration.CatConfig,
  }
}

export default connect(mapStateToProps, {
  getChatter,
  getSession,
  startConversation,
  endConversation,
  saveNote,
  getNote,
  setIsFollowUp,
  setIsSpam,
  getTags,
  saveTag,
  getSessionTag,
  addTag,
  updateChatterData,
  deleteTag,
  deleteClientTag,
  addInquiryDes,
  deleteNote,
  userLog,
  getCatConfig,
  saveCat
})(SessionDetail)
