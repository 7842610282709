import { TYPES } from './actionCreators';
import _ from 'lodash';

export default (state = {}, action) => {
	var session_key = null;
	switch (action.type) {
		case TYPES.GET_SESSIONS_LIST_FOR_REPORTS:
			return { ...state, sessions: action.payload }
		case TYPES.GET_CHATTERS_LIST_FOR_REPORTS:
			return { ...state, chatters: action.payload }
		case TYPES.GET_SESSIONS_COUNT:
			return { ...state, sessionCount: action.payload }
		case TYPES.GET_CHATTERS_COUNT:
			return { ...state, chatterCount: action.payload }
		case TYPES.GET_FEEDBACK_DATA:
			return { ...state, feedbackData: action.payload }
		case TYPES.LEGAL_LOADING:
			return { ...state, legalLoading: action.payload }
		case TYPES.SESSION_LOADING:
			return { ...state, sessionLoading: action.payload }
		case TYPES.CHATTER_LOADING:
			return { ...state, chatterLoading: action.payload }
		default:
			return state;
	}

}