import React, { Component } from 'react';
import {
	VictoryChart,
	VictoryBar,
	VictoryVoronoiContainer,
	VictoryTooltip,
	VictoryAxis,
} from 'victory';

class HorizontalBar extends Component {
	render = () => {
		const colorArr = this.props.colorArr
		const dateRange = this.props.dateRange ? this.props.dateRange : '';
		return (
			<VictoryChart
				padding={{
					left: this.props.left ? this.props.left : 200,
					right: 10,
					top: 50,
					bottom: 50
				}}
				style={{
					parent: {
						maxWidth: this.props.maxWidth ? this.props.maxWidth : '100%'
					}
				}}
				containerComponent={
					<VictoryVoronoiContainer
						mouseFollowTooltips
						voronoiDimension="x"
						labels={({ datum }) =>
							`${dateRange}\ncount:${datum.count} ${((datum.count / this.props.total) * 100).toFixed(2)}%`}
						labelComponent={
							<VictoryTooltip
								style={{
									fontSize: 14
								}}
							/>
						}
					/>
				}
			>
				<VictoryBar horizontal
					style={{
						data: {
							fill: ({ index }) =>
								colorArr[index + (colorArr.length - this.props.data.length)]
						}
					}}
					data={this.props.data}
					x={this.props.x}
					y={this.props.y}
				/>
				{this.props.yAxis && <VictoryAxis
					dependentAxis
				/>}
				{this.props.yAxis && <VictoryAxis
					crossAxis
					tickValues={this.props.yAxis}
				/>}
			</VictoryChart>
		);
	}

}

export default HorizontalBar;