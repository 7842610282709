//export const BASE_URL = process.env.REACT_APP_BASE_URL !== undefined ? process.env.REACT_APP_BASE_URL : (process.env.NODE_ENV === 'development' ? 'http://192.168.142.147:8000' : 'https://api-qms.tisyahustisya.org');
export const BASE_URL = process.env.REACT_APP_BASE_URL !== undefined ? process.env.REACT_APP_BASE_URL : (process.env.NODE_ENV === 'development' ? 'https://api-qms.tisyahustisya.org' : 'https://api-ideals.staging.acctechnology.ph');
//export const BASE_URL = 'http://192.168.142.133:8000';

export const TIMEOUT = 600000;
export const BASE_API_URL = `${BASE_URL}/api`;
export const API_GET_USERS = `${BASE_API_URL}/users`;
export const API_CREATE_USER = `${BASE_API_URL}/create_user`;
export const API_USER = `${BASE_API_URL}/user`;
export const API_LOGIN_START = `${BASE_API_URL}/login`;
export const API_USER_LOG = `${BASE_API_URL}/userLog`;
export const API_GET_USER_LOG = `${BASE_API_URL}/getUserLog`;

export const API_GET_SESSIONS = `${BASE_API_URL}/sessions`;
export const API_GET_SESSION = `${BASE_API_URL}/session`;
export const API_START_CONVERSATION = `${BASE_API_URL}/session/start_conversation`;
export const API_END_CONVERSATION = `${BASE_API_URL}/session/end_conversation`;
export const API_SET_IS_FOLLOW_UP = `${BASE_API_URL}/session/set_follow_up`;
export const API_SET_IS_SPAM = `${BASE_API_URL}/session/set_spam`;
export const API_GET_SESSIONS_COUNT = `${BASE_API_URL}/session/session_count`;
export const API_GET_STATUS_COUNT = `${BASE_API_URL}/session/status_count`;
export const API_MASS_UPDATE_SESSION = `${BASE_API_URL}/session/mass_update`;
export const API_ADD_INQUIRY_DES = `${BASE_API_URL}/session/add_inquiry_des`;
export const API_GET_ALL_TAGS = `${BASE_API_URL}/session/getAllTags`;
export const API_GET_ALL_HITS = `${BASE_API_URL}/session/getAllHits`;
export const API_SAVE_CLIENT_CAT = `${BASE_API_URL}/saveClientCat`;

export const API_GET_CHATTER = `${BASE_API_URL}/chatter`;
export const API_GET_CHATTERS = `${BASE_API_URL}/chatters`;
export const API_GET_CHATTERS_COUNT = `${BASE_API_URL}/chatter_count`;
export const API_GET_REGION_COUNT = `${BASE_API_URL}/getAllRegions`;

export const API_SAVE_NOTE = `${BASE_API_URL}/note`;
export const API_GET_NOTE = `${BASE_API_URL}/note/get`;
export const API_DELETE_NOTE = `${BASE_API_URL}/note/destroy`;

export const API_GET_TAG_LIST = `${BASE_API_URL}/tag/list`;
export const API_DELETE_CLIENT_TAG = `${BASE_API_URL}/tag_delete`;
export const API_SAVE_TAG_CLIENT_SESSION = `${BASE_API_URL}/tag`;
export const API_GET_TAG_CLIENT_SESSION = `${BASE_API_URL}/tag/clientlist`;
export const API_ADD_TAG = `${BASE_API_URL}/tag/add`;
export const API_DELETE_TAG = `${BASE_API_URL}/tag`;

export const API_GET_FEEDBACK_DATA = `${BASE_API_URL}/feedback_report`;
export const API_CREATE_FEEDBACK_SESSION = `${BASE_API_URL}/feedback/session_detail`;
export const API_CREATE_FEEDBACK_CHATTER = `${BASE_API_URL}/feedback/chatter_detail`;

export const API_SAVE_REPORT_CONFIG = `${BASE_API_URL}/configReport`;
export const API_GET_REPORT_CONFIG = `${BASE_API_URL}/getConfigReport`;
export const API_EDIT_TAG = `${BASE_API_URL}/editTag`;
export const API_GET_EDIT_TAG = `${BASE_API_URL}/getEditTag`;
export const API_GET_CLIENT_CONF = `${BASE_API_URL}/getConfigClient`;
export const API_SAVE_CLIENT_CONF = `${BASE_API_URL}/configClient`;
export const API_SAVE_CAT_CONFIG = `${BASE_API_URL}/saveCatConfig`;
export const API_GET_CAT_CONFIG = `${BASE_API_URL}/getCategoryConfig`;