import React, { Component } from 'react';
import {
	VictoryChart,
	VictoryBar,
	VictoryAxis,
	VictoryVoronoiContainer
} from 'victory';

class BarIndividual extends Component {

	render = () => {
		const dateRange = this.props.dateRange ? this.props.dateRange : '';
		return (
			<VictoryChart
				height={200}
				containerComponent={
					<VictoryVoronoiContainer
						mouseFollowTooltips
						voronoiDimension="x"
						labels={({ datum }) => `${dateRange}\ncount: ${datum.y}`}
					/>
				}
			>
				<VictoryBar
					style={{ data: { fill: this.props.blue, fillOpacity: 0.3 } }}
					data={this.props.data}
				/>
				<VictoryAxis dependentAxis />
				{
					this.props.category.map((d, i) => {
						return (
							<VictoryAxis dependentAxis
								orientation="right"
								key={i}
								label={d}
								style={{
									axisLabel: { fontSize: 15, padding: 5 },
									tickLabels: { fill: "none" }
								}}
								axisValue={d}
							/>
						);
					})
				}
			</VictoryChart>
		);
	}

}

export default BarIndividual