import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../actionCreators'
import { Input, Row, Col, Button } from 'antd';
import 'antd/dist/antd.css';

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      password: ''
    }
  }

  handleFieldChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
    // console.log(event.target.name)
    // console.log(event.target.value)
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const item = {
      email: this.state.email,
      password: this.state.password,
    }
    //console.log(item)
    this.props.login(item)
  }

  componentDidMount() {
    if (this.props.auth.token != null) {
      this.props.history.push('/sessions/list');
    }
  }

  componentDidUpdate() {
    if (this.props.auth.token != null) {
      this.props.history.push('/sessions/list');
    }
  }
  render() {
    const { auth } = this.props;
    //console.log(this.props)
    if (auth.token) {
      return <Redirect to={'/sessions/list'} />
      // return <Redirect to={from}/>
    }

    return (
      <Row gutter={16}>
        <Col span={6}></Col>
        <Col span={12}>
          <div style={{ textAlign: 'center', }}>
            <img
              src={require('../../../assets/ideals_logo.jpg')}
              alt='Ideals Logo'
              width="40%"
              height="40%"
            />
            <h1>Queue and Reports Management System</h1>
          </div>
          <form onSubmit={this.handleSubmit}>
            <div>
              <label htmlFor='email'>Email</label>
              <Input
                id='email'
                name='email'
                value={this.state.email}
                onChange={this.handleFieldChange}
              />
            </div>
            <div>
              <label htmlFor='email'>Password</label>
              <Input
                type='password'
                id='password'
                name='password'
                value={this.state.password}
                onChange={this.handleFieldChange}
              />
            </div>
            <br />
            <Button type="primary" onClick={this.handleSubmit}>Login</Button>
          </form>
        </Col>
        <Col span={6}></Col>
      </Row>

    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}
export default connect(mapStateToProps, { login })(Login);