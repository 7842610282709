/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Drawer, Select, Button, Input } from 'antd';
import SummaryInformation from '../../../shared/components/SummaryInformation';
import { sourceFeedback } from '../data'
import TextArea from 'antd/lib/input/TextArea';
import axios from '../../../shared/utils/axios';
import {
  API_CREATE_FEEDBACK_SESSION,
  API_USER_LOG,
} from '../../../shared/config/constants'
import _ from 'lodash';
import { isThisSecond } from 'date-fns';

const { Option } = Select;
class DrawerFeedback extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      feedbackList: '',
      why: '',
      rating: '',
      showFeedbackInput: false
    }
    this.handleCreateNewItem = this.handleCreateNewItem.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this)
  }
  componentDidMount() {
    this.setState({
      feedbackList: this.props.feedback
    })
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.feedback !== this.props.feedback) {
      this.setState({
        feedbackList: this.props.feedback
      })
    }
  }
  show = () => {
    this.setState({ visible: true });
  }

  hide = () => {
    this.setState({ visible: false });
  }

  handleCreateNewItem(event) {
    event.preventDefault();
    const item = {
      'session_id': this.props.session.id,
      'chatter_id': this.props.session.chatter_id,
      'data': [
        {
          feedback_item: 'Paano niyo nalaman ang tungkol sa serbisyong ito?',
          feedback_value: this.state.feedback
        },
        {
          feedback_item: 'Gaano kayo kasatisfied sa aming serbisyo?',
          feedback_value: this.state.rating
        },
        {
          feedback_item: 'Bakit po ganun ang inyong opinyon sa aming serbisyo?',
          feedback_value: this.state.why
        }
      ]
    }
    axios.put(API_CREATE_FEEDBACK_SESSION, item, {
      headers: {
        'Authorization': `Bearer ${this.props.token}`
      }
    }).then((response) => {
      this.setState({
        feedbackList: response.data.feedback
      })
      axios.put(API_USER_LOG, {
        'user_id': this.props.userId,
        'activity': 'Added Feedback',
        'condition': {
          'session_id': this.props.session.id,
          'chatter_id': this.props.session.chatter_id,
          'chatter_name': this.props.chatter_name
        }
      }, {
        headers: {
          'Authorization': `Bearer ${this.props.token}`
        }
      });
    })
  }
  handleFieldChange(event) {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  setFeedback = (value) => {
    this.setState({
      feedback: value
    })
  }
  setRating = (value) => {
    this.setState({
      rating: value
    })
  }
  showFeedbackInput = () => {
    this.setState({
      showFeedbackInput: !this.state.showFeedbackInput
    })
  }
  render = () => {
    if (!this.props.feedback) {
      return <div></div>
    }
    return <Drawer
      title={<div>
        Client Feedback
        <SummaryInformation style={{ fontSize: '.8em' }} data={[
          { key: 'Session ID', value: this.props.session.id }
        ]} />
      </div>}
      placement="right"
      closable="true"
      onClose={this.hide}
      visible={this.state.visible}
      width={500}>

      {_.get(this, 'state.feedbackList.length', 0) === 0 && <form onSubmit={this.handleCreateNewItem}>
        <Button
          style={{ color: '#1890ff' }}
          type="text"
          onClick={this.showFeedbackInput}
        >
          add feedback
        </Button>
        {this.state.showFeedbackInput ? <div>
          <div>Paano niyo ng tungkol sa serbisyong ito?</div>
          <Select
            onChange={this.setFeedback}
            value={this.state.feedback}
            placeholder="Please select source"
          >
            {sourceFeedback.map((item) => {
              return <Option value={item}>{item}</Option>
            })}
          </Select>
          <div>Gaano kayo ka satisfied sa aming serbisyo</div>
          <Button onClick={() => this.setRating('⭐')}>⭐</Button>
          <Button onClick={() => this.setRating('⭐⭐')}>⭐⭐</Button>
          <Button onClick={() => this.setRating('⭐⭐⭐')}>⭐⭐⭐</Button>
          <Button onClick={() => this.setRating('⭐⭐⭐⭐')}>⭐⭐⭐⭐</Button>
          <Button onClick={() => this.setRating('⭐⭐⭐⭐⭐')}>⭐⭐⭐⭐⭐</Button>
          <div>bakit po ganun ang opnion niyo sa sa aming serbisyo</div>
          <TextArea
            id='why'
            name='why'
            value={this.state.why}
            onChange={this.handleFieldChange}
            rows={4}
          />
          <br />
          <Button
            style={{ color: '#1890ff' }}
            type="text"
            onClick={this.handleCreateNewItem}
          >
            save feedback
          </Button></div> : <div>&nbsp;&nbsp;&nbsp;&nbsp;no feedback data saved</div>}
      </form>}
      <div>
        {
          this.state.feedbackList && this.state.feedbackList.map((item) => {
            return <div key={item.id}>
              <div style={{ fontSize: '0.8em', color: '#888', height: '1.7em' }}>{item.created_at}<br /></div>
              <div style={{ fontSize: '0.8em', color: '#888', lineHeight: '1em' }}>{item.feedback_item}</div>
              <div style={{ fontSize: '1.2em', lineHeight: '1.3em', paddingTop: '12px' }}>{item.feedback_value}</div>
              <br />
            </div>
          })
        }
      </div>

    </Drawer>
  }
}

export default DrawerFeedback;

