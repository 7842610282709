export const getError = (error) => {
    let message, errorCode;
    if (error.response) {
        const response = error.response;
        const data = response.data;
        errorCode = data.error ? data.error : 'no_code';
        const messages = data.messages;
        message = (messages && messages.length > 0) ?
            messages[0] : 'Something went wrong. Try Again!';
    } else if (typeof error === 'string') {
        errorCode = 'no_code';
        message = error;
    } else {
        message = 'Something went wrong. Try Again!';
    }
    return {
        code: errorCode,
        message
    };
}

export const logout = (err, dispatch) => {
    if (err
        && err.response
        && err.response.data
        && err.response.data.message) {
        const msg = err.response.data.message
        if (msg && msg === 'Token has expired') {
            dispatch({ type: 'LOGOUT' })
        }
    }

}