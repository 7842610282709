import { TYPES } from './actionCreators';

export default (state = {}, action) => {
	switch (action.type) {
		case TYPES.GET_AVAILABLE_TAGS:
			return { ...state, tags: action.payload }
		case TYPES.GET_REPORT_CONFIG:
			return { ...state, reportConfig: action.payload }
		case TYPES.SAVE_REPORT_CONFIG:
			return { ...state, saveConfig: action.payload }
		case TYPES.GET_CLIENT_CONF:
			return { ...state, ...action.payload }
		case TYPES.GET_CAT_CONFIG:
			return { ...state, ...action.payload }
		default:
			return state;
	}
}