import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import {
	getTags,
	deleteTag,
	editTag,
	getEditTag,
	getCatConfig,
} from '../actionCreators';
import { addTag } from '../../sessions/actionCreators'
import { userLog } from '../../users/actionCreators'
import {
	Table, Button, AutoComplete,
	Popconfirm, Input, Select,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import TextArea from 'antd/lib/input/TextArea';
import {
	DeleteOutlined,
	EditOutlined,
} from '@ant-design/icons';

const { Option } = Select
class TagsConf extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addTagModalVis: false,
			editTagModalVis: false,
			textAreaTag: '',
			category: '',
			oldTag: '',
			tagArr: [],
			updateTag: true,
			columns: [
				{
					title: 'Tag',
					dataIndex: 'tag',
					key: 'tag',
					sorter: (a, b) => a.tag.localeCompare(b.tag),
					filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
						<div style={{ padding: '10px', background: '#F0F0F0' }} className="custom-filter-dropdown">
							<Button type="primary" onClick={() => confirm()}>Search</Button>
							<Button onClick={() => this.resetTagSelect(clearFilters)}>Reset</Button>
							<div style={{ margin: '10px 0px 0px 0px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<Select
									style={{ width: '300px' }}
									mode='multiple'
									defaultOpen={true}
									placeholder={'Tags to Filter'}
									onChange={e => {
										setSelectedKeys(e)
										this.setState({
											selectTagFilterVals: e
										})
									}}
									value={this.state.selectTagFilterVals}
									showSearch
								>
									{this.props?.tags?.map(value => {
										return <Option value={value.tag}>{value.tag}</Option>
									})}
								</Select>
							</div>
						</div>
					),
					onFilter: (value, record) => record.tag.toLowerCase().includes(value.toLowerCase()),
				}, {
					title: 'Category',
					dataIndex: 'category',
					key: 'category'
				},
				{
					title: 'Action',
					key: 'action',
					render: (text, record) => (
						<span>
							<Popconfirm
								title="Are you sure you want to delete this user?"
								onConfirm={() => {
									this.props.deleteTag(record.tag, this.props.auth.token)
									this.props.userLog({
										'user_id': this.props.auth.user.id,
										'activity': `Deleted Tag`,
										'condition': {
											'newTag': record.tag
										}
									}, this.props.auth.token);
								}}
								okText="Yes"
								cancelText="No"
							>
								<Button
									size="small"
									icon={<DeleteOutlined />}
									danger
								/>
							</Popconfirm>
							&nbsp;
							<Button size="small" icon={<EditOutlined />}
								disabled={!this.props.auth.user.permissions.includes('edit_tag')}
								onClick={() => this.editTagModal(record.tag)}
							/>
						</span>
					),
				}
			]
		}
	}
	componentDidMount() {
		this.props.getTags(this.props.auth.token)
		this.props.getCatConfig(this.props.auth.token)
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevState.updateTag !== this.state.updateTag) {
			this.props.getTags(this.props.auth.token)
		}
	}
	resetTagSelect = (clearFilters) => {
		clearFilters()
		this.setState({
			selectTagFilterVals: []
		})
	}
	editTagModal = (tag) => {
		this.setState({
			textAreaTag: tag,
			oldTag: tag,
			addTagModalVis: false,
			editTagModalVis: true
		})
	}
	okEditTagModel = () => {
		const item = {
			oldTag: this.state.oldTag,
			newTag: this.state.textAreaTag.replace(/[^a-zA-Zñ ]/gi, ''),
			category: this.state.category
		}
		this.props.editTag(item, this.props.auth.token)
		this.props.userLog({
			'user_id': this.props.auth.user.id,
			'activity': `Edited Tag`,
			'condition': {
				'oldTag': this.state.oldTag,
				'newTag': this.state.textAreaTag.replace(/[^a-zA-Zñ ]/gi, '')
			}
		}, this.props.auth.token)
		this.setState({
			editTagModalVis: false,
			textAreaTag: '',
			oldTag: '',
			category: '',
			updateTag: !this.state.updateTag //for re rendering of the table
		})
	}
	toggleModal = () => {
		this.setState({
			addTagModalVis: true,
			editTagModalVis: false
		})
	}
	cancelModal = () => {
		this.setState({
			addTagModalVis: false,
			editTagModalVis: false,
			textAreaTag: ''
		})
	}
	okModal = () => {
		this.props.addTag({
			tag: this.state.textAreaTag.replace(/[^a-zA-Zñ ]/gi, ''),
			category: this.state.category
		}, this.props.auth.token)
		this.props.userLog({
			'user_id': this.props.auth.user.id,
			'activity': `Created Tag`,
			'condition': {
				'newTag': this.state.textAreaTag.replace(/[^a-zA-Zñ ]/gi, '')
			}
		}, this.props.auth.token)
		this.setState({
			addTagModalVis: false,
			textAreaTag: '',
			category: '',
			updateTag: !this.state.updateTag //for re rendering of the table
		})
	}
	onSearch = (searchText) => {
		const availalbleTags = this.props?.tags?.filter(n => n.tag.toLowerCase().includes(searchText.toLowerCase())) ?? []
		let optionsArr = []
		_.each(availalbleTags, (item) => {
			optionsArr.push({
				value: item.tag
			})
		})
		this.setState({
			tagArr: optionsArr
		})
	}
	textAreaTagChange = (event) => {
		this.setState({
			textAreaTag: event.target.value.substring(0, 35)
		})
	}
	onSelectChange = (value) => {
		this.setState({
			category: value
		})
	}
	exportEditTag = () => {
		this.props.getEditTag(this.props.auth.token)
	}
	render() {
		if (!this.props.auth.token) {
			return <Redirect to={'/login'} />
		}
		return (
			<div>
				<Button
					type='primary'
					onClick={this.toggleModal}
				>
					+Add New Tag
				</Button>
				<Button
					style={{ float: 'right' }}
					onClick={this.exportEditTag}
				>
					Export Tag History
				</Button>
				<Modal
					title='Add Tag'
					visible={this.state.addTagModalVis}
					onCancel={this.cancelModal}
					onOk={this.okModal}
				>
					<div style={{ color: '#0eeb7c', fontWeight: 'bold', fontSize: 20 }}>
						Please make sure that this tag does not exist yet before clicking OK
					</div>
					<AutoComplete
						options={this.state.tagArr}
						onSearch={this.onSearch}
						value={this.state.textAreaTag}
					>
						<TextArea
							id='confTextArea'
							name='confTextArea'
							placeholder='Add Tag'
							style={{ height: 32, width: 300 }}
							onChange={this.textAreaTagChange}
							value={this.state.textAreaTag}
						/>
					</AutoComplete>
					<div>Select Category</div>
					<Select
						placeholder='Select Category'
						style={{ width: 300 }}
						onChange={this.onSelectChange}
						value={this.state.category}
					>
						{
							this.props.catConfig?.map(item => {
								return <Option value={item.category}>{item.category}</Option>
							})
						}
					</Select>
				</Modal>
				<Modal
					title='Edit Tag'
					visible={this.state.editTagModalVis}
					onCancel={this.cancelModal}
					onOk={this.okEditTagModel}
				>
					Tag to change : <span style={{ color: '#0eeb7c', fontWeight: 'bold', fontSize: 20 }}>
						{this.state.oldTag}
					</span>
					<Input
						placeholder='New Tag'
						onChange={this.textAreaTagChange}
						value={this.state.textAreaTag}
					/>
					<div>Select Category</div>
					<Select
						placeholder='Select Category'
						style={{ width: '100%' }}
						onChange={this.onSelectChange}
						value={this.state.category}
					>
						{
							this.props.catConfig?.map(item => {
								return <Option value={item.category}>{item.category}</Option>
							})
						}
					</Select>
				</Modal>
				<Table
					columns={this.state.columns}
					dataSource={this.props.tags}
				/>
			</div >
		)
	}
}

const mapStateToProps = (state) => {
	return {
		tags: state.configuration.tags,
		catConfig: state.configuration.CatConfig,
		auth: state.auth,
	}
}
export default connect(mapStateToProps, {
	getTags,
	addTag,
	deleteTag,
	editTag,
	getEditTag,
	userLog,
	getCatConfig
})(TagsConf)

