import axios from '../../shared/utils/axios';
import _, { add } from 'lodash'
import Moment from 'moment'
import { logout } from '../../shared/utils/api_utils'
import {
	API_GET_SESSIONS,
	API_GET_CHATTERS,
	API_GET_SESSIONS_COUNT,
	API_GET_CHATTERS_COUNT,
	API_GET_FEEDBACK_DATA,
	API_GET_ALL_TAGS,
	API_GET_ALL_HITS,
	API_GET_REGION_COUNT
} from '../../shared/config/constants';

export const TYPES = {
	GET_SESSIONS_LIST_FOR_REPORTS: 'GET_SESSIONS_LIST_FOR_REPORTS',
	GET_CHATTERS_LIST_FOR_REPORTS: 'GET_CHATTERS_LIST_FOR_REPORTS',
	GET_SESSIONS_COUNT: 'GET_SESSIONS_COUNT',
	GET_CHATTERS_COUNT: 'GET_CHATTERS_COUNT',
	GET_FEEDBACK_DATA: 'GET_FEEDBACK_DATA',
	LEGAL_LOADING: 'LEGAL_LOADING',
	SESSION_LOADING: 'SESSION_LOADING',
	CHATTER_LOADING: 'CHATTER_LOADING'
}

export const getSessions = (params, token) => async dispatch => {
	try {

		const response = await axios.get(API_GET_SESSIONS,
			{
				params: params,
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
		dispatch({ type: TYPES.GET_SESSIONS_LIST_FOR_REPORTS, payload: response.data })
	}
	catch (err) {
		logout(err, dispatch)
	}
}

export const getSessionsCount = (params, token) => async dispatch => {
	try {
		const response = await axios.get(API_GET_SESSIONS_COUNT,
			{
				params: params,
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
		dispatch({ type: TYPES.GET_SESSIONS_COUNT, payload: response.data })
	}
	catch (err) {
		logout(err, dispatch)
	}
}

export const getChatters = (params, token) => async dispatch => {
	try {

		const response = await axios.get(API_GET_CHATTERS,
			{
				params: params,
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
		dispatch({ type: TYPES.GET_CHATTERS_LIST_FOR_REPORTS, payload: response.data })
	}
	catch (err) {
		logout(err, dispatch)
	}
}

export const getChattersCount = (params, token) => async dispatch => {
	try {
		const response = await axios.get(API_GET_CHATTERS_COUNT,
			{
				params: params,
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
		dispatch({ type: TYPES.GET_CHATTERS_COUNT, payload: response.data })
	}
	catch (err) {
		logout(err, dispatch)
	}
}

export const getFeedbackData = (params, token) => async dispatch => {
	try {
		const response = await axios.get(API_GET_FEEDBACK_DATA,
			{
				params: params,
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
		dispatch({ type: TYPES.GET_FEEDBACK_DATA, payload: response.data })
	}
	catch (err) {
		logout(err, dispatch)
	}
}

const cleanInquiry = (inquiryArr) => {
	let inquiry = ''
	if (!Array.isArray(inquiryArr)) {
		return inquiryArr.replace(/[^a-zA-Z0-9ñ ]/gi, ' ')
	}
	inquiryArr.forEach((item) => {
		const inquiryItem = item ? item.replace(/[^a-zA-Z0-9ñ ]/gi, ' ') : ''
		inquiry = inquiry + inquiryItem;
	})
	return inquiry
}

const isJson = (str) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

const cleanSeshData = (seshData) => {
	if (isJson(seshData) && seshData !== '[]') {
		return JSON.parse(seshData)['Para Kanino']
	}
	return ''
}

const convertToCSV = (objArray) => {
	var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
	var str = '';

	for (var i = 0; i < array.length; i++) {
		var line = '';
		for (var index in array[i]) {
			if (line != '') line += ','

			line += array[i][index];
		}

		str += line + '\r\n';
	}

	return str;
}

export const exportCSVFile = (headers, items, fileTitle) => {
	if (headers) {
		items.unshift(headers);
	}

	// Convert Object to JSON
	const jsonObject = JSON.stringify(items);

	const csv = convertToCSV(jsonObject);

	const exportedFilenmae = fileTitle + '.csv' || 'export.csv';
	const blob = new Blob(["\ufeff", csv]);
	// var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
	if (navigator.msSaveBlob) { // IE 10+
		navigator.msSaveBlob(blob, exportedFilenmae);
	} else {
		const link = document.createElement("a");
		if (link.download !== undefined) { // feature detection
			// Browsers that support HTML5 download attribute
			const url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", exportedFilenmae);
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
}

const getFormattedDate = (date) => {
	const year = date.getFullYear()
	const month = date.getMonth() + 1;// getMonth starts from 0 so plus 1 is needed
	const day = date.getDate();
	const formattedDate = Moment(`${year}-${month}-${day}`).format('YYYY-MM-DD')
	return formattedDate;
}

export const getSessionsData = (configArr, dateRange, startDte, endDte, token, _this) => async dispatch => {
	const seshID = configArr.includes('SessionID');
	const clientName = configArr.includes('ClientName');
	const dateTime = configArr.includes('DateTime');
	const assignTo = configArr.includes('AssignTo');
	const status = configArr.includes('Status');
	const FRT = configArr.includes('FRT');
	const time_queued = configArr.includes('time_queued');
	const time_ongoing = configArr.includes('time_ongoing');
	const CRT = configArr.includes('CRT');
	const time_completed = configArr.includes('time_completed');
	const inquiry = configArr.includes('Inquiry');
	const category = configArr.includes('category');
	const askingFor = configArr.includes('AskingFor');
	const tags = configArr.includes('Tags');
	const notes = configArr.includes('Notes');
	const rating = configArr.includes('RatingFeedback');
	const why = configArr.includes('WhyFeedback');
	const headers = {// undefinded is ignoreed for configuration
		SessionID: (seshID ? 'SessionID' : undefined),
		ClientName: (clientName ? 'ClientName' : undefined),
		DateTime: (dateTime ? 'DateTime' : undefined),
		AssignTo: (assignTo ? 'AssignTo' : undefined),
		Status: (status ? 'Status' : undefined),
		FRT: (FRT ? 'FRT' : undefined),
		time_queued: (time_queued ? 'TimeQueued' : undefined),
		time_ongoing: (time_ongoing ? 'TimeOngoing' : undefined),
		CRT: (CRT ? 'CRT' : undefined),
		time_completed: (time_completed ? 'TimeComplete' : undefined),
		Inquiry: (inquiry ? 'Inquiry' : undefined),
		category: (category ? 'Category' : undefined),
		AskingFor: (askingFor ? 'AskingFor' : undefined),
		Tags: (tags ? 'Tags' : undefined),
		Notes: (notes ? 'Notes' : undefined),
		RatingFeedback: (rating ? 'RatingFeedback' : undefined),
		WhyFeedback: (why ? 'WhyFeedback' : undefined)
	}
	let sessionListData = [];
	const startDate = getFormattedDate(startDte) + ' 00:00:00'
	const endDate = getFormattedDate(endDte) + ' 23:59:59'

	axios.get(API_GET_SESSIONS, {
		params: {
			getFeedback: true,
			startDate: startDate,
			endDate: endDate,
		},
		headers: {
			'Authorization': `Bearer ${token}`
		}
	}, { timeout: 600000 }).then((response) => {
		response.data.data.forEach((value) => {
			sessionListData.push({
				SessionID: (seshID ? value.id?.replace(/,/gi, ';') ?? '' : undefined),
				ClientName: (clientName ? value.full_name?.replace(/,/gi, ';') ?? '' : undefined),
				DateTime: (dateTime ? value.created_at?.replace(/,/gi, ';') ?? '' : undefined),
				AssignTo: (assignTo ? _.get(_this, `props.user[${value.assigned_to}].name`, 0) !== 0 ? _this.props.user[value.assigned_to].name : '' : undefined),
				Status: (status ? value.status?.replace(/,/gi, ';') ?? '' : undefined),
				FRT: (FRT ? value.firstResponseTime ?? '' : undefined),
				time_queued: (time_queued ? value.time_queued ?? '' : undefined),
				time_ongoing: (time_ongoing ? value.time_ongoing ?? '' : undefined),
				CRT: (CRT ? value.completionTime ?? '' : undefined),
				time_completed: (time_completed ? value.time_completed ?? '' : undefined),
				Inquiry: (inquiry ? value.inquiry ? cleanInquiry(value.inquiry) : '' : undefined),
				category: (category ? value.category ?? '' : undefined),
				AskingFor: (askingFor ? cleanSeshData(value.seshData) : undefined),
				Tags: (tags ? value.tags?.replace(/,/gi, ';') ?? '' : undefined),
				Notes: (notes ? value.notes?.replace(/,/gi, ';') ?? '' : undefined),
				RatingFeedback: (rating ? value.rating?.replace(/,/gi, ';') ?? '' : undefined),
				WhyFeedback: (why ? value.why?.replace(/[^a-zA-Z0-9ñ ]/gi, ';') ?? '' : undefined)
			})
		})
		exportCSVFile(headers, sessionListData, `sessionData ${dateRange}`)
		dispatch({ type: TYPES.SESSION_LOADING, payload: Moment() })//so componentDidUpdate will update
	})
}

export const getLegalReport = (colArr, dateRange, startDte, endDte, token, _this) => async dispatch => {
	const seshID = colArr.includes('sessionID')
	const fb = colArr.includes('fb_name')
	const realName = colArr.includes('real_name')
	const ageBracket = colArr.includes('age_bracket')
	const gender = colArr.includes('gender')
	const civil = colArr.includes('civil_status')
	const employment = colArr.includes('employment_status')
	const sector = colArr.includes('sector')
	const address = colArr.includes('address')
	//const region = colArr.includes('region')
	//const city = colArr.includes('city')
	//const contact = colArr.includes('contact_number')
	const dateTime = colArr.includes('date_time')
	const status = colArr.includes('status')
	//const asking = colArr.includes('asking_for')
	const assingn = colArr.includes('assigned_to')
	const time_queued = colArr.includes('time_queued')
	const timeOngoing = colArr.includes('time_ongoing')
	//const FRT = colArr.includes('first_response')
	const inquiry = colArr.includes('inquiry')
	const inq_des = colArr.includes('inquiry_des')
	const category = colArr.includes('category')
	const tags = colArr.includes('tags')
	const timeComplete = colArr.includes('time_completed')
	const complete = colArr.includes('completed_date')
	const source = colArr.includes('source')
	//const rating = colArr.includes('rating')
	//const why = colArr.includes('why')
	const headers = {
		sessionID: (seshID ? 'Session ID' : undefined),
		fb_name: (fb ? 'FB Name' : undefined),
		real_name: (realName ? 'Real Name' : undefined),
		age_bracket: (ageBracket ? 'Age Bracket' : undefined),
		gender: (gender ? 'Gender' : undefined),
		civil_status: (civil ? 'Civil Status' : undefined),
		employment_status: (employment ? 'Employment Status' : undefined),
		sector: (sector ? 'Sector' : undefined),
		address: (address ? 'Address' : undefined),
		//region: (region ? 'Region' : undefined),
		//city: (city ? 'City' : undefined),
		//contact_number: (contact ? 'Contact Number' : undefined),
		date_time: (dateTime ? 'Created At' : undefined),
		status: (status ? 'Status' : undefined),
		//asking_for: (asking ? 'Asking For' : undefined),
		assignTo: (assingn ? 'Assigne to' : undefined),
		time_queued: (time_queued ? 'Time Queued' : undefined),
		time_ongoing: (timeOngoing ? 'FRT Date' : undefined),
		//first_response: (FRT ? 'First' : undefined),
		inquiry: (inquiry ? 'Inquiry' : undefined),
		inquiry_des: (inq_des ? 'inquiry description' : undefined),
		category: (category ? 'Category' : undefined),
		tags: (tags ? 'tags' : undefined),
		time_completed: (timeComplete ? 'Complete Time' : undefined),
		completed_date: (complete ? 'complete' : undefined),
		source: (source ? 'source' : undefined)
		//rating: (rating ? 'rating' : undefined)
		//why: (why ? 'why' : undefined)
	};
	let itemsFormatted = []
	const startDate = getFormattedDate(startDte) + ' 00:00:00';
	const endDate = getFormattedDate(endDte) + ' 23:59:59';
	axios.get(API_GET_SESSIONS, {
		params: {
			getLegalReport: true,
			getFeedback: true,
			startDate: startDate,
			endDate: endDate
		},
		headers: {
			'Authorization': `Bearer ${token}`
		}
	}


		, { timeout: 600000 }).then((response) => {
			response.data.data.forEach((item) => {
				//const obj = JSON.parse(item.data)
				//start rating and why const
				//const sessionResponse = axios.get(
				//`https://api-qms.tisyahustisya.org/api/session?id=` +
				//item.id + `&chatter_id=` + item.chatter_id,
				//{ headers: { 'Authorization': `Bearer ${token}` }, timeout: 600000 }
				//);
				//const sessionData = sessionResponse.data.feedback;
				//const sessionRating = sessionResponse.data || [];
				//end const
				itemsFormatted.push({
					sessionID: (seshID ? item.id ? item.id : '' : undefined),
					fb_name: (fb ? item.full_name ? item.full_name.replace(/,/gi, ';') : '' : undefined),
					real_name: (realName ? item.data?.['Real Name'] ? item.data['Real Name'].replace(/[^a-zA-Z0-9ñ ]/gi, ';') : '' : undefined),
					age_bracket: (ageBracket ? item.data?.['Age'] ? item.data['Age'].replace(/,/gi, ';') : '' : undefined),
					gender: (gender ? item.data?.['Gender'] ? item.data['Gender'].replace(/,/gi, ';') : '' : undefined),
					civil_status: (civil ? item.data?.['Civil Status'] ? item.data['Civil Status'].replace(/,/gi, ';') : '' : undefined),
					employment_status: (employment ? item.data?.['Employment Status'] ? item.data['Employment Status'].replace(/,/gi, ';') : '' : undefined),
					sector: (sector ? item.data?.['Sector'] ? item.data['Sector'].replace(/,/gi, ';') : '' : undefined),
					address: (address ? JSON.stringify(item.data?.['Address']) ? JSON.stringify(item.data['Address']).replace(/,/gi, ';') : '' : undefined),
					//region: (region ? item.region ? item.region.replace(/,/gi, ';') : '' : undefined),
					//city: (city ? obj?.['City'] ? obj['City'].replace(/,/gi, ';') : '' : undefined),
					//contact_number: (contact ? obj?.['Contact Number'] ? obj['Contact Number'].replace(/,/gi, ';') : '' : undefined),
					date_time: (dateTime ? item.created_at ? item.created_at.replace(/,/gi, ';') : '' : undefined),
					status: (status ? item.status ? item.status.replace(/,/gi, ';') : '' : undefined),
					//asking_for: (asking ? cleanSeshData(item.seshData) : undefined),
					assignTo: (assingn ? _.get(_this, `props.user[${item.assigned_to}].name`, 0) !== 0 ? _this.props.user[item.assigned_to].name : '' : undefined),
					time_queued: (time_queued ? item.time_queued ? item.time_queued : '' : undefined),
					//time_queued: (time_queued ? item.time_queued ?? '' : undefined),
					time_ongoing: (timeOngoing ? item.time_ongoing ? item.time_ongoing : '' : undefined),
					//first_response: (FRT ? item.firstResponseTime ? item.firstResponseTime : '' : undefined),
					inquiry: (inquiry ? item.inquiry ? cleanInquiry(item.inquiry) : '' : undefined),
					inquiry_des: (inq_des ? item.inquiry_des ? item.inquiry_des.replace(/[^a-zA-Z0-9ñ ]/gi, ';') : '' : undefined),
					//category: (category ? item.category ?? '' : undefined),
					category: (category ? item.category ? item.category : '' : undefined),
					tags: (tags ? item.tags ? item.tags.replace(/,/gi, ';') : '' : undefined),
					time_completed: (timeComplete ? item.time_completed ? item.time_completed : '' : undefined),
					completed_date: (complete ? item.completionTime ? item.completionTime : '' : undefined),
					source: (civil ? item.data?.['Source'] ? item.data['Source'].replace(/,/gi, ';') : '' : undefined)
					//rating: (rating ? item.rating ? item.rating.replace(/,/gi, ';') : '' : undefined),
					//why: (why ? item.why ? item.why.replace(/[^a-zA-Z0-9ñ ]/gi, ';') : '' : undefined)
				})
			})
			exportCSVFile(headers, itemsFormatted, `legalReport ${dateRange}`)
			dispatch({ type: TYPES.LEGAL_LOADING, payload: Moment() })
		})
}

export const getChatterData = (colArr, extraCols, dateRange, startDte, endDte, token, _this) => async dispatch => {
	const clientID = colArr.includes('ClientID')
	const clientName = colArr.includes('ClientName')
	const realName = colArr.includes('RealName')
	const firstSeen = colArr.includes('FirstSeen')
	const lastSeen = colArr.includes('LastSeen')
	const sessions = colArr.includes('Sessions')
	const inquired = colArr.includes('Inquired')
	const status = colArr.includes('Status')
	const source = colArr.includes('SourceFeedBack')
	const age = colArr.includes('Age')
	const gender = colArr.includes('Gender')
	const employment = colArr.includes('Employment')
	const civil = colArr.includes('CivilStatus')
	const sector = colArr.includes('Sector')
	const address = colArr.includes('Address')
	const region = colArr.includes('Region')
	const province = colArr.includes('Province')
	const city = colArr.includes('City')
	const contactNumber = colArr.includes('ContactNumber')
	// const extraData = ['test', 'Radio', 'CheckBox']
	let extraData = []
	_.each(extraCols, (item) => {
		if (colArr.includes(item)) {
			extraData.push(item)
		}
	})
	let headers = {
		ClientID: (clientID ? 'ClientID' : undefined),
		ClientName: (clientName ? 'Client Name' : undefined),
		RealName: (realName ? 'Real Name' : undefined),
		FirstSeen: (firstSeen ? 'First Seen' : undefined),
		LastSeen: (lastSeen ? 'Last Seen' : undefined),
		Sessions: (sessions ? 'Sessions' : undefined),
		Inquired: (inquired ? 'Inquired' : undefined),
		Status: (status ? 'Status' : undefined),
		SourceFeedBack: (source ? 'Source Feedback' : undefined),
		Age: (age ? 'Age' : undefined),
		Gender: (gender ? 'Gender' : undefined),
		Employment: (employment ? 'Employment' : undefined),
		CivilStatus: (civil ? 'Civil Status' : undefined),
		Sector: (sector ? 'Sector' : undefined),
		Address: (address ? 'Address' : undefined),
		Region: (region ? 'Region' : undefined),
		Province: (province ? 'Province' : undefined),
		City: (city ? 'City' : undefined),
		ContactNumber: (contactNumber ? 'Contact Number' : undefined)
	}
	extraData.map(item => {
		headers[item] = item
	})
	// headers['insurance'] = (true ? 'hello' : undefined);
	let chattersListData = [];
	const startDate = getFormattedDate(startDte) + ' 00:00:00'
	const endDate = getFormattedDate(endDte) + ' 23:59:59'
	axios.get(API_GET_CHATTERS, {
		params: {
			startDate: startDate,
			endDate: endDate,
		},
		headers: {
			'Authorization': `Bearer ${token}`
		}
	},
		{ timeout: 600000 }
	).then((response) => {
		response.data.data.forEach((value) => {
			let items = {
				ClientID: (clientID ? value.id?.replace(/,/gi, ';') ?? '' : undefined),
				ClientName: (clientName ? value.full_name?.replace(/,/gi, ';') ?? '' : undefined),
				RealName: (realName ? value.data?.['Real Name']?.replace(/,/gi, ';') ?? '' : undefined),
				FirstSeen: (firstSeen ? value.created_at : undefined),
				LastSeen: (lastSeen ? value.updated_at : undefined),
				Sessions: (sessions ? value.session_count : undefined),
				Inquired: (inquired ? value.is_talked_to_lawyer === 0 ? 'no' : 'yes' : undefined),
				Status: (status ? value.status?.replace(/,/gi, ';') ?? '' : undefined),
				SourceFeedBack: (source ? value.feedback_value?.replace(/,/gi, ';') ?? '' : undefined),
				Age: (age ? value.age?.replace(/,/gi, ';') ?? '' : undefined),
				Gender: (gender ? value.gender?.replace(/,/gi, ';') ?? '' : undefined),
				Employment: (employment ? value.employment_status?.replace(/,/gi, ';') ?? '' : undefined),
				CivilStatus: (civil ? value.civil_status?.replace(/,/gi, ';') ?? '' : undefined),
				Sector: (sector ? value.sector?.replace(/,/gi, ';') ?? '' : undefined),
				Address: (address ? value.data?.['Address']?.replace(/[^a-zA-Z0-9ñ ]/gi, ';') ?? '' : undefined),
				Region: (region ? value.region?.replace(/,/gi, ';') ?? '' : undefined),
				Province: (province ? value.data?.['Province']?.replace(/,/gi, ';') ?? '' : undefined),
				City: (city ? value.data?.['City']?.replace(/,/gi, ';') ?? '' : undefined),
				ContactNumber: (contactNumber ? value.data?.['Contact Number']?.replace(/,/gi, ';') ?? '' : undefined)
			}
			extraData.map(entry => {
				items[entry] = value.data?.[entry]?.toString()?.replace(/[^a-zA-Z0-9ñ ]/gi, ';') ?? ''
			})
			// items['insurance'] = (true ? 'sun life' : undefined)
			chattersListData.push(items)
		})
		exportCSVFile(headers, chattersListData, `clientData ${dateRange}`)
		dispatch({ type: TYPES.CHATTER_LOADING, payload: Moment() })
	})
}

export const getAllHitData = (dateRange, startDte, endDte, token, _this, warning) => async dispatch => {
	if (!_this.props.auth.user.permissions.includes('download_reports')) {
		warning({
			title: 'Unauthorized',
			content: 'Your account does not have permission to export.',
		});
		return;
	}
	const headers = {
		node: 'Hit', // remove commas to avoid errors
		count: 'Count'
	};
	let itemsFormatted = []
	const startDate = getFormattedDate(startDte) + ' 00:00:00'
	const endDate = getFormattedDate(endDte) + ' 23:59:59'
	axios.get(API_GET_ALL_HITS, {
		params: {
			getAllHits: true,
			startDate: startDate,
			endDate: endDate
		},
		headers: {
			'Authorization': `Bearer ${token}`
		}
	}).then((response) => {
		response.data.hitCount.forEach((item) => {
			itemsFormatted.push({
				node: item.node,
				count: item.count
			})
		})
		exportCSVFile(headers, itemsFormatted, `Hits ${dateRange}`)
	})
}

export const getAllTagData = (dateRange, startDte, endDte, token, _this, warning) => async dispatch => {
	if (!_this.props.auth.user.permissions.includes('download_reports')) {
		warning({
			title: 'Unauthorized',
			content: 'Your account does not have permission to export.',
		});
		return;
	}
	const headers = {
		tag: 'Node', // remove commas to avoid errors
		count: 'Count'
	};
	let itemsFormatted = []
	const startDate = getFormattedDate(startDte) + ' 00:00:00'
	const endDate = getFormattedDate(endDte) + ' 23:59:59'
	axios.get(API_GET_ALL_TAGS, {
		params: {
			getAllTags: true,
			startDate: startDate,
			endDate: endDate
		},
		headers: {
			'Authorization': `Bearer ${token}`
		}
	}).then((response) => {
		response.data.tagCount.forEach((item) => {
			itemsFormatted.push({
				tag: item.tag,
				count: item.count
			})
		})
		exportCSVFile(headers, itemsFormatted, `Issues ${dateRange}`)
	})
}

export const getAllRegionData = (dateRange, startDte, endDte, token, _this, warning) => async dispatch => {
	if (!_this.props.auth.user.permissions.includes('download_reports')) {
		warning({
			title: 'Unauthorized',
			content: 'Your account does not have permission to export.',
		});
		return;
	}
	const headers = {
		region: 'Region', // remove commas to avoid errors
		count: 'Count'
	};
	let itemsFormatted = []
	const startDate = getFormattedDate(startDte) + ' 00:00:00'
	const endDate = getFormattedDate(endDte) + ' 23:59:59'
	axios.get(API_GET_REGION_COUNT, {
		params: {
			getAllRegions: true,
			startDate: startDate,
			endDate: endDate
		},
		headers: {
			'Authorization': `Bearer ${token}`
		}
	}).then((response) => {
		response.data.regionCount.forEach((item) => {
			itemsFormatted.push({
				region: item.region,
				count: item.count
			})
		})
		exportCSVFile(headers, itemsFormatted, `Region ${dateRange}`)
	})
}