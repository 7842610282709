import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	SessionColumns,
	legalColumns,
} from '../data'
import { Checkbox, Button, notification, Collapse } from 'antd';
import {
	saveReportConfig,
	getReportConfig,
	getClientConf,
} from '../actionCreators'
import {
	userLog
} from '../../users/actionCreators'
import _ from 'lodash';
const { Panel } = Collapse;
class ReportConf extends Component {
	constructor(props) {
		super(props);
		this.state = {
			columns: '',
			sessionChecked: [],
			legalChecked: [],
			clientChecked: [],
			clientsColumns: [],
			saved: false,
		}
	}
	componentDidMount() {
		this.props.getReportConfig(this.props.auth.token)
		this.props.getClientConf(this.props.auth.token)
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.reportConfig !== this.props.reportConfig) {
			const seshArr = this.props.reportConfig.reportConfig.sessionsDetails?.columns?.split(',') ?? []
			const legalArr = this.props.reportConfig.reportConfig.legalDetails?.columns?.split(',') ?? []
			const clientArr = this.props.reportConfig.reportConfig.clientDetails?.columns?.split(',') ?? [];
			this.setState({
				sessionChecked: seshArr,
				legalChecked: legalArr,
				clientChecked: clientArr,
			})
		}
		if (prevProps.saveConfig !== this.props.saveConfig && this.props.saveConfig.created === true) {
			console.log('update')
			notification.success({
				message: `successfully saved`,
				duration: 0.5
			})
		}
		if (prevProps.clientConf !== this.props.clientConf) {
			const clientsColumns = [
				'ClientID',
				'ClientName',
				'RealName',
				'FirstSeen',
				'LastSeen',
				'Sessions',
				'Inquired',
				'Status',
				'SourceFeedBack',
				'Age',
				'Gender',
				'Employment',
				'CivilStatus',
				'Sector',
				'Address',
				'Region',
				'Province',
				'City',
				'ContactNumber'
			]
			let dataArr = clientsColumns
			_.each(this.props.clientConf, (item) => {
				dataArr.push(item.label)
			})
			this.setState({
				clientsColumns: dataArr
			})
		}
	}
	onSessionChange = (e) => {
		this.setState({
			sessionChecked: e
		})
	}
	onLegalChange = (e) => {
		this.setState({
			legalChecked: e
		})
	}
	onClientChange = (e) => {
		this.setState({
			clientChecked: e
		})
	}
	onClick = () => {
		const sessionVals = {
			"csvDoc": "sessionsDetails",
			"columns": this.state.sessionChecked
		}
		const legalVals = {
			"csvDoc": "legalDetails",
			"columns": this.state.legalChecked
		}
		const clientVals = {
			"csvDoc": "clientDetails",
			"columns": this.state.clientChecked
		}
		this.props.saveReportConfig(sessionVals, this.props.auth.token)
		this.props.saveReportConfig(legalVals, this.props.auth.token)
		this.props.saveReportConfig(clientVals, this.props.auth.token)
		this.props.userLog({
			'user_id': this.props.auth.user.id,
			'activity': `Configured Reports`,
		}, this.props.auth.token)
	}
	render() {
		if (!this.props.auth.token) {
			return <Redirect to={'/login'} />
		}
		return (
			<div>
				<Button style={{ float: 'right' }}
					onClick={this.onClick}>Save CSV columns
					</Button><br /><br />
				<Collapse>
					<Panel header='Session Details' key='1' >
						<Checkbox.Group
							onChange={this.onSessionChange}
							options={SessionColumns}
							value={this.state.sessionChecked}
						/>
					</Panel>
					<Panel header='Legal Report' key='2'>
						<Checkbox.Group
							onChange={this.onLegalChange}
							options={legalColumns}
							value={this.state.legalChecked}
						/>
					</Panel>
					<Panel header='Client Report' key='3'>
						<Checkbox.Group
							onChange={this.onClientChange}
							options={this.state.clientsColumns}
							value={this.state.clientChecked}
						/>
					</Panel>
				</Collapse>
			</div>
		)
	}
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		reportConfig: state.configuration.reportConfig,
		saveConfig: state.configuration.saveConfig,
		clientConf: state.configuration.clientConf
	}
}
export default connect(mapStateToProps, {
	saveReportConfig,
	getReportConfig,
	userLog,
	getClientConf
})(ReportConf)