import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import ChatterList from './screens/ChatterList';
import ChatterDetail from './screens/ChatterDetail';

class Chatters extends Component {
  componentDidMount() {
    if (!this.props.auth.token) {
      this.props.history.push('/login');
      //return <Redirect to={'/login'} />
    }
  }

  render() {
    return (
      <Switch>
        <Route exact path="/chatters/list" component={ChatterList} />
        <Route exact path="/chatters/:id" component={ChatterDetail} />

      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, {})(Chatters)
