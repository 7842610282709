import React, { Component } from 'react';
import {
	VictoryChart,
	VictoryBar,
	VictoryVoronoiContainer,
	VictoryStack,
} from 'victory';

class StackBar extends Component {
	render = () => {
		return (
			<VictoryChart
				// tickLabelComponent={<VictoryLabel angle={45} />}
				height={200}
				containerComponent={
					<VictoryVoronoiContainer
						mouseFollowTooltips
						voronoiDimension="x"
						labels={({ datum }) => `count: ${datum.count}`}
					/>
				}
			>
				<VictoryStack
				// colorScale={[pink, blue]}
				// style={{ data: { fillOpacity: 0.3 } }}
				>
					<VictoryBar
						style={{
							data: { fill: this.props.pink, fillOpacity: 0.3 },
							labels: { fill: this.props.pink }
						}}
						data={this.props.data1}
						x={this.props.x}
						y={this.props.y}
					/>
					<VictoryBar
						style={{
							data: { fill: this.props.blue, fillOpacity: 0.3 },
							labels: { fill: this.props.blue }
						}}
						data={this.props.data2}
						x={this.props.x}
						y={this.props.y}
					/>

				</VictoryStack>
			</VictoryChart>
		);
	}
}

export default StackBar;