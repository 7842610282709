import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import SessionList from './screens/SessionList';
import SessionDetail from './screens/SessionDetail';

class Sessions extends Component {
  componentDidMount() {
    if (!this.props.auth.token) {
      this.props.history.push('/login');
      //return <Redirect to={'/login'} />
    }
  }
  render() {
    return (
      <Switch>
        <Route exact path="/sessions/list" component={SessionList} />
        <Route exact path="/sessions/:id/chatter/:chatter_id" component={SessionDetail} />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, {})(Sessions)
