import React, { Component } from 'react';
import {
	Table, Popconfirm, Button
} from 'antd';
import {
	DeleteOutlined,
	EditOutlined,
} from '@ant-design/icons';
import UserLog from './UserLog';

class UserTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			userId: '',
			visible: false
		}
	}
	viewUserLog = (id) => {
		this.setState({
			userId: id,
			visible: true
		})
	}
	onClose = () => {
		this.setState({
			visible: false
		})
	}
	render() {
		const columns = [{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Role',
			dataIndex: 'role',
			key: 'role',
			filters: [
				{
					text: 'Admin', value: 'Admin'
				},
				{
					text: 'Lawyer', value: 'Lawyer'
				},
				{
					text: 'Researcher', value: 'Researcher'
				}]
		},
		{
			title: 'Status',
			dataIndex: 'active',
			key: 'active',
			filters: [
				{
					text: 'active', value: 1
				},
				{
					text: 'disabled', value: 0
				}
			],
			render: (text, record) => (
				<div>{record.active ? 'active' : 'disabled'}</div>
			)
		},
		{
			title: 'Action',
			key: 'action',
			render: (text, record) => (
				<span>
					<Button
						onClick={() => this.viewUserLog(record.id)}
					>View User Log</Button>
					<Popconfirm
						title="Are you sure you want to delete this user?"
						onConfirm={() => this.props.onDelete(record.id, this.props.token, record.name, record.role)}
						okText="Yes"
						cancelText="No"
					>
						<Button
							size="small"
							icon={<DeleteOutlined />}
							danger
						/>
					</Popconfirm>

           &nbsp;
					<Button icon={<EditOutlined />} size="small" onClick={() => {
						this.props.onUpdate(record.id, record.name, record.email, record.role, record.active, record.permissions)
					}} />
				</span>
			)
		}
		];
		return (
			<div>
				<Table
					size="small"
					dataSource={this.props.data}
					columns={columns}
					rowKey='id'
					onChange={this.props.handleListChange}
					pagination={{
						total: this.props.user.total,
						current: this.props.user.page,
						pageSize: this.props.user.take
					}}
				/>
				<UserLog
					userId={this.state.userId}
					visible={this.state.visible}
					onClose={this.onClose}
				/>
			</div>
		)
	}
}

export default UserTable