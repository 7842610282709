import React, { Component } from 'react';
import {
  Drawer, Button, Input, Select,
  Radio, AutoComplete, Checkbox
} from 'antd';
import { API_CREATE_FEEDBACK_CHATTER } from '../../../shared/config/constants'
import axios from '../../../shared/utils/axios'
import { FIELDS, DROPDOWN_FIELDS } from '../data'
import _ from 'lodash'

const { Option } = Select;
const { TextArea } = Input;
class FormInformation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: {

      },
    }
  }

  componentDidMount() {
    this.setState({ data: this.props.data });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.setState({ data: this.props.data });
    }
  }

  show = () => {
    this.setState({ visible: true });
  }

  hide = () => {
    this.setState({ visible: false });
  }

  handleFieldChange = (event, field) => {
    let value = event.target ? event.target.value : event;
    this.setState({ data: { ...this.state.data, [field]: value } });
  }

  handleSubmit = () => {
    // TODO: perform validation
    this.props.onSubmit(this.state.data);
    if (this.state.data.Source) {
      axios.put(API_CREATE_FEEDBACK_CHATTER,
        {
          'id': this.props.id,
          'source': this.state.data.Source
        },
        { headers: { 'Authorization': `Bearer ${this.props.token}` } })
    }
  }
  // onChange = (checked) => {
  //   this.setState({ data: { ...this.state.data, [field]: checked } })
  // }
  renderField = (item) => {
    let input;
    switch (item.inputType) {
      case 'Input':
        input = <div>
          <label htmlFor={item.label}>{item.label}</label>
          <Input
            id={item.label}
            name={item.label}
            placeholder={`Input ${item.label}`}
            value={this.state.data[item.label]}
            onChange={(event) => { this.handleFieldChange(event, item.label) }}
          />
        </div>
        break;
      case 'Select':
        const optionArr = item.options.split(',')
        input = <div>
          <label htmlFor={item.label}>{item.label}</label><br />
          <Select
            id={item}
            name={item}
            style={{ width: '100%' }}
            placeholder={`Select ${item.label}`}
            value={this.state.data[item.label]}
            onChange={(event) => { this.handleFieldChange(event, item.label) }}
          >
            {optionArr.map(entry => {
              return <Option value={entry}>{entry}</Option>
            })}
          </Select>
        </div>
        break;
      case 'Radio':
        const radioArr = item.options.split(',')
        input = <div>
          <label htmlFor={item.label}>{item.label}</label><br />
          <Radio.Group
            value={this.state.data[item.label]}
            onChange={(event) => { this.handleFieldChange(event, item.label) }}
          >
            {radioArr.map(entry => {
              return <Radio value={entry}>{entry}</Radio>
            })}
          </Radio.Group>
        </div>
        break
      case 'Checkbox':
        const checkBox = item.options.split(',')
        input = <div>
          <label htmlFor={item.label}>{item.label}</label><br />
          <Checkbox.Group
            value={this.state.data[item.label]}
            onChange={(event) => { this.handleFieldChange(event, item.label) }}
          >
            {
              checkBox.map(entry => {
                return <Checkbox value={entry}>{entry}</Checkbox>
              })
            }
          </Checkbox.Group>
        </div>
        break
      case 'TextArea':
        input = <div>
          <label htmlFor={item.label}>{item.label}</label>
          <TextArea
            id={item.label}
            name={item.label}
            placeholder={`Input ${item.label}`}
            value={this.state.data[item.label]}
            onChange={(event) => { this.handleFieldChange(event, item.label) }}
            rows={4}
          />
        </div>
        break;
      default:
        break;
    }
    return input;
  }
  render = () => {
    // var fields = FIELDS; //remove written Aug 26, 2020
    // for (var key in this.state.data) {
    //   if (!fields.includes(key)) {
    //     fields = fields.concat(key)
    //   }
    // }
    return <Drawer
      title="Client Information"
      placement="right"
      closable="true"
      onClose={this.hide}
      visible={this.state.visible}
      width={500}>

      <form onSubmit={this.handleSubmit} >

        <div>
          {FIELDS.map((item, index) => {
            let input;
            if (DROPDOWN_FIELDS[item]) {
              input = <div>
                <Select
                  onChange={(event) => { this.handleFieldChange(event, item) }}
                  id={item}
                  name={item}
                  value={this.state.data[item]}
                  style={{ width: '100%' }}
                >
                  {
                    DROPDOWN_FIELDS[item].map((option) => {
                      return <Select.Option value={option} key={option}>{option}</Select.Option>
                    })
                  }
                </Select>
              </div>
            }
            else {
              input = <Input
                id={item}
                name={item}
                value={this.state.data[item]}
                onChange={(event) => { this.handleFieldChange(event, item) }}
              />
            }
            return <div key={item}>
              <label htmlFor={item}>{item}</label>
              {input}
            </div>
          })}
          {
            this.props.extraFields?.map((item) => {
              return this.renderField(item)
            })
          }
        </div>
        <br />
        <Button type="primary" onClick={this.handleSubmit}>Save</Button>
      </form>

    </Drawer >
  }
}

export default FormInformation;