export const SessionColumns = [
	'SessionID',
	'ClientName',
	'DateTime',
	'AssignTo',
	'Status',
	'time_queued',
	'time_ongoing',
	'FRT',
	'time_completed',
	'CRT',
	'Inquiry',
	'AskingFor',
	'Tags',
	'Notes',
	'RatingFeedback',
	'WhyFeedback',
	'category'
]

export const legalColumns = [
	'sessionID',
	'fb_name',
	'real_name',
	'age_bracket',
	'gender',
	'civil_status',
	'employment_status',
	'sector',
	'address',
	'region',
	'city',
	'contact_number',
	'date_time',
	'status',
	'asking_for',
	'assignTo',
	'time_queued',
	'time_ongoing',
	'first_response',
	'inquiry',
	'inquiry_des',
	'tags',
	'time_completed',
	'completed_date',
	'source',
	'rating',
	'why',
	'category',
];

export const clientsColumns = [
	'ClientID',
	'ClientName',
	'RealName',
	'FirstSeen',
	'LastSeen',
	'Sessions',
	'Inquired',
	'Status',
	'SourceFeedBack',
	'Age',
	'Gender',
	'Employment',
	'CivilStatus',
	'Sector',
	'Address',
	'Region',
	'Province',
	'City',
	'ContactNumber'
]

export const antdInputType = [
	'Input',
	'Select',
	'Radio',
	'Checkbox',
	'TextArea'
]