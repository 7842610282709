import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import history from './history'
import './App.css';

//import UserList from './modules/users/screens/UserList.js'
import Login from './modules/login/screens/login';
import { logout } from './modules/login/actionCreators';
import User from './modules/users';
import Session from './modules/sessions/';
import Chatter from './modules/chatters/';
import Reports from './modules/reports/';
import Configuration from './modules/configuration';
import { Layout, Menu, Breadcrumb, notification, Button } from 'antd';
import {
  PieChartOutlined,
  RobotOutlined,
  WechatOutlined,
  TeamOutlined,
  SettingOutlined
} from '@ant-design/icons';
import _ from 'lodash';

const { Content, Sider } = Layout;

class App extends Component {
  state = {
    collapsed: false,
  };

  navigateTo = (history, path) => {
    history.push({
      pathname: path,
    })
  }

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  componentDidUpdate = (prevProps) => {
    try {
      if (this.props.notification.id !== prevProps.notification.id) {
        notification[this.props.notification.type]({
          message: this.props.notification.message,
          description: this.props.notification.details,
          duration: 3
        });
      }
    } catch (e) {
      console.log(e)
    }

  }
  logout = (history, path) => {
    this.props.logout();
    history.push({
      pathname: path
    })
  }
  render() {
    return (
      <Router history={history}>

        <Layout style={{ minHeight: '100vh' }}>
          {
            this.props.auth.token &&
            <Sider collapsed={this.state.collapsed} onCollapse={this.onCollapse} theme="light" style={{ borderRight: '1px solid #ccc' }}>
              <div style={{ margin: '15px' }} >
                <img
                  src={require('./assets/ideals_logo.png')}
                  alt='Ideals Logo'
                  height={80}
                />
              </div>
              <Menu theme="light" defaultSelectedKeys={[]} mode="inline" style={{ border: '0px' }}>
                <Menu.Item key="1" icon={<WechatOutlined />} onClick={() => this.navigateTo(history, `/sessions/list`)}>
                  Queuing
                </Menu.Item>
                <Menu.Item key="2" icon={<RobotOutlined />} onClick={() => this.navigateTo(history, `/chatters/list`)}>
                  Clients
                </Menu.Item>
                <Menu.Item key="3" icon={<PieChartOutlined />} onClick={() => this.navigateTo(history, `/reports/reports`)}>
                  Reports
                </Menu.Item>
                <Menu.Item key="4" icon={<SettingOutlined />} onClick={() => this.navigateTo(history, '/configuration/settings')}>
                  Configuration
                </Menu.Item>
                {_.get(this, `props.auth.user.role`, 'notAdmin') === 'Admin' &&
                  <Menu.Item key="5" icon={<TeamOutlined />} onClick={() => this.navigateTo(history, `/users/list`)}>
                    Users
                </Menu.Item>}
              </Menu>
              <br />
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <span>User: {this.props.auth.user.name}</span></div>
              <br />
              <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <Button type='primary' onClick={() => this.logout(history, '/login')}>
                  Logout
                </Button>
              </div>
            </Sider>
          }
          <Layout className="site-layout" style={{ backgroundColor: "#fff" }}>
            {/* <Header className="site-layout-background" style={{ padding: 0 }} /> */}
            <Content style={{ margin: '0 0px' }}>
              <Breadcrumb style={{ margin: '0px 0' }}>
              </Breadcrumb>
              <div className="site-layout-background" style={{ padding: 20, minHeight: 360 }}>
                <div className="App">
                  <Switch>

                    <Route path='/login' component={Login} />
                    <Route path='/users' component={User} />
                    <Route path='/sessions' component={Session} />
                    <Route path='/chatters' component={Chatter} />
                    <Route path='/reports' component={Reports} />
                    <Route path='/configuration' component={Configuration} />
                    <Route path='/' render={() => {
                      return <span><Redirect to={'/login'} /></span>
                    }} />
                  </Switch>
                </div>

              </div>
            </Content>
            {/* <Footer style={{ textAlign: 'center' }}>IDEALS Chatbot</Footer> */}
          </Layout>
        </Layout>
      </Router>


    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: Object.values(state.user),
    auth: state.auth,
    notification: state.notification
  }
}
export default connect(mapStateToProps, { logout })(App);
//<Route path='/login' component={Login}/>
//<Route path='/login' render={props => <Login from={from} {...props} />}/>