export const session = {
  status: [
    { key: 'BOT', label: 'ChatBot' },
    { key: 'QUEUE', label: 'Queued' },
    { key: 'ONGOING_CHAT', label: 'Ongoing' },
    { key: 'DONE', label: 'Completed' },
    { key: 'Archive', label: 'Archive' },
  ]
}

export const sourceFeedback = [
  'Facebook',
  'IBP',
  'Ibang Social Media',
  'Media',
  'Nasabi lang sa akin',
  'Iba pa'
]