import axios from '../../shared/utils/axios';
import _ from 'lodash';
import { logout } from '../../shared/utils/api_utils';
import { exportCSVFile } from '../reports/actionCreators'
import {
	API_GET_TAG_LIST,
	API_SAVE_REPORT_CONFIG,
	API_GET_REPORT_CONFIG,
	API_DELETE_TAG,
	API_EDIT_TAG,
	API_GET_EDIT_TAG,
	API_GET_CLIENT_CONF,
	API_SAVE_CLIENT_CONF,
	BASE_API_URL,
	API_GET_CAT_CONFIG,
	API_SAVE_CAT_CONFIG,
} from '../../shared/config/constants'
import { notification } from 'antd'

export const TYPES = {
	GET_AVAILABLE_TAGS: 'GET_AVAILABLE_TAGS',
	GET_REPORT_CONFIG: 'GET_REPORT_CONFIG',
	SAVE_REPORT_CONFIG: 'SAVE_REPORT_CONFIG',
	GET_CLIENT_CONF: 'GET_CLINT_CONF',
	GET_CAT_CONFIG: 'GET_CAT_CONFIG',
}

export const getTags = (token) => async dispatch => {
	try {
		const response = await axios.get(API_GET_TAG_LIST, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		});
		dispatch({ type: TYPES.GET_AVAILABLE_TAGS, payload: response.data })
	} catch (err) {
		logout(err, dispatch)
	}

}

export const deleteTag = (id, token) => async dispatch => {
	let response = await axios.delete(`${API_DELETE_TAG}/${id}`,
		{
			headers: {
				'Authorization': `Bearer ${token}`
			}
		}
	)
	dispatch({ type: TYPES.GET_AVAILABLE_TAGS, payload: response.data });
}

export const saveReportConfig = (values, token) => async dispatch => {
	try {
		const response = await axios.put(API_SAVE_REPORT_CONFIG, values,
			{
				headers: {
					'Authorization': `Bearer ${token}`
				}
			})
		dispatch({ type: TYPES.SAVE_REPORT_CONFIG, payload: response.data })
	} catch (err) {
		logout(err, dispatch)
	}
}

export const getReportConfig = token => async dispatch => {
	try {
		const response = await axios.get(API_GET_REPORT_CONFIG, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		});
		dispatch({ type: TYPES.GET_REPORT_CONFIG, payload: response.data })
	} catch (err) {
		logout(err, dispatch)
	}
}

export const editTag = (value, token) => async dispatch => {
	try {
		await axios.put(API_EDIT_TAG, value, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		});
	} catch (err) {
		logout(err, dispatch)
	}
}

export const getEditTag = (token) => async dispatch => {
	try {
		const headers = {
			created: 'Created_at',
			oldTag: 'Old Tag',
			newTag: 'New Tag'
		}
		let tagArr = []
		axios.get(API_GET_EDIT_TAG, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		}).then((response) => {
			response.data.editTag.forEach(item => {
				tagArr.push({
					created: item.updated_at,
					oldTag: item.oldTag,
					newTag: item.newTag
				})
			})
			exportCSVFile(headers, tagArr, 'TagHistoty')
		})
	} catch (err) {
		logout(err, dispatch)
	}
}

export const getClientConf = token => async dispatch => {
	axios.get(API_GET_CLIENT_CONF, {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	}).then((response) => {
		dispatch({ type: TYPES.GET_CLIENT_CONF, payload: response.data })
	}).catch((err) => {
		logout(err, dispatch)
	})
}

export const saveClientConf = (value, token) => async dispatch => {
	axios.put(API_SAVE_CLIENT_CONF, value, {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	}).catch(err => logout(err, dispatch))
}

export const deleteClientConf = (id, token) => async dispatch => {
	let response = await axios.delete(`${BASE_API_URL}/deleteClientConf/${id}`,
		{
			headers: {
				'Authorization': `Bearer ${token}`
			}
		}
	).then((response) => {
		dispatch({ type: TYPES.GET_CLIENT_CONF, payload: response.data })
	}).catch(response => {
		if (response.message.includes('404')) {
			notification.error({
				message: 'Data already used cannot delete',
			})
		} else {
			logout(response, dispatch)
		}
	})
}
export const getCatConfig = token => async dispatch => {
	axios.get(API_GET_CAT_CONFIG, {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	}).then(response => {
		dispatch({ type: TYPES.GET_CAT_CONFIG, payload: response.data })
	}).catch(err => {
		logout(err, dispatch)
	})
}
export const saveCatConfig = (value, token) => async dispatch => {
	axios.put(API_SAVE_CAT_CONFIG, value, {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	}
	).then(response => {
		dispatch({ type: TYPES.GET_CAT_CONFIG, payload: response.data })
	}).catch(err => {
		logout(err, dispatch)
	})
}

export const deleteCat = (id, token) => async dispatch => {
	axios.delete(`${BASE_API_URL}/deleteCat/${id}`,
		{
			headers: {
				'Authorization': `Bearer ${token}`
			}
		}
	).then((response) => {
		dispatch({ type: TYPES.GET_CAT_CONFIG, payload: response.data })
	}).catch(err => {
		logout(err, dispatch)
	})
}