import axios from 'axios';
import {
	BASE_URL,
	TIMEOUT
} from '../config/constants';

const instance = axios.create({
    baseURL: BASE_URL,
    timeout: TIMEOUT,
    // adapter: cache.adapter,
});
export default instance;