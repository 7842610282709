import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getUsers, createUser, deleteUser, userLog } from '../actionCreators';
import {
  Button, Input,
} from 'antd';
import 'antd/dist/antd.css';
import { role, admin, researcher, lawyer } from '../data'
import {
  DoubleRightOutlined,
} from '@ant-design/icons';
import _ from 'lodash'
import UserManagement from '../components/UserManagement';
import UserTable from '../components/UserTable'

const { Search } = Input;

class UserList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      active: false,
      name: '',
      email: '',
      password: null,
      password2: null,
      role: 'Researcher',
      permissions: researcher,
      checked: false,
      errors: {
        name: '',
        email: '',
        password: '',
        password2: ''
      }
    }
    this.onDelete = this.onDelete.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleCreateNewItem = this.handleCreateNewItem.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
      active: false,
      name: '',
      email: '',
      password: null,
      password2: null,
      role: 'Researcher',
      id: null,
      permissions: researcher
    });
  };

  handleFieldChange(event) {
    // this.setState({
    //   [event.target.name]: event.target.value
    // })
    event.preventDefault();
    const { name, value } = event.target;
    /* eslint-disable-next-line */
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    let errors = this.state.errors;
    switch (name) {
      case 'name':
        errors.name =
          value.length < 5 ?
            'Full Name must be 5 characters long!' :
            '';
        break;
      case 'email':
        errors.email =
          validEmailRegex.test(value) ?
            '' :
            'Email is not valid!';
        break;
      case 'password':
        errors.password =
          value.length < 8 ?
            'Password must be 8 characters long!' :
            '';
        break;
      case 'password2':
        errors.password2 =
          value.localeCompare(this.state.password) < 0 ?
            'Password not same!' :
            '';
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value }, () => {
      //console.log(errors)
    })
  }
  validateForm(errors) {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }

  handleCreateNewItem(event) {
    event.preventDefault();
    const item = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      role: this.state.role,
      active: this.state.active,
      permissions: this.state.permissions
    }
    const logItem = {
      'user_id': this.props.auth.user.id,
      'activity': this.state.id ? 'Edited User' : 'Created User',
      'condition': {
        role: this.state.role,
        userName: this.state.name,
        active: this.state.active
      }
    }
    if (this.validateForm(this.state.errors)) {
      this.props.createUser(this.state.id, item, this.props.auth.token);
      this.props.userLog(logItem, this.props.auth.token)
      // this.props.getUsers({ page: this.props.user.page, take: user.take }, this.props.auth.token);
      this.onClose();
    }

  }

  onDelete(id, token, role, name) {
    const user = this.props.user
    this.props.deleteUser({ page: this.props.user.page, take: user.take }, id, token)
    this.props.userLog({
      'user_id': this.props.auth.user.id,
      'activity': 'Deleted User',
      'condition': {
        role: role,
        userName: name
      }
    }, this.props.auth.token)
    //this.props.getUsers({ page: this.props.user.page, take: user.take }, this.props.auth.token);
  }

  onUpdate = (userId, userName, userEmail, userRole, userStatus, userPermissions) => {
    const permissions = userPermissions.split(',')
    this.setState({
      id: userId,
      name: userName,
      email: userEmail,
      role: userRole,
      active: userStatus,
      visible: true,
      permissions: permissions
    })
  }

  componentDidMount() {
    if (_.get(this, `props.auth.user.role`, 'notAdmin') === 'Admin') {
      this.props.getUsers({ page: 1, take: 10 }, this.props.auth.token);
    }
  }

  handleListChange = (pagination, filters, sorter) => {
    this.props.getUsers({
      q: this.props.user.q,
      take: pagination.pageSize,
      page: pagination.current,
      ...filters
    },
      this.props.auth.token);
  }

  onSelectRole = (value) => {
    this.setState({
      role: value,
      permissions: this.roleSwitch(value)
    })
  }

  onCheckPermissions = (checkedValues) => {
    this.setState({
      permissions: checkedValues,
      checked: false
    })
  }
  checkAll = (e) => {
    if (e.target.checked) {
      this.setState({
        permissions: admin,
        checked: true
      })
    }
    else {
      this.setState({
        permissions: [],
        checked: false
      })
    }
  }
  roleSwitch = (role) => {
    switch (role) {
      case 'Admin':
        return admin
      case 'Lawyer':
        return lawyer
      case 'Researcher':
        return researcher
      default:
        return <div>none</div>
    }
  }
  search = (name) => {
    this.props.getUsers({
      q: name,
      page: 1,
      take: this.props.user.take,
      sort_key: this.props.user.sort_key,
      sort_dir: this.props.user.sort_dir,
      ...this.props.user.filters

    },
      this.props.auth.token);
  }
  onSwitchChange = (checked) => {
    this.setState({
      active: checked
    })
  }

  render() {
    const user = this.props.user;
    const { errors } = this.state;

    if (!this.props.auth.token) {
      return <Redirect to={'/login'} />
    }
    var data = user.data;
    return (
      <div>
        <h3 className='ideals-page-header'>
          <DoubleRightOutlined />&nbsp; System Users List
        </h3>
        <hr />
        <br />

        <Search placeholder="Search User Name" onSearch={this.search} enterButton style={{ width: 400 }} />
        <div style={{ float: 'right' }}>
          <Button type="primary" onClick={this.showDrawer}>
            New User
          </Button>
        </div>
        <br />
        <br />
        <UserTable
          handleListChange={this.handleListChange}
          onDelete={this.onDelete}
          onUpdate={this.onUpdate}
          data={data}
          user={user}
          token={this.props.auth.token}
        />
        <UserManagement
          _this={this}
          onSwitchChange={this.onSwitchChange}
          handleCreateNewItem={this.handleCreateNewItem}
          handleFieldChange={this.handleFieldChange}
          onCheckPermissions={this.onCheckPermissions}
          onSelectRole={this.onSelectRole}
          validateForm={this.validateForm}
          onClose={this.onClose}
          checkAll={this.checkAll}
          admin={admin}
          role={role}
          errors={errors}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    auth: state.auth
  }
}
export default connect(mapStateToProps, { getUsers, createUser, deleteUser, userLog })(UserList);