import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

class FeedbackConf extends Component {
	render() {
		if (!this.props.auth.token) {
			return <Redirect to={'/login'} />
		}
		return (
			<div>
				FeedbackConf
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	}
}
export default connect(mapStateToProps, {})(FeedbackConf)